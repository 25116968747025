import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import dateformat from 'dateformat';
import { translate } from 'react-i18next';

import ArticleEditFormContainer from './ArticleEditFormContainer';
import { fetchArticle, updateArticle, fetchArticleHistories } from '../../actions/articleAction';
import { fetchAuthors } from '../../actions/authorAction';
import { fetchCategories } from '../../actions/categoryAction';
import { addErrorAlert, addWarningAlert, addConfirmAlert } from '../../actions/alertAction';
import { createEditExclusion, deleteEditExclusion } from '../../actions/editExclusionAction';
import { addLoading } from '../../actions/loadingAction';
import Config from '../../config/Config';
import { hasPermission, timeFormat } from '../../util/util';

class ArticleEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleUnload = this.handleUnload.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.handleUnEditing = this.handleUnEditing.bind(this);

    this.handleCreateEditExclusion = this.handleCreateEditExclusion.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.handleHistoryClick = this.handleHistoryClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      editExclusionTimer: null,
      isAddUnloadListener: false,
    };
  }

  componentDidMount() {
    this.props.fetchArticle(this.props.params.id);
    this.props.fetchAuthors();
    this.props.fetchCategories();

    if (this.state.editExclusionTimer == null) {
      setTimeout(this.handleCreateEditExclusion, 3 * 1000);
      this.state.editExclusionTimer = setInterval(this.handleCreateEditExclusion, 30 * 1000);
    }

    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this));
  }

  componentWillUnmount() {
    clearInterval(this.state.editExclusionTimer);
    this.setState({ editExclusionTimer: null });

    this.props.deleteEditExclusion(this.props.session.tabID);

    if (this.state.isAddUnloadListener) {
      window.removeEventListener('beforeunload', this.handleUnload, false);
    }
  }

  routerWillLeave(e) {
    if (this.state.isAddUnloadListener) {
      this.props.addConfirmAlert(
        this.props.t('messages.discardEditingArticle'),
        () => {
          localStorage.removeItem(`articleEditForm_${this.props.article.article_id}`);
          this.setState({ isAddUnloadListener: false });
          window.removeEventListener('beforeunload', this.handleUnload, false);

          setTimeout(() => this.props.router.push(e.pathname), 0);
        },
      );

      return false;
    }

    return true;
  }

  handleUnload(e) {
    e.returnValue = '';
  }

  handleEditing() {
    if (!this.state.isAddUnloadListener) {
      window.addEventListener('beforeunload', this.handleUnload, false);
      this.setState({ isAddUnloadListener: true });
    }
  }

  handleUnEditing() {
    if (this.state.isAddUnloadListener) {
      window.removeEventListener('beforeunload', this.handleUnload, false);
      this.setState({ isAddUnloadListener: false });
    }
  }

  handleCreateEditExclusion() {
    const { article, session } = this.props;

    if (article.article_id != null) {
      this.props.createEditExclusion(session.tabID, article.article_id, article.revision);
    }
  }

  handleCloseClick() {
    if (this.state.isAddUnloadListener) {
      this.props.addConfirmAlert(
        this.props.t('messages.discardEditingArticle'),
        () => {
          localStorage.removeItem(`articleEditForm_${this.props.article.article_id}`);
          this.setState({ isAddUnloadListener: false });
          window.removeEventListener('beforeunload', this.handleUnload, false);

          setTimeout(() => window.close());
        },
      );
    } else {
      window.close();
    }
  }

  handlePreviewClick() {
    const { article } = this.props;

    if (article.article_id != null) {
      if (article.status === 'posted') {
        if (article.brand === 'stories') {
          window.open(`${Config.WEB_ENDPOINT}/company/stories/${article.article_id}/${article.custom_url}`, '_blank');
        } else if (article.brand === 'shinkai') {
          window.open(`${Config.WEB_ENDPOINT}/shinkai/${article.article_id}/${article.custom_url}`, '_blank');
        } else {
          window.open(`${Config.WEB_ENDPOINT}/news/${article.article_id}/${article.custom_url}`, '_blank');
        }
      } else if (article.brand === 'stories') {
        window.open(`${Config.WEB_ENDPOINT}/company/stories/preview/${article.article_id}/${article.preview_key}`, '_blank');
      } else if (article.brand === 'shinkai') {
        window.open(`${Config.WEB_ENDPOINT}/shinkai/preview/${article.article_id}/${article.preview_key}`, '_blank');
      } else {
        window.open(`${Config.WEB_ENDPOINT}/news/preview/${article.article_id}/${article.preview_key}`, '_blank');
      }
    }
  }

  handleHistoryClick() {
    const { router, article } = this.props;

    if (article.article_id != null) {
      this.props.fetchArticleHistories(article.article_id, (res) => {
        router.push(`/article/${article.article_id}/history/${res.articles[0].revision}`);
      });
    }
  }

  handleSubmit(form, newStatus) {
    const newForm = { ...form };

    if (newForm.post_date != null && newForm.post_date.length === 16) {
      const date = new Date(newForm.post_date);
      newForm.post_date = dateformat(date, 'yyyy-mm-dd HH:MM:ss');
    }

    if ((newForm.status === 'reserved' || newForm.status === 'posted') &&
      (newForm.post_date == null || newForm.post_date.length === 0)) {
      this.props.addErrorAlert(this.props.t('messages.warningRequirePostDate'));
      return;
    }

    if (newForm.status === 'reserved' && new Date(newForm.post_date).getTime() < new Date().getTime() + (10 * 1000)) {
      this.props.addWarningAlert(this.props.t('messages.warningPastDateReservation'));
    }

    if (newForm.edit_date.length > 0 &&
      new Date(newForm.edit_date).getTime() < new Date(newForm.post_date).getTime()) {
      this.props.addErrorAlert(this.props.t('messages.warningPastEditDate'));
      return;
    }

    if (newStatus) newForm.status = newStatus;
    newForm.author_id = Number(newForm.author_id);
    newForm.category_id = Number(newForm.category_id);

    const escapeCtrlCharRegexp = /[\x00-\x1F\x7F-\x9F]/g;
    const escapeCtrlCharRegexp2 = /[\x00-\x09\x0B\x0C\x0E-\x1F\x7F-\x9F]/g;

    if (newForm.titles != null) {
      const titles = [];
      newForm.titles.forEach((e) => {
        if (e != null && e.length > 0) {
          titles.push(e.replace(escapeCtrlCharRegexp, ''));
        }
      });
      newForm.titles = titles;
    }

    newForm.hide_eye_catch = newForm.hide_eye_catch !== '' && newForm.hide_eye_catch;

    newForm.custom_url = newForm.custom_url.replace(/\s+/g, '');

    newForm.seo_title = newForm.seo_title.replace(escapeCtrlCharRegexp, '');
    newForm.social_title = newForm.social_title.replace(escapeCtrlCharRegexp, '');
    newForm.description = newForm.description.replace(escapeCtrlCharRegexp, '');
    newForm.memo = newForm.memo.replace(escapeCtrlCharRegexp2, '');

    this.props.addLoading();
    this.props.updateArticle(newForm);

    Object.keys(newForm).forEach((key) => {
      this.props.updateForm(key, newForm[key]);
    });

    if (this.state.isAddUnloadListener) {
      window.removeEventListener('beforeunload', this.handleUnload, false);
      this.setState({ isAddUnloadListener: false });
    }
  }

  render() {
    const { article, authors, categories, permissions, params, t } = this.props;
    const editExclusions = this.props.editExclusions.edit_exclusions;

    if (article == null) {
      return null;
    }

    let editingUserNames = [];
    if (editExclusions != null) {
      editExclusions.forEach((e) => {
        if (permissions != null && e.user_id !== permissions.id) {
          editingUserNames.push(e.user_name);
        }
      });

      editingUserNames = editingUserNames.filter((x, i, self) => self.indexOf(x) === i);
    }

    const classNames = { status: true };
    classNames[article.status] = true;

    return (
      <div className="articleEditContainer">

        <div className="statusBar">
          {article.status === 'posted' &&
          <div className="posted">
            <span className="postDateTime">{timeFormat(article.post_date) || '-'}</span>
            <span className="postedText">{t('article.statusBar.posted')}</span>
          </div>}

          {article.status === 'reserved' &&
          <div className="reserved">
            <span className="postDateTime">{timeFormat(article.post_date) || '-'}</span>
            <span className="reservedText">{t('article.statusBar.reserved')}</span>
          </div>}

          {article.status === 'deleted' &&
          <div className="deleted">
            <span className="deletedText">{t('article.statusBar.deleted')}</span>
          </div>}


          <div className="header">
            {(() => {
              if (editingUserNames.length > 0) {
                return (
                  <div className="editExclusion">
                    {t('article.editExclusion', { userName: editingUserNames.join(', ') })}
                  </div>
                );
              }

              return null;
            })()}

            <div className="left">
              <button
                className="deleteButton"
                ref={(ref) => { this.deleteButton = ref; }}
                disabled={article.status === 'deleted' || !hasPermission(Config.PERMISSIONS.article_status_edit, permissions)}
              >
                {t('article.header.delete')}
              </button>
            </div>

            <div className="right">
              <button className="historyButton" ref={(ref) => { this.historyButton = ref; }}>
                {t('article.header.history')}
              </button>
              <button onClick={this.handlePreviewClick}>
                {t('article.header.preview')}
              </button>
              {article.status === 'reserved' || article.status === 'posted' || article.status === 'deleted' ?
                <button className="draftButton" ref={(ref) => { this.draftButton = ref; }} disabled={!hasPermission(Config.PERMISSIONS.article_status_edit, permissions)}>
                  {t('article.header.returnToDraft')}
                </button>
                : null
              }
              {article.status === 'draft' ?
                <button className="draftButton" ref={(ref) => { this.draftButton = ref; }}>
                  {t('article.header.saveAsDraft')}
                </button>
                : null
              }

              {article.status === 'draft' || article.status === 'deleted' ?
                <button
                  className="publishButton"
                  disabled={article.status === 'deleted' || !hasPermission(Config.PERMISSIONS.article_status_edit, permissions)}
                  onClick={this.handlePublishSettingClick}
                  ref={(ref) => { this.publishSettingButton = ref; }}
                >
                  <div>{t('article.header.publishSettings')}</div>
                  <i className="material-icons">settings</i>
                </button>
                :
                <span>
                  <button
                    className="overwriteButton"
                    ref={(ref) => { this.saveButton = ref; }}
                  >
                    {t('article.header.overwrite')}
                  </button>
                  <button
                    className="cogButton"
                    onClick={this.handlePublishSettingClick}
                    ref={(ref) => { this.publishSettingButton = ref; }}
                    disabled={!hasPermission(Config.PERMISSIONS.article_status_edit, permissions)}
                  >
                    <i className="material-icons">settings</i>
                  </button>
                </span>
              }

            </div>

            <div className="clear" />

          </div>
        </div>

        <div className="content">
          {article != null && Object.keys(article).length > 0 ? <ArticleEditFormContainer
            id={params.id}
            article={article}
            authors={authors.authors}
            categories={categories.categories}
            saveButton={this.saveButton}
            publishSettingButton={this.publishSettingButton}
            closeButton={this.closeButton}
            deleteButton={this.deleteButton}
            historyButton={this.historyButton}
            draftButton={this.draftButton}
            onEditing={this.handleEditing}
            onUnEditing={this.handleUnEditing}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCloseClick}
            onHistoryClick={this.handleHistoryClick}
            ref={(ref) => { this.articleEditFormContainer = ref; }}
          /> : null}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    article: state.article,
    authors: state.authors,
    categories: state.categories,
    session: state.session,
    permissions: state.permissions,
    editExclusions: state.editExclusions,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('articleEditForm', key, value)),
    fetchArticle: id => dispatch(fetchArticle(id)),
    fetchArticleHistories: (id, callback) => dispatch(fetchArticleHistories(id, callback)),
    fetchAuthors: () => dispatch(fetchAuthors()),
    fetchCategories: () => dispatch(fetchCategories()),
    updateArticle: form => dispatch(updateArticle(form)),
    addErrorAlert: value => dispatch(addErrorAlert(value)),
    addWarningAlert: value => dispatch(addWarningAlert(value)),
    addConfirmAlert:
      (message, onSubmit, onCancel) => dispatch(addConfirmAlert(message, onSubmit, onCancel)),
    createEditExclusion:
      (tabID, articleID, revision) => dispatch(createEditExclusion(tabID, articleID, revision)),
    deleteEditExclusion: tabID => dispatch(deleteEditExclusion(tabID)),
    addLoading: () => dispatch(addLoading()),
  }
);

const articleEditContainer = withRouter(translate()(ArticleEditContainer));
export default connect(mapStateToProps, mapDispatchToProps)(articleEditContainer);
