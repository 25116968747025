import React from 'react';
import { connect } from 'react-redux';

import ArticlePublishSettingPopup from '../../components/article/ArticlePublishSettingPopup';

class ArticlePublishSettingPopupContainer extends React.Component {
  render() {
    const { permissions, articleEditForm, onSubmit, onReserved, onCancel, t } = this.props;

    return (
      <ArticlePublishSettingPopup
        permissions={permissions}
        form={articleEditForm}
        onSubmit={onSubmit}
        onReserved={onReserved}
        onCancel={onCancel}
        t={t}
      />
    );
  }
}

const mapStateToProps = state => (
  {
    permissions: state.permissions,
    articleEditForm: state.form.articleEditForm != null ? state.form.articleEditForm.values : null,
  }
);

export default connect(mapStateToProps)(ArticlePublishSettingPopupContainer);
