import uuid from 'node-uuid';

import {
  ADD_ALERT,
  REMOVE_ALERT,
} from '../actions/alertAction';

const alertsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ALERT:
      return [
        ...state,
        {
          id: uuid(),
          text: action.text,
          style: action.style,
          onSubmit: action.onSubmit,
          onCancel: action.onCancel,
        },
      ];

    case REMOVE_ALERT:
      return state.filter((alert) => {
        if (alert.id === action.id) {
          return false;
        }

        return true;
      });

    default:
      return state;
  }
};

export default alertsReducer;
