import {
  FETCH_USERS_START,
  FETCH_USERS_COMPLETE,
} from '../actions/userAction';

const usersReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USERS_START:
      return state;

    case FETCH_USERS_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default usersReducer;
