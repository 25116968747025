import React from 'react';
import classnames from 'classnames';
import Config from '../../config/Config';
import { getName } from '../../util/util';

const renderIssue = (issue, props) => {
  const {
    id,
    onClick,
  } = props;

  const classNames = { status: true };
  classNames[issue.status] = true;

  return (
    <li
      className={classnames({ item: true, selected: Number(id) === issue.issue_id })}
      key={`issue-${issue.issue_id}`}
    >
      <div className="list-content" onClick={() => onClick(issue.issue_id)}>
        <img
          src={issue.catalog_image_url != null &&
            issue.catalog_image_url.length > 0 ?
            issue.catalog_image_url : '/assets/images/no_image_small.png'}
          alt="catalogImageURL"
        />

        <div className="description">
          <div>
            <span className={classnames(classNames)}>
              {getName(Config.PRODUCT_STATUSES, issue.status)}
            </span>
            <span className="name">
              {`${issue.name} (${issue.key})`}
            </span>
          </div>
          <div className="description">
            <span>
              {issue.seo_description}
            </span>
          </div>
        </div>

      </div>
    </li>
  );
};

const IssueList = (props) => {
  const { issues } = props;

  if (issues == null) {
    return null;
  }

  return (
    <div className="issueList">
      <ul>
        {issues.map(issue => renderIssue(issue, props))}
      </ul>
    </div>
  );
};

export default IssueList;
