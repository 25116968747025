const i18nEN = {
  layout: {
    sideMenu: {
      menus: {
        article: 'Articles',
        image: 'Images',
        author: 'Authors',
        category: 'Categories',
        tag: 'Tags',
        issue: 'Issues',
        productSet: 'Product sets',
        productOption: 'Product options',
        user: 'User accounts',
      },
    },
  },
  article: {
    eyeCatchURLs: 'Eye Catch image',
    hideEyeCatch: 'Hide Eye Catch image in the article page',
    displayOriginalRatioEyeCatch: 'Display Eye Catch image by Original Ratio in the article page',
    headerVideoURLs: 'Eye Catch Youtube Videos',
    mobileYoutubeVideoEyeCatchIDPlaceholder: 'Please input youtube video id for mobile.',
    pcYoutubeVideoEyeCatchIDPlaceholder: 'Please input youtube video id for PC.',
    titles: 'Headline',
    subTItle: 'Sub Headline',
    author: 'Author',
    category: 'Category',
    url: 'URL',
    copyUrl: 'Copy Preview URLs',
    brand: 'Brand',
    contentType: 'Content type',
    tags: 'Tags',
    seoTitle: 'SEO title',
    socialTitle: 'Social title',
    description: 'Description',
    embedCode: 'Embed code',
    status: 'Status',
    postDate: 'Post date',
    memo: 'Memo',
    eyeCatchURLsTooltip: 'Sub eyecathes are for AB Test.',
    displayOriginalRatioEyeCatchTooltip: 'This image will be displayed with original aspect ratio in the artice page instead of the image above',
    eyeCatchURLsToMainTooltip: 'Set displayed image as the main image.',
    titlesTooltip: 'Following titles are used for AB Test.',
    titlesToMainTooltip: 'Set as main headline',
    subTitleTooltip: 'Sub Title are used for Stories / Shinkai Project Article.',
    tagsTooltip: 'Input tags for tag page and searching articles.',
    seoTitleTooltip: 'The title that will be used in search engine results (like Google).<br>If over 70 characters the title may be truncated.<br>If unused the headline will be used.<br>It’s more effective to include keywords you want picked up by search in the headline and body.',
    socialTitleTooltip: 'The title that will be used in social media like Facebook, Twitter.<br>If over 25 characters the title may be truncated.<br>If unused the headline will be used.',
    descriptionTooltip: 'The description that will be used in search engine results (like Google).<br>If over 250 characters it may be truncated.<br>For more search engine hits, it’s more effective to put the keywords in the headline or body.',
    descriptionCount: 'Characters: {{count}}',
    descriptionCountAlert: 'Please enter no more than 256 characters.',
    tagsPlaceholder: 'Input tags',
    sentence: 'Paragraph',
    heading: 'Subhead',
    image: 'Image',
    embed: 'Embed',
    etc: 'Other',
    imageURLPlaceholder: 'Input URL link',
    imageAltPlaceholder: 'Input Alt text',
    embedPlaceholder: 'Input embed code',
    creditTypePlaceholder: 'Input attribution type',
    creditNamePlaceholder: 'Input attribution name',
    creditURLPlaceholder: 'Input attribution URL',
    creditAddPlaceholder: 'Add attribution',
    authorNamePlaceholder: 'Input author',
    referenceNamePlaceholder: 'Input reference',
    referenceURLPlaceholder: 'Input reference URL',
    insertSection: 'Insert new section',
    editExclusion: '{{userName}} is currently editing',
    articleHistory: 'Article history',
    searchForm: {
      word: 'Search',
      wordPlaceholder: 'Input Titles prefix words or article ID and hit Enter',
      status: 'Status',
      brand: 'Brand',
      isMyArticle: 'Only display articles I created',
    },
    searchPopup: {
      searchWord: 'Input Titles prefix words or article ID and hit Enter',
    },
    publishSettingPopup: {
      popTitle: 'Publish Settings',
      postDate: 'Post date',
      editDate: 'Edit date',
      cancel: 'Cancel',
      save: 'Save',
      saveAsReserved: 'Save as reserved',
    },
    statusBar: {
      posted: 'Posted',
      reserved: 'Reserved',
      deleted: 'Deleted',
    },
    header: {
      delete: 'Delete',
      download: 'Download',
      history: 'History',
      preview: 'Preview',
      overwrite: 'Overwrite',
      saveAsDraft: 'Save as draft',
      returnToDraft: 'Return to Draft',
      publishSettings: 'PublishSettings',
    },
  },
  image: {
    url: 'URL',
    tags: 'Tags',
    userName: 'User name',
    imageType: 'Image type',
    source: 'Image site name',
    tagsPlaceholder: 'Input tags',
    credit: 'Credit',
    creditName: 'Credit name',
    creditURL: 'Credit URL',
    hideCredit: 'Hide credit',
    isFullWidth: 'Display image with device width',
    searchForm: {
      tags: 'Tag',
      imageType: 'Image type',
      isMyImage: 'Only display images I’ve uploaded',
    },
    searchPopup: {
      searchWord: 'Input tag and hit Enter',
    },
    uploadFile: 'Upload file',
    draggableImage: 'Drag and drop image file here',
  },
  author: {
    name: 'Name',
    key: 'Key',
    title: 'Title',
    profile: 'Profile',
    icon: 'Icon image',
  },
  category: {
    name: 'Name',
    key: 'Key',
    description: 'Description',
    seoName: 'Name (SEO)',
    mobileHeaderImageURL: 'Header image URL (mobile)',
    pcHeaderImageURL: 'Header image URL (desktop)',
    sortKey: 'Display order',
  },
  tag: {
    name: 'Name',
    description: 'Description',
    parentID: 'Parent tag',
    childIDs: 'Child tag',
    searchTag: 'Input tag and hit Enter',
  },
  issue: {
    name: 'Name',
    key: 'Key',
    topDescription: 'Top description',
    catalogDescription: 'Catalog description',
    seoDescription: 'SEO description',
    issues: 'Issues',
    summaries: 'Summaries',
    featureFunctions: 'Feature, Functions',
    detailYoutubeVideoID: 'Detail Youtube Video ID',
    showDetailYoutubeVideo: 'Show Youtube Video on Detail Page',
    catalogImageURL: 'Catalog image URL (mobile)',
    mobileHeaderImageURL: 'Header image URL (mobile)',
    pcHeaderImageURL: 'Header image URL (desktop)',
    sortKey: 'Display order',
    status: 'Status',
  },
  productSet: {
    name: 'Name',
    key: 'Key',
    detailDescription: 'Detail description',
    catalogDescription: 'Catalog description',
    seoDescription: 'SEO description',
    featureFunctions: 'Feature, Functions',
    detailYoutubeVideoID: 'Detail Youtube Video ID',
    showDetailYoutubeVideo: 'Show Youtube Video on Detail Page',
    catalogImageURL: 'Catalog image URL (mobile)',
    mobileHeaderImageURL: 'Header image URL (mobile)',
    pcHeaderImageURL: 'Header image URL (desktop)',
    sortKey: 'Display order',
    status: 'Status',
  },
  productOption: {
    name: 'Name',
    key: 'Key',
    purposes: 'Purpose',
    detailDescription: 'Detail description',
    catalogDescription: 'Catalog description',
    catalogSceneDescription: 'Catalog scene description',
    seoDescription: 'SEO description',
    summaries: 'Summaries',
    featureFunctions: 'Feature, Functions',
    detailYoutubeVideoID: 'Detail Youtube Video ID',
    showDetailYoutubeVideo: 'Show Youtube Video on Detail Page',
    catalogImageURL: 'Catalog image URL (mobile)',
    mobileHeaderImageURL: 'Header image URL (mobile)',
    pcHeaderImageURL: 'Header image URL (desktop)',
    sortKey: 'Display order',
    status: 'Status',
  },
  user: {
    name: 'Name',
    email: 'Email address',
    role: 'Role',
    lastAccessAt: 'Last accessed',
  },
  common: {
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    createUserName: 'Create User',
    updateUserName: 'Update User',
    selectAll: 'All',
    match: 'MATCH',
    notMatch: 'NOT MATCH',
    main: 'Main',
    sub: 'Sub{{index}}',
    download: 'Download',
    new: 'Create new',
    dateFormat: 'mm/dd/yyyy',
    timeFormat: 'mm/dd/yyyy HH:MM:ss',
    articleContentTypes: {
      news: 'news',
      product: 'product',
      event: 'event',
      original: 'original',
    },
    articleStatuses: {
      draft: 'Draft',
      reserved: 'Reserved',
      posted: 'Posted',
      deleted: 'Deleted',
    },
    articleInputTypes: {
      credit: 'Attribution',
      relationArticle: 'Related article',
      reference: 'Reference',
    },
    imageImageTypes: {
      eyeCatch: 'Eye Catch',
      article: 'Inside article',
      material: 'Assets',
    },
    featureFunctionsItems: {
      name: 'Name',
      eyecatchType: 'Eyecatch Type',
      imageURL: 'Image URL',
      imagePreview: 'Image Preview',
      youtubeVideoID: 'youtube Video ID',
      youtubePreview: 'Youtube Preview',
      example: 'Example',
      equipment: 'Equipment',
      text: 'Text',
    },
    productOptionPurposeTypes: {
      performance: 'Performance',
      watching: 'Watching',
      measuring: 'Measuring',
      collection: 'Collection',
      cleaning: 'Cleaning',
    },
  },
  messages: {
    authFailure: 'User authentication failed.',
    fetchArticleError: 'Article retrieval failed. {{message}}',
    fetchArticleHistoryError: 'Article history retrieval failed. {{message}}',
    createArticleSuccess: 'Article created.',
    createArticleError: 'Article creation failed. {{message}}',
    updateArticleSuccess: 'Article updated.',
    updateArticleError: 'Article update failed. {{message}}',
    deleteArticleSuccess: 'Article deleted.',
    deleteArticleError: 'Article delete failed. {{message}}',
    deleteArticleConfirm: 'Are you sure you want to delete this article?',
    draftArticleSuccess: 'Article drafted.',
    draftArticleError: 'Article draft failed. {{message}}',
    fetchImageError: 'Image retrieval failed. {{message}}',
    fetchImageSourceError: 'Image site retrieval failed. {{message}}',
    createImageSuccess: 'Image uploaded.',
    createImageError: 'Image upload failed. {{message}}',
    createImageValidateError: 'Upload file not specified.',
    updateImageSuccess: 'Image updated.',
    updateImageError: 'Image update failed. {{message}}',
    deleteImageSuccess: 'Image deleted.',
    deleteImageError: 'Image deletion failed. {{message}}',
    fetchAuthorError: 'Author retrieval failed. {{message}}',
    createAuthorSuccess: 'Author created.',
    createAuthorError: 'Author creation failed. {{message}}',
    updateAuthorSuccess: 'Author updated.',
    updateAuthorError: 'Author update failed. {{message}}',
    deleteAuthorSuccess: 'Author deleted.',
    deleteAuthorError: 'Author deletion failed. {{message}}',
    fetchCategoryError: 'Category retrieval failed. {{message}}',
    createCategorySuccess: 'Category created.',
    createCategoryError: 'Category creation failed. {{message}}',
    updateCategorySuccess: 'Category updated.',
    updateCategoryError: 'Category update failed. {{message}}',
    deleteCategorySuccess: 'Category deleted.',
    deleteCategoryError: 'Category deletion failed. {{message}}',
    fetchTagError: 'Tag retrieval failed. {{message}}',
    createTagSuccess: 'Tag created.',
    createTagError: 'Tag creation failed. {{message}}',
    updateTagSuccess: 'Tag updated.',
    updateTagError: 'Tag update failed. {{message}}',
    deleteTagSuccess: 'Tag deleted.',
    deleteTagError: 'Tag deletion failed. {{message}}',
    fetchIssueError: 'Issue retrieval failed. {{message}}',
    createIssueSuccess: 'Issue created.',
    createIssueError: 'Issue creation failed. {{message}}',
    updateIssueSuccess: 'Issue updated.',
    updateIssueError: 'Issue update failed. {{message}}',
    deleteIssueSuccess: 'Issue deleted.',
    deleteIssueError: 'Issue deletion failed. {{message}}',
    fetchProductSetError: 'ProductSet retrieval failed. {{message}}',
    createProductSetSuccess: 'ProductSet created.',
    createProductSetError: 'ProductSet creation failed. {{message}}',
    updateProductSetSuccess: 'ProductSet updated.',
    updateProductSetError: 'ProductSet update failed. {{message}}',
    deleteProductSetSuccess: 'ProductSet deleted.',
    deleteProductSetError: 'ProductSet deletion failed. {{message}}',
    fetchProductOptionError: 'ProductOption retrieval failed. {{message}}',
    createProductOptionSuccess: 'ProductOption created.',
    createProductOptionError: 'ProductOption creation failed. {{message}}',
    updateProductOptionSuccess: 'ProductOption updated.',
    updateProductOptionError: 'ProductOption update failed. {{message}}',
    deleteProductOptionSuccess: 'ProductOption deleted.',
    deleteProductOptionError: 'ProductOption deletion failed. {{message}}',
    fetchUserError: 'User retrieval failed. {{message}}',
    createUserSuccess: 'User created.',
    createUserError: 'User creation failed. {{message}}',
    updateUserSuccess: 'User updated.',
    updateUserError: 'User update failed. {{message}}',
    deleteUserSuccess: 'User deleted.',
    deleteUserError: 'User deletion failed. {{message}}',
    fetchRoleError: 'Role retrieval failed. {{message}}',
    warningEditingSection: 'Cannot Save while an edit box is still open. Click OK or Cancel to confirm the changes to the edit box prior to saving.',
    warningPastDateReservation: 'schedule post to a past date. Please Check it.',
    warningRequirePostDate: 'Please set the post date.',
    warningRequireImageCredit: 'Please set image credits to publish the article.',
    discardEditingArticle: 'Contents not saved. Do you wish to discard changes?',
    restoreFromLocalStorage: 'Autosaved data exists. Do you wish to restore?<br>If you click Cancel the autosaved data will be deleted.',
    googleMapsURLError: 'URL is invalid. Please enter the URL of Google Maps.',
    warningInvalidVideoEmbedCode: 'Embed code for video article is invalid. Please copy correct code in YouTube',
    warningTagTreeInvalid: 'Cannot set the same tag as parent tag and child tag.',
    previewUrlCopied: 'Preview URL was copied.',
  },
};

export default i18nEN;
