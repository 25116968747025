import React from 'react';
import classNames from 'classnames';
import { translate } from 'react-i18next';

import Config from '../../config/Config';

class LoginContainer extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className={classNames({ loginContainer: true })}>
        <div className="login-wrap">

          <div className="header">
            <img src={Config.LOGO_IMAGE} alt="fulldepth" />
          </div>

          <div className="form">
            <div className="alert">
              {this.props.location.query.status === 'Failed' ? t('messages.authFailure') : null}
            </div>
            <div>
              <a href={Config.AUTH_ENDPOINT}>
                <i className="fa fa-google" />
                <span>Sign in with Google</span>
              </a>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default translate()(LoginContainer);
