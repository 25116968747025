import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

import { getName, timeFormat } from '../../util/util';
import Config from '../../config/Config';

const renderArticleHistory = (params, history) => {
  const statusClassNames = { status: true };
  statusClassNames[history.status] = true;

  return (
    <li
      className={classNames({ selected: Number(params.revision) === history.revision })}
      key={history.revision}
    >
      <Link to={`/article/${history.article_id}/history/${history.revision}`}>

        <span className={classNames(statusClassNames)}>
          {getName(Config.ARTICLE_STATUSES, history.status)}
        </span>

        <span>
          <i className="fa fa-clock-o" data-for="updatedAt" />
          {timeFormat(history.updated_at)}
        </span>

        <span>
          <i className="fa fa-user" data-for="updateUser" />
          {history.update_user_name}
        </span>

      </Link>
    </li>
  );
};

const ArticleHistoryList = ({ params, articleHistories }) => (
  <div className="articleHistoryList">
    <ul>
      {articleHistories.articles.map(history => renderArticleHistory(params, history))}
    </ul>
  </div>
);

export default ArticleHistoryList;
