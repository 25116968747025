import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ProductSetEditFormContainer from './ProductSetEditFormContainer';
import { fetchProductSet, fetchProductSetsWithCondition, updateProductSet } from '../../actions/productSetAction';
import { addLoading } from '../../actions/loadingAction';
import Config from '../../config/Config';

class ProductSetEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchProductSet(this.props.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { productSet } = this.props;

    if (productSet != null && Number(prevProps.params.id) !== productSet.product_set_id) {
      this.props.fetchProductSet(prevProps.params.id);
    }
  }

  handleCloseClick() {
    this.props.router.push('/product_set');
  }

  handleSubmit(form) {
    const newForm = { ...form };

    newForm.sort_key = Number(newForm.sort_key);

    if (newForm.top_view_enable == null || newForm.top_view_enable.length === 0) {
      newForm.top_view_enable = false;
    }

    this.props.addLoading();
    this.props.updateProductSet(newForm, () => this.props.fetchProductSetsWithCondition());
  }

  handlePreviewClick(e) {
    const { productSet } = this.props;
    window.open(`${Config.WEB_ENDPOINT}/product/set/preview/${productSet.key}/${productSet.preview_key}`, '_blank');
    e.stopPropagation();
  }

  render() {
    const { productSet, params } = this.props;

    if (productSet == null || params.id == null) {
      return null;
    }

    return (
      <div className="productSetEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
            <button className="previewButton" onClick={this.handlePreviewClick}>
              <i className="fa fa-eye" aria-hidden="true" />
              Preview
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          <ProductSetEditFormContainer
            id={params.id}
            productSet={productSet}
            saveButton={this.saveButton}
            closeButton={this.closeButton}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCloseClick}
            ref={(ref) => { this.productSetEditFormContainer = ref; }}
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    productSet: state.productSet,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchProductSet: id => dispatch(fetchProductSet(id)),
    fetchProductSetsWithCondition:
      (form, productSets, offset) => (
        dispatch(fetchProductSetsWithCondition(form, productSets, offset))),
    updateProductSet: (form, callback) => dispatch(updateProductSet(form, callback)),
    addLoading: () => dispatch(addLoading()),
  }
);

const productSetEditContainer = withRouter(ProductSetEditContainer);
export default connect(mapStateToProps, mapDispatchToProps)(productSetEditContainer);
