import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchCategories as fetchCategoriesAPI,
  fetchCategory as fetchCategoryAPI,
  createCategory as createCategoryAPI,
  updateCategory as updateCategoryAPI,
  deleteCategory as deleteCategoryAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_COMPLETE = 'FETCH_CATEGORIES_COMPLETE';
export const FETCH_CATEGORY_START = 'FETCH_CATEGORY_START';
export const FETCH_CATEGORY_COMPLETE = 'FETCH_CATEGORY_COMPLETE';
export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_COMPLETE = 'CREATE_CATEGORY_COMPLETE';
export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_COMPLETE = 'UPDATE_CATEGORY_COMPLETE';
export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_COMPLETE = 'DELETE_CATEGORY_COMPLETE';

const fetchCategoriesStart = () => ({ type: FETCH_CATEGORIES_START });

const fetchCategoriesComplete = res => ({
  type: FETCH_CATEGORIES_COMPLETE,
  payload: res,
});

const fetchCategoryStart = () => ({ type: FETCH_CATEGORY_START });

const fetchCategoryComplete = res => ({
  type: FETCH_CATEGORY_COMPLETE,
  payload: res,
});

const createCategoryStart = () => ({ type: CREATE_CATEGORY_START });

const createCategoryComplete = res => ({
  type: CREATE_CATEGORY_COMPLETE,
  payload: res,
});

const updateCategoryStart = () => ({ type: UPDATE_CATEGORY_START });

const updateCategoryComplete = res => ({
  type: UPDATE_CATEGORY_COMPLETE,
  payload: res,
});

const deleteCategoryStart = () => ({ type: DELETE_CATEGORY_START });

const deleteCategoryComplete = () => ({
  type: DELETE_CATEGORY_COMPLETE,
  payload: {},
});

export const fetchCategories = () => (
  (dispatch) => {
    dispatch(fetchCategoriesStart());

    fetchCategoriesAPI().then((res) => {
      dispatch(fetchCategoriesComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchCategoryError', { message: res.text })));
    });
  }
);

export const fetchCategory = id => (
  (dispatch) => {
    dispatch(fetchCategoryStart());

    fetchCategoryAPI(id).then((res) => {
      dispatch(fetchCategoryComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchCategoryError', { message: res.text })));
    });
  }
);

export const createCategory = (form, callback) => (
  (dispatch) => {
    dispatch(createCategoryStart());

    createCategoryAPI(form).then((res) => {
      dispatch(createCategoryComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createCategorySuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createCategoryError', { message: res.text })));
    });
  }
);

export const updateCategory = (form, callback) => (
  (dispatch) => {
    dispatch(updateCategoryStart());

    updateCategoryAPI(form).then((res) => {
      dispatch(updateCategoryComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.updateCategorySuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateCategoryError', { message: res.text })));
    });
  }
);

export const deleteCategory = id => (
  (dispatch) => {
    dispatch(deleteCategoryStart());

    deleteCategoryAPI(id).then(() => {
      dispatch(deleteCategoryComplete());
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.deleteCategorySuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.deleteCategoryError', { message: res.text })));
    });
  }
);
