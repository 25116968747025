import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';

import CategoryEditForm from '../../components/category/CategoryEditForm';

class CategoryEditFormContainer extends React.Component {
  render() {
    if (Number(this.props.id) !== this.props.category.category_id) {
      return null;
    }

    const {
      categoryEditForm,
      initialValues,
      category,
      saveButton,
      closeButton,
      onSubmit,
      onCancel,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = () => onSubmit(this.props.categoryEditForm);
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    return (
      <div className="categoryEditFormContainer">
        <CategoryEditForm
          form={categoryEditForm}
          initialValues={initialValues}
          category={category}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    categoryEditForm:
      state.form.categoryEditForm != null ? state.form.categoryEditForm.values : null,
    initialValues: state.category,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('categoryEditForm', key, value)),
  }
);

const categoryEditFormContainer = reduxForm({ form: 'categoryEditForm', enableReinitialize: true })(translate()(CategoryEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(categoryEditFormContainer);
