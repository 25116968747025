import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import Autosuggest from 'react-autosuggest';
import { translate } from 'react-i18next';
import classNames from 'classnames';

import ArticleEditForm from '../../components/article/ArticleEditForm';
import { fetchTags } from '../../actions/tagAction';
import { addSuccessAlert, addErrorAlert, addConfirmAlert } from '../../actions/alertAction';
import { compareForm, getStrLength } from '../../util/util';

class ArticleEditFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleEyeCatchClick = this.handleEyeCatchClick.bind(this);
    this.handleEyeCatchAddClick = this.handleEyeCatchAddClick.bind(this);
    this.handleEyeCatchToMainClick = this.handleEyeCatchToMainClick.bind(this);
    this.handleEyeCatchRemoveClick = this.handleEyeCatchRemoveClick.bind(this);
    this.handleImageUploadSubmit = this.handleImageUploadSubmit.bind(this);
    this.handleImageUploadCancel = this.handleImageUploadCancel.bind(this);

    this.handleHeaderVideoURLsOpenClick
      = this.handleHeaderVideoURLsOpenClick.bind(this);

    this.handleTitlesAddClick = this.handleTitlesAddClick.bind(this);
    this.handleTitlesToMainClick = this.handleTitlesToMainClick.bind(this);
    this.handleTitlesRemoveClick = this.handleTitlesRemoveClick.bind(this);

    this.handleItemsAddClick = this.handleItemsAddClick.bind(this);
    this.handleItemsEditClick = this.handleItemsEditClick.bind(this);
    this.handleItemsCopyClick = this.handleItemsCopyClick.bind(this);
    this.handleItemsUpClick = this.handleItemsUpClick.bind(this);
    this.handleItemsDownClick = this.handleItemsDownClick.bind(this);
    this.handleItemsRemoveClick = this.handleItemsRemoveClick.bind(this);

    this.handleCopyToClipboardClick = this.handleCopyToClipboardClick.bind(this);

    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleTagsRenderInput = this.handleTagsRenderInput.bind(this);
    this.handleTagsRenderTag = this.handleTagsRenderTag.bind(this);

    this.handleDescriptionKeyUp = this.handleDescriptionKeyUp.bind(this);

    this.handlePublishSettingClick = this.handlePublishSettingClick.bind(this);
    this.handlePublishSettingSubmit = this.handlePublishSettingSubmit.bind(this);
    this.handlePublishSettingReserved = this.handlePublishSettingReserved.bind(this);
    this.handlePublishSettingCancel = this.handlePublishSettingCancel.bind(this);

    this.handleSectionSubmit = this.handleSectionSubmit.bind(this);
    this.handleSectionCancel = this.handleSectionCancel.bind(this);
    this.handleSectionBlur = this.handleSectionBlur.bind(this);

    this.refDescription = this.refDescription.bind(this);
    this.refDescriptionLabel = this.refDescriptionLabel.bind(this);
    this.refTagsInput = this.refTagsInput.bind(this);

    this.state = {
      prevTagsValue: '',
      selectedEyeCatch: 0,
      selectedItemIndex: -1,
      isDisplayImageUploadPopup: false,
      isDisplayOriginalRatioImageUploadPopup: false,
      isDisplayPublishSettingPopup: false,
      showHeaderVideoURLs:
        props.article.mobile_header_video_url || props.article.pc_header_video_url,
    };
  }

  componentWillMount() {
    const savedForm = JSON.parse(localStorage.getItem(`articleEditForm_${this.props.id}`));

    if (savedForm != null && savedForm.revision !== this.props.initialValues.revision) {
      localStorage.removeItem(`articleEditForm_${this.props.id}`);
    } else if (savedForm != null && !compareForm(savedForm, this.props.initialValues)) {
      this.props.addConfirmAlert(
        this.props.t('messages.restoreFromLocalStorage'),
        () => {
          const articleEditForm = JSON.parse(localStorage.getItem(`articleEditForm_${this.props.id}`));

          if (articleEditForm != null) {
            Object.keys(articleEditForm).map(key => (
              this.props.updateForm(key, articleEditForm[key])
            ));
          }
        },
        () => localStorage.removeItem(`articleEditForm_${this.props.id}`),
      );
    }
  }

  componentDidUpdate() {
    const {
      articleEditForm,
      initialValues,
      id,
      onEditing,
      onUnEditing,
    } = this.props;

    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }

    if (window.twttr) {
      window.twttr.widgets.load();
    }

    if (Object.keys(articleEditForm).length > 0) {
      if (!compareForm(articleEditForm, initialValues)) {
        localStorage.setItem(`articleEditForm_${articleEditForm.article_id}`, JSON.stringify(articleEditForm));
        onEditing();
      } else {
        const savedForm = JSON.parse(localStorage.getItem(`articleEditForm_${id}`));
        if (savedForm != null && compareForm(savedForm, articleEditForm)) {
          localStorage.removeItem(`articleEditForm_${id}`);
          onUnEditing();
        }
      }
    }
  }

  handleEyeCatchClick(e) {
    this.setState({ selectedEyeCatch: Number(e.target.dataset.key) });
  }

  handleEyeCatchAddClick() {
    this.setState({ isDisplayImageUploadPopup: true });
  }

  handleEyeCatchToMainClick() {
    if (this.props.articleEditForm.eye_catch_urls == null
      || this.props.articleEditForm.eye_catch_urls.length <= 1) {
      return;
    }

    const eyeCatchURLs = [].concat(this.props.articleEditForm.eye_catch_urls);

    const target = eyeCatchURLs.splice(this.state.selectedEyeCatch, 1);
    eyeCatchURLs.unshift(target[0]);

    this.setState({ selectedEyeCatch: 0 });

    this.props.updateForm('eye_catch_urls', eyeCatchURLs);
  }

  handleEyeCatchRemoveClick() {
    if (this.props.articleEditForm.eye_catch_urls == null
      || this.props.articleEditForm.eye_catch_urls.length === 0) {
      return;
    }

    const eyeCatchURLs = [].concat(this.props.articleEditForm.eye_catch_urls);

    eyeCatchURLs.splice(this.state.selectedEyeCatch, 1);

    if (eyeCatchURLs.length === this.state.selectedEyeCatch) {
      this.setState({ selectedEyeCatch: this.state.selectedEyeCatch - 1 });
    }

    this.props.updateForm('eye_catch_urls', eyeCatchURLs);
  }

  handleImageUploadSubmit(image) {
    const url = image.url;
    const eyeCatchURLs = this.props.articleEditForm.eye_catch_urls;

    const urls = eyeCatchURLs != null ? [].concat(eyeCatchURLs) : [];
    urls.push(url);

    this.setState({ selectedEyeCatch: urls.length - 1 });

    this.props.updateForm('eye_catch_urls', urls);

    this.setState({ isDisplayImageUploadPopup: false });
  }

  handleImageUploadCancel() {
    this.setState({
      isDisplayImageUploadPopup: false,
      isDisplayOriginalRatioImageUploadPopup: false,
    });
  }

  handleTitlesAddClick() {
    let titles = [].concat(this.props.articleEditForm.titles);
    titles.push('');
    titles = titles.filter(v => v != null);

    this.props.updateForm('titles', titles);
  }

  handleTitlesToMainClick(e) {
    const { titles } = this.props.articleEditForm;

    if (titles.length <= 1) {
      return;
    }

    const i = Number(e.target.dataset.key);

    const newTitles = [].concat(titles);
    const target = newTitles.splice(i, 1);
    newTitles.unshift(target[0]);

    this.props.updateForm('titles', newTitles);
  }

  handleTitlesRemoveClick(e) {
    const { titles } = this.props.articleEditForm;

    if (titles.length <= 1) {
      return;
    }

    const i = Number(e.target.dataset.key);

    const newTitles = titles != null ? [].concat(titles) : [];
    newTitles.splice(i, 1);

    this.props.updateForm('titles', newTitles);
  }

  handleItemsAddClick(e) {
    const articleItems = this.props.articleEditForm.article_items;
    const i = Number(e.target.dataset.key);

    if (this.state.selectedItemIndex !== -1) {
      this.setState({ selectedItemIndex: -1 });
      return;
    }

    const items = articleItems != null ? [].concat(articleItems) : [];

    items.splice(i, 0, {
      content: '',
      input_type: 'sentence',
      media_type: '',
      meta: '',
    });

    this.props.updateForm('article_items', items);

    this.setState({ selectedItemIndex: i });
  }

  handleItemsEditClick(key) {
    this.setState({ selectedItemIndex: Number(key) });
  }

  handleItemsCopyClick(e) {
    const i = Number(e.target.dataset.key);

    if (this.state.selectedItemIndex !== -1) {
      this.setState({ selectedItemIndex: -1 });
      return;
    }

    const items = [].concat(this.props.articleEditForm.article_items);
    const copyTarget = JSON.parse(JSON.stringify(items[i]));

    items.splice(i, 0, copyTarget);

    this.props.updateForm('article_items', items);
  }

  handleItemsUpClick(e) {
    const i = Number(e.target.dataset.key);

    if (this.state.selectedItemIndex !== -1) {
      this.setState({ selectedItemIndex: -1 });
      return;
    }

    if (i === 0) {
      return;
    }

    const items = [].concat(this.props.articleEditForm.article_items);

    const target = items.splice(i, 1);
    items.splice(i - 1, 0, target[0]);

    this.props.updateForm('article_items', items);
  }

  handleItemsDownClick(e) {
    const i = Number(e.target.dataset.key);

    if (this.state.selectedItemIndex !== -1) {
      this.setState({ selectedItemIndex: -1 });
      return;
    }

    if (i === this.props.articleEditForm.article_items.length - 1) {
      return;
    }

    const items = [].concat(this.props.articleEditForm.article_items);

    const target = items.splice(i, 1);
    items.splice(i + 1, 0, target[0]);

    this.props.updateForm('article_items', items);
  }

  handleItemsRemoveClick(e) {
    const i = Number(e.target.dataset.key);

    if (this.state.selectedItemIndex !== -1) {
      this.setState({ selectedItemIndex: -1 });
      return;
    }

    const items = [].concat(this.props.articleEditForm.article_items);

    items.splice(i, 1);

    this.props.updateForm('article_items', items);
  }

  handleSectionSubmit(item, selectedIndex) {
    const items = [].concat(this.props.articleEditForm.article_items);
    items[selectedIndex] = item;

    console.warn('index: %d, value: %o', selectedIndex, item);

    this.props.updateForm('article_items', items);

    this.setState({ selectedItemIndex: -1 });
  }

  handleSectionCancel() {
    this.setState({ selectedItemIndex: -1 });
  }

  handleSectionBlur(item, selectedIndex) {
    const items = [].concat(this.props.articleEditForm.article_items);
    items[selectedIndex] = item;

    this.props.updateForm('article_items', items);
  }

  handleCopyToClipboardClick() {
    this.props.addSuccessAlert(this.props.t('messages.previewUrlCopied'));
  }

  handleTagsChange(tags) {
    this.props.updateForm('tags', tags);
  }

  handleTagsRenderTag(props) {
    const {
      tag,
      key,
      disabled,
      onRemove,
      className,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;

    return (
      <span
        key={key}
        data-key={key}
        className={classNames({
          'react-tagsinput-tag': true,
          selected: tag === this.props.articleEditForm.tag_for_breadcrumbs,
        })}
        {...other}
      >
        {getTagDisplayValue(tag)}
        {!disabled &&
          <span className={classNameRemove} onClick={() => { onRemove(key); }} />
        }
      </span>
    );
  }

  handleTagsRenderInput({ addTag, value, ref, onChange }) {
    const handleChange = (e, { newValue }) => {
      const tags = this.props.articleEditForm.tags || [];

      if (newValue.indexOf(',') >= 0 || newValue.indexOf('  ') >= 0) {
        const tag = newValue.replace(/,/g, '').trim();

        if (tag.length > 0 && !tags.includes(tag)) {
          this.handleTagsChange(tags.concat([tag]));
        }

        this.tagsInput.setState({ tag: '' });
      } else {
        onChange(e);
      }
    };

    const handleKeyUp = (e) => {
      const tags = this.props.articleEditForm.tags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Backspace') {
        if (this.state.prevTagsValue === '') {
          this.handleTagsChange(tags.slice(0, tags.length - 1));
        }
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleKeyPress = (e) => {
      const tags = this.props.articleEditForm.tags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Enter') {
        if (newValue.length > 0 && !tags.includes(newValue)) {
          this.handleTagsChange(tags.concat([newValue]));
        }

        this.tagsInput.setState({ tag: '' });
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleBlur = () => {
      this.tagsInput.setState({ tag: '' });
    };

    return (
      <Autosuggest
        ref={ref}
        suggestions={this.props.suggestTags.tags || []}
        shouldRenderSuggestions={v => v != null && v.trim().length > 0}
        getSuggestionValue={suggestion => suggestion.name}
        renderSuggestion={suggestion => <span>{suggestion.name}</span>}
        inputProps={{
          value,
          placeholder: this.props.t('article.tagsPlaceholder'),
          onChange: handleChange,
          onKeyUp: handleKeyUp,
          onKeyPress: handleKeyPress,
          onBlur: handleBlur,
        }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.name);
        }}
        onSuggestionsFetchRequested={(e) => {
          this.props.fetchTags({ prefix: e.value });
        }}
        onSuggestionsClearRequested={() => {}}
      />
    );
  }

  handleDescriptionKeyUp() {
    if (this.description != null) {
      this.descriptionLabel.innerHTML = this.props.t('article.descriptionCount', { count: getStrLength(this.description.value) });
    }
  }

  handlePublishSettingClick() {
    this.setState({ isDisplayPublishSettingPopup: true });
  }

  handlePublishSettingSubmit() {
    this.props.onSubmit(this.props.articleEditForm);
    this.setState({ isDisplayPublishSettingPopup: false });
  }

  handlePublishSettingReserved() {
    this.props.onSubmit(this.props.articleEditForm, 'reserved');
    this.setState({ isDisplayPublishSettingPopup: false });
  }

  handlePublishSettingCancel() {
    this.setState({ isDisplayPublishSettingPopup: false });
  }

  handleHeaderVideoURLsOpenClick() {
    this.setState({ showHeaderVideoURLs: !this.state.showHeaderVideoURLs });
  }

  refDescription(ref) {
    this.description = ref;
  }

  refDescriptionLabel(ref) {
    this.descriptionLabel = ref;
  }

  refTagsInput(ref) {
    this.tagsInput = ref;
  }

  render() {
    if (Number(this.props.id) !== this.props.article.article_id) {
      return null;
    }

    const {
      id,
      permissions,
      article,
      articleEditForm,
      initialValues,
      authors,
      categories,
      saveButton,
      closeButton,
      deleteButton,
      historyButton,
      publishSettingButton,
      draftButton,
      onSubmit,
      onCancel,
      onHistoryClick,
      t,
    } = this.props;

    const {
      selectedItemIndex,
      showHeaderVideoURLs,
      selectedEyeCatch,
      isDisplayImageUploadPopup,
      isDisplayOriginalRatioImageUploadPopup,
      isDisplayPublishSettingPopup,
    } = this.state;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = () => {
        if (selectedItemIndex !== -1) {
          this.props.addErrorAlert(this.props.t('messages.warningEditingSection'));
          return;
        }

        if ((this.props.articleEditForm.status === 'posted' || this.props.articleEditForm.status === 'reserved')) {
          const articleItems = this.props.articleEditForm.article_items;
          const length = articleItems.length;
          for (let index = 0; index < length; index += 1) {
            const articleItem = articleItems[index];
            if (articleItem.input_type === 'image' && (!articleItem.meta.credit || !articleItem.meta.credit.name || articleItem.meta.credit.name === '©')) {
              this.props.addErrorAlert(this.props.t('messages.warningRequireImageCredit'));
              return;
            }
          }
        }

        onSubmit(this.props.articleEditForm);
      };
    }

    if (publishSettingButton != null && publishSettingButton.onclick == null) {
      publishSettingButton.onclick =
        () => this.handlePublishSettingClick();
    }

    if (deleteButton != null && deleteButton.onclick == null) {
      deleteButton.onclick = () => {
        this.props.addConfirmAlert(
          this.props.t('messages.deleteArticleConfirm'),
          () => {
            onSubmit(this.props.articleEditForm, 'deleted');
          },
        );
      };
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    if (historyButton != null && historyButton.onclick == null) {
      historyButton.onclick =
        () => onHistoryClick();
    }

    if (draftButton != null && draftButton.onclick == null) {
      draftButton.onclick = () => {
        onSubmit(this.props.articleEditForm, 'draft');
      };
    }

    return (
      <div className="articleEditFormContainer">
        <ArticleEditForm
          id={id}
          form={articleEditForm}
          article={article}
          authors={authors}
          categories={categories}
          permissions={permissions}
          initialValues={initialValues}
          onEyeCatchClick={this.handleEyeCatchClick}
          onEyeCatchAddClick={this.handleEyeCatchAddClick}
          onEyeCatchToMainClick={this.handleEyeCatchToMainClick}
          onEyeCatchRemoveClick={this.handleEyeCatchRemoveClick}
          onHeaderVideoURLsOpenClick={this.handleHeaderVideoURLsOpenClick}
          showHeaderVideoURLs={showHeaderVideoURLs}
          onTitlesAddClick={this.handleTitlesAddClick}
          onTitlesToMainClick={this.handleTitlesToMainClick}
          onTitlesRemoveClick={this.handleTitlesRemoveClick}
          onItemsAddClick={this.handleItemsAddClick}
          onItemsEditClick={this.handleItemsEditClick}
          onItemsCopyClick={this.handleItemsCopyClick}
          onItemsUpClick={this.handleItemsUpClick}
          onItemsDownClick={this.handleItemsDownClick}
          onItemsRemoveClick={this.handleItemsRemoveClick}
          onCopyToClipboardClick={this.handleCopyToClipboardClick}
          onTagsChange={this.handleTagsChange}
          onTagsRenderInput={this.handleTagsRenderInput}
          onTagsRenderTag={this.handleTagsRenderTag}
          onDescriptionKeyUp={this.handleDescriptionKeyUp}
          onSectionSubmit={this.handleSectionSubmit}
          onSectionCancel={this.handleSectionCancel}
          onSectionBlur={this.handleSectionBlur}
          refDescription={this.refDescription}
          refDescriptionLabel={this.refDescriptionLabel}
          refTagsInput={this.refTagsInput}
          selectedEyeCatch={selectedEyeCatch}
          selectedItemIndex={selectedItemIndex}
          isDisplayImageUploadPopup={isDisplayImageUploadPopup}
          isDisplayOriginalRatioImageUploadPopup={isDisplayOriginalRatioImageUploadPopup}
          onImageUploadSubmit={this.handleImageUploadSubmit}
          onImageUploadCancel={this.handleImageUploadCancel}
          isDisplayPublishSettingPopup={isDisplayPublishSettingPopup}
          onPublishSettingSubmit={this.handlePublishSettingSubmit}
          onPublishSettingReserved={this.handlePublishSettingReserved}
          onPublishSettingCancel={this.handlePublishSettingCancel}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    permissions: state.permissions,
    articleEditForm: state.form.articleEditForm != null ? state.form.articleEditForm.values : null,
    suggestTags: state.tags,
    initialValues: state.article,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('articleEditForm', key, value)),
    fetchTags: (form, callback) => dispatch(fetchTags(form, callback)),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
    addErrorAlert: value => dispatch(addErrorAlert(value)),
    addConfirmAlert:
      (message, onSubmit, onCancel) => dispatch(addConfirmAlert(message, onSubmit, onCancel)),
  }
);

const articleEditFormContainer = reduxForm({ form: 'articleEditForm', enableReinitialize: true })(translate()(ArticleEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(articleEditFormContainer);
