import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';

import ProductOptionEditForm from '../../components/productOption/ProductOptionEditForm';
import Config from '../../config/Config';

class ProductOptionEditFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handlePurposesToggleClick = this.handlePurposesToggleClick.bind(this);
    this.handleSummariesAddClick = this.handleSummariesAddClick.bind(this);
    this.handleSummariesRemoveClick = this.handleSummariesRemoveClick.bind(this);
    this.handleFeatureFunctionsAddClick = this.handleFeatureFunctionsAddClick.bind(this);
    this.handleFeatureFunctionsRemoveClick = this.handleFeatureFunctionsRemoveClick.bind(this);
    this.handleFeatureFunctionsChange = this.handleFeatureFunctionsChange.bind(this);

    this.state = {
      featureFunctions: null,
    };
  }

  componentWillMount() {
    const featureFunctions = this.props.productOption.feature_functions;

    this.setState({ featureFunctions });
  }

  componentWillReceiveProps() {
    const featureFunctions = Object.assign({}, this.state.featureFunctions);
    this.setState({ featureFunctions });
  }

  handlePurposesToggleClick(e) {
    let newPurposes = this.props.productOptionEditForm.purposes;
    const toggleDataName = e.target.dataset.name;
    const PRODUCT_OPTION_PURPOSE_TYPES_NAME = Config.PRODUCT_OPTION_PURPOSE_TYPES.map(p => p.id);
    newPurposes = newPurposes.filter(p => (
      PRODUCT_OPTION_PURPOSE_TYPES_NAME.includes(p)
    ));

    if (e.target.classList.contains('selected')) {
      if (document.getElementsByClassName('purposes selected').length > 1) {
        e.target.classList.remove('selected');
        const index = newPurposes.indexOf(toggleDataName);
        newPurposes.splice(index, 1);
      }
    } else {
      e.target.classList.add('selected');
      newPurposes.push(toggleDataName);
    }

    this.props.updateForm('purposes', newPurposes);
  }

  handleSummariesAddClick() {
    let summaries = [].concat(this.props.productOptionEditForm.summaries);
    summaries.push('');
    summaries = summaries.filter(v => v != null);

    this.props.updateForm('summaries', summaries);
  }

  handleSummariesRemoveClick(e) {
    const summaries = this.props.productOptionEditForm.summaries;

    if (summaries.length <= 1) {
      return;
    }

    const i = Number(e.target.dataset.key);

    const newSummaries = summaries != null ? [].concat(summaries) : [];
    newSummaries.splice(i, 1);

    this.props.updateForm('summaries', newSummaries);
  }

  handleFeatureFunctionsAddClick() {
    let featureFunctions = [].concat(this.props.productOptionEditForm.feature_functions);
    featureFunctions.push({
      name: 'no name',
      eyecatch_type: 'image',
      image_url: '',
      youtube_video_id: '',
      example: '',
      equipment: '',
      text: '',
    });
    featureFunctions = featureFunctions.filter(v => v != null);

    this.props.updateForm('feature_functions', featureFunctions);
  }

  handleFeatureFunctionsRemoveClick(e) {
    const featureFunctions = this.props.productOptionEditForm.feature_functions;

    if (featureFunctions.length <= 1) {
      return;
    }

    const i = Number(e.target.dataset.key);

    const newFeatureFunctions = featureFunctions != null ? [].concat(featureFunctions) : [];
    newFeatureFunctions.splice(i, 1);

    this.props.updateForm('feature_functions', newFeatureFunctions);
  }

  handleFeatureFunctionsChange(e) {
    const newFeatureFunctions = [].concat(this.props.productOptionEditForm.feature_functions);
    const i = Number(e.target.dataset.key);
    const changeType = e.target.dataset.name;
    newFeatureFunctions[i][changeType] = e.target.value;

    this.props.updateForm('feature_functions', newFeatureFunctions);
    if (changeType === 'image_url') {
      document.getElementById(`featureFunctions${i}ImagePreview`).src =
      newFeatureFunctions[i].image_url || '/assets/images/no_image.png';
    } else if (changeType === 'youtube_video_id') {
      document.getElementById(`featureFunctions${i}YoutubePreview`).src =
        `https://www.youtube.com/embed/${newFeatureFunctions[i].youtube_video_id}`;
    }
  }

  handleDetailYoutubeVideoIDChange(e) {
    const id = e.target.value;
    document.getElementById('detailYoutubeVideoID').src = `https://www.youtube.com/embed/${id}`;
  }

  render() {
    if (Number(this.props.id) !== this.props.productOption.product_option_id) {
      return null;
    }

    const {
      productOptionEditForm,
      permissions,
      initialValues,
      productOption,
      saveButton,
      closeButton,
      onSubmit,
      onCancel,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = () => onSubmit(this.props.productOptionEditForm);
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    return (
      <div className="productOptionEditFormContainer">
        <ProductOptionEditForm
          form={productOptionEditForm}
          permissions={permissions}
          initialValues={initialValues}
          productOption={productOption}
          onPurposesToggleClick={this.handlePurposesToggleClick}
          onSummariesAddClick={this.handleSummariesAddClick}
          onSummariesRemoveClick={this.handleSummariesRemoveClick}
          onFeatureFunctionsAddClick={this.handleFeatureFunctionsAddClick}
          onFeatureFunctionsRemoveClick={this.handleFeatureFunctionsRemoveClick}
          onFeatureFunctionsChange={this.handleFeatureFunctionsChange}
          onDetailYoutubeVideoIDChange={this.handleDetailYoutubeVideoIDChange}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    productOptionEditForm:
      state.form.productOptionEditForm != null ? state.form.productOptionEditForm.values : null,
    initialValues: state.productOption,
    permissions: state.permissions,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('productOptionEditForm', key, value)),
  }
);

const productOptionEditFormContainer = reduxForm({ form: 'productOptionEditForm', enableReinitialize: true })(translate()(ProductOptionEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(productOptionEditFormContainer);
