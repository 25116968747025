import {
  FETCH_AUTHOR_START,
  FETCH_AUTHOR_COMPLETE,
  CREATE_AUTHOR_START,
  CREATE_AUTHOR_COMPLETE,
  UPDATE_AUTHOR_START,
  UPDATE_AUTHOR_COMPLETE,
  DELETE_AUTHOR_START,
  DELETE_AUTHOR_COMPLETE,
} from '../actions/authorAction';

const authorReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_AUTHOR_START:
      return state;

    case FETCH_AUTHOR_COMPLETE:
      return action.payload;

    case CREATE_AUTHOR_START:
      return state;

    case CREATE_AUTHOR_COMPLETE:
      return action.payload;

    case UPDATE_AUTHOR_START:
      return state;

    case UPDATE_AUTHOR_COMPLETE:
      return action.payload;

    case DELETE_AUTHOR_START:
      return state;

    case DELETE_AUTHOR_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default authorReducer;
