const appEnv = require(`./_env-${process.env.ENV_NAME}`).default;

const Config = {
  ENV: appEnv.ENV,
  NAMESPACE: appEnv.NAMESPACE,
  LOGO_IMAGE: appEnv.LOGO_IMAGE,

  WEB_ENDPOINT: appEnv.WEB_ENDPOINT,
  CMS_ENDPOINT: appEnv.CMS_ENDPOINT,
  API_ENDPOINT: appEnv.API_ENDPOINT,
  AUTH_ENDPOINT: appEnv.AUTH_ENDPOINT,

  ARTICLE_BRANDS: [
    { id: 'corporate', name: 'Corporate' },
    { id: 'shinkai', name: 'Shinkai' },
    { id: 'stories', name: 'Stories' },
  ],

  ARTICLE_CONTENT_TYPES: [
    { id: 'news', i18nKey: 'common.articleContentTypes.news' },
    { id: 'product', i18nKey: 'common.articleContentTypes.product' },
    { id: 'event', i18nKey: 'common.articleContentTypes.event' },
    { id: 'original', i18nKey: 'common.articleContentTypes.original' },
  ],

  ARTICLE_STATUSES: [
    { id: 'draft', i18nKey: 'common.articleStatuses.draft' },
    { id: 'reserved', i18nKey: 'common.articleStatuses.reserved' },
    { id: 'posted', i18nKey: 'common.articleStatuses.posted' },
    { id: 'deleted', i18nKey: 'common.articleStatuses.deleted' },
  ],

  ARTICLE_INPUT_TYPES: [
    { id: 'credit', i18nKey: 'common.articleInputTypes.credit' },
    { id: 'relation_article', i18nKey: 'common.articleInputTypes.relationArticle' },
    { id: 'reference', i18nKey: 'common.articleInputTypes.reference' },
  ],

  ARTICLE_CREDIT_TYPES: [
    { id: 'copyright', name: '©' },
    { id: 'free', name: 'Free' },
  ],

  ARTICLE_CREDIT_TYPE_VALUES: {
    copyright: '© ',
    free: '',
  },

  PRODUCT_FEATURE_FUNCTIONS_EYECATCH_TYPE: [
    { id: 'image', name: 'image' },
    { id: 'video', name: 'video' },
  ],

  PRODUCT_STATUSES: [
    { id: 'draft', i18nKey: 'common.articleStatuses.draft' },
    { id: 'posted', i18nKey: 'common.articleStatuses.posted' },
  ],

  MATCH_OR_NOT: [
    { id: 'match', i18nKey: 'common.match' },
    { id: 'not_match', i18nKey: 'common.notMatch' },
  ],

  IMAGE_IMAGE_TYPES: [
    { id: 'eye_catch', i18nKey: 'common.imageImageTypes.eyeCatch' },
    { id: 'article', i18nKey: 'common.imageImageTypes.article' },
    { id: 'material', i18nKey: 'common.imageImageTypes.material' },
  ],

  PRODUCT_OPTION_PURPOSE_TYPES: [
    { id: 'performance', i18nKey: 'common.productOptionPurposeTypes.performance' },
    { id: 'watching', i18nKey: 'common.productOptionPurposeTypes.watching' },
    { id: 'measuring', i18nKey: 'common.productOptionPurposeTypes.measuring' },
    { id: 'collection', i18nKey: 'common.productOptionPurposeTypes.collection' },
    { id: 'cleaning', i18nKey: 'common.productOptionPurposeTypes.cleaning' },
  ],

  PERMISSIONS: {
    article: 'article',
    article_all_read: 'article_all_read',
    article_edit: 'article_edit',
    article_status_edit: 'article_status_edit',
    image: 'image',
    issue: 'issue',
    issue_all_read: 'issue_all_read',
    issue_edit: 'issue_edit',
    issue_status_edit: 'issue_edit',
    product_set: 'product_set',
    product_set_all_read: 'product_set_all_read',
    product_set_edit: 'product_set_edit',
    product_set_status_edit: 'product_set_edit',
    product_option: 'product_option',
    product_option_all_read: 'product_option_all_read',
    product_option_edit: 'product_option_edit',
    product_option_status_edit: 'product_option_edit',
    author: 'author',
    category: 'category',
    tag: 'tag',
    user: 'user',
  },

  LANGUAGES: [
    { id: 'en', name: 'English' },
    { id: 'ja', name: '日本語' },
  ],
};

export default Config;
