import { browserHistory } from 'react-router';
import Cookies from 'universal-cookie';

import { fetchPermissions as fetchPermissionsAPI } from '../api/apiClient';
import Config from '../config/Config';

export const FETCH_PERMISSIONS_START = 'FETCH_PERMISSIONS_START';
export const FETCH_PERMISSIONS_COMPLETE = 'FETCH_PERMISSIONS_COMPLETE';

const fetchPermissionsStart = () => ({ type: FETCH_PERMISSIONS_START });

const fetchPermissionsComplete = res => ({
  type: FETCH_PERMISSIONS_COMPLETE,
  payload: res,
});

export const fetchPermissions = token => (
  (dispatch) => {
    dispatch(fetchPermissionsStart());

    fetchPermissionsAPI(token).then((res) => {
      dispatch(fetchPermissionsComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);

      const cookies = new Cookies();
      cookies.set('auth_token', null, { path: '/', secure: Config.ENV !== 'dev', maxAge: 60 * 60 * 24 * 90 });

      browserHistory.push('/login?status=Failed');
    });
  }
);
