import React from 'react';

const Loading = () => (
  <div className="loading">
    <div className="loading-inner">
      <i className="fa fa-refresh fa-spin" />
      <div>working</div>
    </div>
  </div>
);

export default Loading;
