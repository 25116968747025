import React from 'react';
import TinyMCE from 'react-tinymce';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import ArticleSearchPopupContainer from '../../containers/article/ArticleSearchPopupContainer';
import ImageSearchPopupContainer from '../../containers/image/ImageSearchPopupContainer';
import ImageUploadPopupContainer from '../../containers/image/ImageUploadPopupContainer';
import ImageEditPopupContainer from '../../containers/image/ImageEditPopupContainer';
import Config from '../../config/Config';
import i18n from '../../config/i18n';

const creditType = (inputType, content) => {
  if (inputType === 'credit') {
    if (content[0].type_key == null || content[0].type_key === 'free') {
      return content[0].type_key;
    }

    return Config.ARTICLE_CREDIT_TYPE_VALUES[content[0].type_key];
  }

  return Config.ARTICLE_CREDIT_TYPE_VALUES.copyright;
};

const ArticleEditSection = (props) => {
  const tinymceLang = {
    en: null,
    ja: 'ja',
  };

  const TINYMCE_CONFIGS = {
    sentence: {
      selector: 'textarea',
      height: 300,
      language: tinymceLang[i18n.language],
      menubar: false,
      plugins: 'link hr lists table',
      toolbar1:
        'undo redo styleselect blockquote bold italic underline hr removeformat | alignleft aligncenter alignright | link unlink',
      toolbar2: 'numlist bullist | table',
      table_advtab: false,
      table_cell_advtab: false,
      table_row_advtab: false,
      toolbar_items_size: 'small',
      content_css: '/assets/stylesheets/tinymce_content.css',
      style_formats: [
        { title: 'small', inline: 'span', classes: 'text-small' },
        { title: 'red', inline: 'span', styles: { color: '#f00' } },
        { title: 'blue', inline: 'span', styles: { color: '#00f' } },
        { title: 'underline', inline: 'span', classes: 'underline' },
      ],
      default_link_target: '_blank',
    },
    heading: {
      selector: 'textarea',
      menubar: false,
      height: 120,
      language: tinymceLang[i18n.language],
      forced_root_block: 'h2',
      toolbar:
        'undo redo styleselect removeformat | alignleft aligncenter alignright',
      toolbar_items_size: 'small',
      content_css: '/assets/stylesheets/tinymce_content.css',
      style_formats: [
        { title: 'H2', block: 'h2' },
        { title: 'H3', block: 'h3' },
        { title: 'red', inline: 'span', styles: { color: '#f00' } },
        { title: 'blue', inline: 'span', styles: { color: '#00f' } },
      ],
      default_link_target: '_blank',
    },
    etc: {
      selector: 'textarea',
      menubar: false,
      height: 120,
      language: tinymceLang[i18n.language],
      plugins: 'link',
      toolbar:
        'undo redo styleselect blockquote bold italic removeformat | alignleft aligncenter alignright | link unlink',
      toolbar_items_size: 'small',
      content_css: '/assets/stylesheets/tinymce_content.css',
      style_formats: [
        { title: 'small', inline: 'span', classes: 'text-small' },
        { title: 'red', inline: 'span', styles: { color: '#f00' } },
        { title: 'blue', inline: 'span', styles: { color: '#00f' } },
      ],
      default_link_target: '_blank',
    },
  };

  const {
    articleItems,
    selectedInputType,
    onSubmit,
    onCancel,
    onInputTypeClick,
    onImageSearchClick,
    onImageSearchSubmit,
    onImageSearchCancel,
    onImageUploadClick,
    onImageUploadSubmit,
    onImageUploadCancel,
    onImageEditClick,
    onImageEditSubmit,
    onImageEditCancel,
    onEtcInputTypeChange,
    onCreditTypeChange,
    onCreditAddClick,
    onCreditRemoveClick,
    onRelatedArticleClick,
    onRelatedArticleSubmit,
    onRelatedArticleCancel,
    isDisplayArticleSearchPopup,
    isDisplayImageSearchPopup,
    isDisplayImageUploadPopup,
    isDisplayImageEditPopup,
    refImageSrc,
    refImageURL,
    refImageAlt,
    refHideCredit,
    refIsFullWidth,
    refEmbed,
    refEtcInputType,
    refCreditType,
    refCreditTypeKey,
    refCreditName,
    refCreditURL,
    refReferenceName,
    refReferenceURL,
    t,
  } = props;

  const inputType = selectedInputType;
  const isEtcType = ['credit', 'relation_article', 'reference'].includes(inputType);

  const articleSearchPopup = isDisplayArticleSearchPopup ?
    (<ArticleSearchPopupContainer
      onSubmit={onRelatedArticleSubmit}
      onCancel={onRelatedArticleCancel}
    />)
    : null;

  const imageSearchPopup = isDisplayImageSearchPopup ?
    (<ImageSearchPopupContainer
      onSubmit={onImageSearchSubmit}
      onCancel={onImageSearchCancel}
      inputType={inputType}
    />)
    : null;

  const imageUploadPopup = isDisplayImageUploadPopup ?
    (<ImageUploadPopupContainer
      onSubmit={onImageUploadSubmit}
      onCancel={onImageUploadCancel}
      inputType={inputType}
    />)
    : null;

  const imageEditPopup = isDisplayImageEditPopup ?
    (<ImageEditPopupContainer
      onSubmit={onImageEditSubmit}
      onCancel={onImageEditCancel}
      inputType={inputType}
      articleItems={articleItems}
    />)
    : null;

  return (
    <div className="articleEditSection">
      <div className="editHeader">
        <div className="buttons">
          <div className="button" onClick={onInputTypeClick}>
            <i
              className={classNames({ fa: true, 'fa-pencil': true, selected: inputType === 'sentence' })}
              data-type="sentence"
            >
              {t('article.sentence')}
            </i>
          </div>

          <div className="button" onClick={onInputTypeClick}>
            <i
              className={classNames({ fa: true, 'fa-newspaper-o': true, selected: inputType === 'heading' })}
              data-type="heading"
            >
              {t('article.heading')}
            </i>
          </div>

          <div className="button" onClick={onInputTypeClick}>
            <i
              className={classNames({ fa: true, 'fa-picture-o': true, selected: inputType === 'image' })}
              data-type="image"
            >
              {t('article.image')}
            </i>
          </div>

          <div className="button" onClick={onInputTypeClick}>
            <i
              className={classNames({ fa: true, 'fa-caret-square-o-right': true, selected: inputType === 'embed' })}
              data-type="embed"
            >
              {t('article.embed')}
            </i>
          </div>

          <div className="button" onClick={onInputTypeClick}>
            <i
              className={classNames({
                fa: true,
                'fa-exclamation-circle': true,
                selected: isEtcType,
              })}
              data-type="etc"
            >
              {t('article.etc')}
            </i>
          </div>
        </div>
      </div>

      <div className="body">
        {imageSearchPopup}
        {imageUploadPopup}
        {imageEditPopup}

        <div className={inputType === 'sentence' ? 'editor' : 'editor none'}>
          <div className="sentence">
            <TinyMCE
              key="sentence"
              content={articleItems.sentence.content}
              config={TINYMCE_CONFIGS.sentence}
            />
          </div>
        </div>

        <div className={inputType === 'heading' ? 'editor' : 'editor none'}>
          <div className="heading">
            <TinyMCE
              key="heading"
              content={articleItems.heading.content}
              config={TINYMCE_CONFIGS.heading}
            />
          </div>
        </div>

        <div className={inputType === 'image' ? 'editor' : 'editor none'}>
          <div className="image">
            <img
              ref={refImageSrc}
              src={articleItems.image.content !== '' ? articleItems.image.content : 'https://image.fulldepth.co.jp/medium/jp/no_image.png'}
              alt="content"
            />

            <div>
              <button type="button" onClick={onImageSearchClick}>Search</button>
              <button type="button" onClick={onImageUploadClick}>Upload</button>
              {articleItems.image.content !== '' ? <button type="button" onClick={onImageEditClick}>Edit</button> : null}
            </div>

            <div className="imageURL">
              <input
                name="image_url"
                ref={refImageURL}
                type="text"
                placeholder={t('article.imageURLPlaceholder')}
                defaultValue={articleItems.image.meta.link != null ? articleItems.image.meta.link.url : ''}
              />
            </div>

            <div className="imageAlt">
              <input
                name="image_alt"
                ref={refImageAlt}
                type="text"
                placeholder={t('article.imageAltPlaceholder')}
                defaultValue={articleItems.image.meta.link != null ? articleItems.image.meta.link.alt : ''}
              />
            </div>

            <div className="imageCredit">
              <div>{t('image.creditName')}: {articleItems.image.meta.credit ? articleItems.image.meta.credit.name : null}</div>
              <div>{t('image.creditURL')}: {articleItems.image.meta.credit ? articleItems.image.meta.credit.url : null}</div>
            </div>

            <div className="hideCredit">
              <input
                name="hide_credit"
                id="hideCredit"
                ref={refHideCredit}
                type="checkbox"
                defaultChecked={
                  articleItems.image.meta.credit ?
                    articleItems.image.meta.credit.hide_credit
                    : false
                }
              />
              <label className="checkbox" htmlFor="hideCredit">{t('image.hideCredit')}</label>
            </div>

            <div className="isFullWidth">
              <input
                name="is_full_width"
                id="isFullWidth"
                ref={refIsFullWidth}
                type="checkbox"
                defaultChecked={!!articleItems.image.meta.is_full_width}
              />
              <label className="checkbox" htmlFor="isFullWidth">{t('image.isFullWidth')}</label>
            </div>
          </div>
        </div>

        <div className={inputType === 'embed' ? 'editor' : 'editor none'}>
          <div className="embed">
            <textarea
              ref={refEmbed}
              defaultValue={articleItems.embed.content}
              placeholder={t('article.embedPlaceholder')}
            />
          </div>
        </div>

        <div className={isEtcType ? 'editor' : 'editor none'}>
          <div className="item">
            <div className="select-wrap">
              <select
                name="input_type"
                ref={refEtcInputType}
                defaultValue={isEtcType ? inputType : ''}
                onChange={onEtcInputTypeChange}
              >
                {
                  Config.ARTICLE_INPUT_TYPES
                    .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
                }
              </select>
            </div>
          </div>

          <div className={inputType === 'credit' ? '' : 'none'}>
            <div className="credit">
              <div className="item">
                <div className="select-wrap">
                  <select
                    name="type_key"
                    ref={refCreditTypeKey}
                    defaultValue={articleItems.credit.content[0].type_key || 'free'}
                    onChange={onCreditTypeChange}
                  >
                    {
                      Config.ARTICLE_CREDIT_TYPES
                        .map(e => <option value={e.id} key={e.id}>{e.name}</option>)
                    }
                  </select>
                </div>
              </div>

              <div className="item">
                <input
                  name="credit_type"
                  ref={refCreditType}
                  type="text"
                  disabled={articleItems.credit.content[0].type_key == null || articleItems.credit.content[0].type_key === 'free' ? '' : 'disabled'}
                  placeholder={t('article.creditTypePlaceholder')}
                  defaultValue={
                    articleItems.credit.content[0].type_key === 'free' ?
                      articleItems.credit.content[0].type_value :
                      creditType(inputType, articleItems.credit.content)
                  }
                />
              </div>
              <div className="creditItemContaner">
                {articleItems.credit.content[0].items.map((creditItem, i) => (
                  <div className="item" key={`credit-${i}`}>
                    <div className="creditName">
                      <input
                        name="credit_name"
                        ref={ref => refCreditName(ref, i)}
                        type="text"
                        data-key={i}
                        placeholder={t('article.creditNamePlaceholder')}
                        defaultValue={creditItem.name}
                      />
                    </div>

                    <div className="creditURL">
                      <input
                        name="credit_url"
                        ref={ref => refCreditURL(ref, i)}
                        type="text"
                        data-key={i}
                        placeholder={t('article.creditURLPlaceholder')}
                        defaultValue={creditItem.url}
                      />
                    </div>

                    <div className="creditRemove" onClick={onCreditRemoveClick}>
                      <i className="fa fa-trash-o" data-key={i} />
                    </div>
                  </div>
                ))}
              </div>
              <div className="item">
                <button
                  type="button"
                  className="creditAdd"
                  data-for="creditAdd"
                  data-tip={t('article.creditAddPlaceholder')}
                  onClick={onCreditAddClick}
                >
                  <i className="fa fa-plus" />
                </button>
                <ReactTooltip
                  id="creditAdd"
                  class="tooltip"
                  place="right"
                  effect="solid"
                  multiline
                />
              </div>
            </div>
          </div>

          <div className={inputType === 'relation_article' ? '' : 'none'}>
            <div className="relationArticle">
              <div className="item">
                <img
                  className="image"
                  src={articleItems.relation_article.image_url ? articleItems.relation_article.image_url : '/assets/images/no_image.png'}
                  alt="content"
                />

                <div className="content">
                  {articleItems.relation_article.content}
                </div>
              </div>
              {articleSearchPopup}
            </div>

            <div className="item">
              <button type="button" onClick={onRelatedArticleClick}>Search</button>
            </div>
          </div>

          <div className={inputType === 'reference' ? '' : 'none'}>
            <div className="reference">
              <div className="item">
                <input
                  name="reference_name"
                  ref={refReferenceName}
                  type="text"
                  placeholder={t('article.referenceNamePlaceholder')}
                  defaultValue={articleItems.reference.meta.reference_name}
                />
              </div>
              <div className="item">
                <input
                  name="reference_url"
                  ref={refReferenceURL}
                  type="text"
                  placeholder={t('article.referenceURLPlaceholder')}
                  defaultValue={articleItems.reference.meta.reference_url}
                />
              </div>
              <TinyMCE
                key="reference"
                content={articleItems.reference.content}
                config={TINYMCE_CONFIGS.etc}
              />
            </div>
          </div>

        </div>

      </div>

      <div className="footer">
        <div className="left">
          <button type="button" onClick={onCancel}>Cancel</button>
        </div>

        <div className="right">
          <button type="button" onClick={() => onSubmit(onSubmit)}>OK</button>
        </div>

        <div className="clear" />
      </div>
    </div>
  );
};

export default ArticleEditSection;
