import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';

import ImageEditPopupForm from '../../components/image/ImageEditPopupForm';
import { fetchImage, updateImage } from '../../actions/imageAction';
import { addLoading } from '../../actions/loadingAction';

class ImageEditPopupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const imageID = this.props.articleItems.image.meta.link.image_page_url.split('/')[3];
    this.props.fetchImage(Number(imageID));
  }

  handleSubmit(form) {
    const newForm = { ...form };
    const creditName = form.credit_name ? form.credit_name : '';
    const creditURL = form.credit_url ? form.credit_url : '';
    newForm.credit = JSON.stringify({ name: creditName, url: creditURL });

    this.props.addLoading();
    this.props.updateImage(
      newForm,
      this.props.onSubmit,
    );
  }

  render() {
    const { image, onCancel, imageEditPopupForm, t } = this.props;

    if (Object.keys(image).length === 0) {
      return null;
    }

    return (
      <div className="imageEditPopupContainer">
        <ImageEditPopupForm
          form={imageEditPopupForm}
          image={image}
          onSubmit={this.handleSubmit}
          onCancel={onCancel}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  imageEditPopupForm:
    state.form.imageEditPopupForm != null ? state.form.imageEditPopupForm.values : null,
  initialValues: state.image,
  image: state.image,
});

const mapDispatchToProps = dispatch => (
  {
    fetchImage: id => dispatch(fetchImage(id)),
    updateImage: (form, callback) => dispatch(updateImage(form, callback)),
    addLoading: () => dispatch(addLoading()),
  }
);

const imageEditPopupContainer = reduxForm({ form: 'imageEditPopupForm', enableReinitialize: true })(translate()(ImageEditPopupContainer));
export default connect(mapStateToProps, mapDispatchToProps)(translate()(imageEditPopupContainer));
