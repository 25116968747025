import React from 'react';
import { Field } from 'redux-form';

import Config from '../../config/Config';
import { getName, hasPermission, timeFormat } from '../../util/util';

const IssueEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    form,
    permissions,
    initialValues,
    issue,
    onIssuesAddClick,
    onIssuesRemoveClick,
    onSummariesAddClick,
    onSummariesRemoveClick,
    onFeatureFunctionsAddClick,
    onFeatureFunctionsRemoveClick,
    onFeatureFunctionsChange,
    onDetailYoutubeVideoIDChange,
    t,
  } = props;

  return (
    <div className="issueEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="id">ID</label>
            <div className="id">
              {issue.issue_id}
            </div>
          </div>

          {hasPermission(Config.PERMISSIONS.issue_status_edit, permissions) ?
            <div className="item narrow">
              <label htmlFor="status">{t('issue.status')}</label>
              <div className="status">
                <div className="select-wrap">
                  <Field name="status" component="select">
                    {Config.PRODUCT_STATUSES.map(e => (
                      <option value={e.id} key={e.id}>
                        {getName(Config.PRODUCT_STATUSES, e.id)}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            </div> : null
          }

          <div className="item narrow">
            <label htmlFor="name">{t('issue.name')}</label>
            <div className="name">
              <Field name="name" component="input" type="text" />
            </div>
          </div>

          <div className="item narrow">
            <label htmlFor="key">{t('issue.key')}</label>
            <div className="key">
              <Field name="key" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="top_description">{t('issue.topDescription')}</label>
            <div className="description">
              <Field name="top_description" component="textarea" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="catalog_description">{t('issue.catalogDescription')}</label>
            <div className="description">
              <Field name="catalog_description" component="textarea" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="seo_description">{t('issue.seoDescription')}</label>
            <div className="description">
              <Field name="seo_description" component="textarea" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="issues">{t('issue.issues')}</label>
            {form.issues != null && form.issues.map((e, i) => (
              <div className={`issues ${form.issues.length === i + 1 ? 'last' : ''}`} key={`${form.name}issues${i}`}>
                <Field
                  name={`issues[${i}]`}
                  component="textarea"
                />
                <div className="remove" onClick={onIssuesRemoveClick}>
                  <i className="fa fa-trash-o" data-key={i} />
                </div>
              </div>
            ))}
            <div className="justify-center w-90">
              <button type="button" className="add" onClick={onIssuesAddClick}>
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>

          <div className="item">
            <label htmlFor="summaries">{t('issue.summaries')}</label>
            {form.summaries != null && form.summaries.map((e, i) => (
              <div className={`summaries ${form.summaries.length === i + 1 ? 'last' : ''}`} key={`${form.name}summaries${i}`}>
                <Field
                  name={`summaries[${i}]`}
                  component="textarea"
                />
                <div className="remove" onClick={onSummariesRemoveClick}>
                  <i className="fa fa-trash-o" data-key={i} />
                </div>
              </div>
            ))}
            <div className="justify-center w-90">
              <button type="button" className="add" onClick={onSummariesAddClick}>
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>

          <div className="item">
            <label htmlFor="featureFunctions">{t('issue.featureFunctions')}</label>
            {form.feature_functions != null && form.feature_functions.map((e, i) => (
              <div className={`featureFunctions ${form.feature_functions.length === i + 1 ? 'last' : ''}`} key={`${form.name}feature_functions${i}`}>
                <div className="featureFunctionsInner">
                  <div className="featureFunctionsContentsWrapper featureFunctionsContentsName">
                    <span>{t('common.featureFunctionsItems.name')}</span>
                    <textarea
                      name={`featureFunctions${i}name`}
                      data-key={i}
                      data-name="name"
                      onChange={onFeatureFunctionsChange}
                      defaultValue={e.name}
                    />
                  </div>
                  <div className="featureFunctionsContentsWrapper featureFunctionsContentsEyecatchType">
                    <span>{t('common.featureFunctionsItems.eyecatchType')}</span>
                    <div className="select-wrap">
                      <select
                        name={`featureFunctions${i}eyecatch_type`}
                        data-name="eyecatch_type"
                        data-key={i}
                        onChange={onFeatureFunctionsChange}
                        defaultValue={e.eyecatch_type}
                      >
                        {
                          Config.PRODUCT_FEATURE_FUNCTIONS_EYECATCH_TYPE
                            .map(et => <option value={et.id} key={et.id}>{et.name}</option>)
                        }
                      </select>
                    </div>
                  </div>
                  <div className="featureFunctionsContentsWrapper featureFunctionsContentsImageURL">
                    <span>{t('common.featureFunctionsItems.imageURL')}</span>
                    <textarea
                      name={`featureFunctions${i}image_url`}
                      data-key={i}
                      data-name="image_url"
                      onChange={onFeatureFunctionsChange}
                      defaultValue={e.image_url}
                    />
                  </div>
                  <div className="featureFunctionsContentsWrapper featureFunctionsContentsImagePreview">
                    <span>ImagePreview</span>
                    <img
                      id={`featureFunctions${i}ImagePreview`}
                      src={e.image_url || '/assets/images/no_image.png'}
                      alt="catalogImageURL"
                    />
                  </div>
                  <div className="featureFunctionsContentsWrapper featureFunctionsContentsYoutubeVideoID">
                    <span>{t('common.featureFunctionsItems.youtubeVideoID')}</span>
                    <textarea
                      name={`featureFunctions${i}youtube_video_id`}
                      data-key={i}
                      data-name="youtube_video_id"
                      onChange={onFeatureFunctionsChange}
                      defaultValue={e.youtube_video_id}
                    />
                  </div>
                  <div className="featureFunctionsContentsWrapper featureFunctionsContentsYoutubePreview">
                    <span>YoutubePreview</span>
                    <iframe
                      id={`featureFunctions${i}YoutubePreview`}
                      title={`featureFunctions${i}YoutubePreview`}
                      src={`https://www.youtube.com/embed/${e.youtube_video_id}`}
                      frameBorder="0"
                    />
                  </div>
                  <div className="featureFunctionsContentsWrapper featureFunctionsContentsExample">
                    <span>{t('common.featureFunctionsItems.example')}</span>
                    <textarea
                      name={`featureFunctions${i}example`}
                      data-key={i}
                      data-name="example"
                      onChange={onFeatureFunctionsChange}
                      defaultValue={e.example}
                    />
                  </div>
                  <div className="featureFunctionsContentsWrapper last featureFunctionsContentsEquipment">
                    <span>{t('common.featureFunctionsItems.equipment')}</span>
                    <textarea
                      name={`featureFunctions${i}equipment`}
                      data-key={i}
                      data-name="equipment"
                      onChange={onFeatureFunctionsChange}
                      defaultValue={e.equipment}
                    />
                  </div>
                </div>
                <div className="remove" onClick={onFeatureFunctionsRemoveClick}>
                  <i className="fa fa-trash-o" data-key={i} />
                </div>
              </div>
            ))}
            <div className="justify-center w-90">
              <button type="button" className="add" onClick={onFeatureFunctionsAddClick}>
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>

          <div className="item">
            <label htmlFor="detailYoutubeVideoID">{t('issue.detailYoutubeVideoID')}</label>
            <div className="showDetailYoutubeVideo">
              <Field name="show_detail_youtube_video" id="showDetailYoutubeVideo" component="input" type="checkbox" />
              <label className="checkbox" htmlFor="showDetailYoutubeVideo">{t('issue.showDetailYoutubeVideo')}</label>
            </div>
            <iframe
              id="detailYoutubeVideoID"
              title="detailYoutubeVideo"
              src={`https://www.youtube.com/embed/${initialValues.detail_youtube_video_id}`}
            />
            <div className="detailYoutubeVideoID">
              <Field name="detail_youtube_video_id" component="input" type="text" onChange={onDetailYoutubeVideoIDChange} />
            </div>
          </div>

          <div className="item">
            <label htmlFor="catalogImageURL">{t('issue.catalogImageURL')}</label>
            <img src={initialValues.catalog_image_url || '/assets/images/no_image.png'} alt="catalogImageURL" />
            <div className="catalogImageURL">
              <Field name="catalog_image_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="mobileHeaderImageURL">{t('issue.mobileHeaderImageURL')}</label>
            <img src={initialValues.mobile_header_image_url || '/assets/images/no_image.png'} alt="mobileHeaderImageURL" />
            <div className="mobileHeaderImageURL">
              <Field name="mobile_header_image_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="pcHeaderImageURL">{t('issue.pcHeaderImageURL')}</label>
            <img src={initialValues.pc_header_image_url || '/assets/images/no_image.png'} alt="pcHeaderImageURL" />
            <div className="pcHeaderImageURL">
              <Field name="pc_header_image_url" component="input" type="text" />
            </div>
          </div>

          <div className="item narrow">
            <label htmlFor="sortKey">{t('issue.sortKey')}</label>
            <div className="sortKey">
              <Field name="sort_key" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="createdAt">{t('common.createdAt')}</label>
            <div className="createdAt">
              {timeFormat(issue.created_at)}
            </div>
          </div>

          <div className="item">
            <label htmlFor="createdAt">{t('common.createUserName')}</label>
            <div className="createdAt">
              {issue.create_user_name}
            </div>
          </div>

          <div className="item">
            <label htmlFor="updateAt">{t('common.updatedAt')}</label>
            <div className="updatedAt">
              {timeFormat(issue.updated_at)}
            </div>
          </div>

          <div className="item">
            <label htmlFor="updateAt">{t('common.updateUserName')}</label>
            <div className="updatedAt">
              {issue.update_user_name}
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default IssueEditForm;
