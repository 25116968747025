import {
  FETCH_PRODUCT_OPTION_START,
  FETCH_PRODUCT_OPTION_COMPLETE,
  CREATE_PRODUCT_OPTION_START,
  CREATE_PRODUCT_OPTION_COMPLETE,
  UPDATE_PRODUCT_OPTION_START,
  UPDATE_PRODUCT_OPTION_COMPLETE,
} from '../actions/productOptionAction';

const productOptionReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_OPTION_START:
      return state;

    case FETCH_PRODUCT_OPTION_COMPLETE:
      return action.payload;

    case CREATE_PRODUCT_OPTION_START:
      return state;

    case CREATE_PRODUCT_OPTION_COMPLETE:
      return action.payload;

    case UPDATE_PRODUCT_OPTION_START:
      return state;

    case UPDATE_PRODUCT_OPTION_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default productOptionReducer;
