import React from 'react';
import { Field } from 'redux-form';
import Autosuggest from 'react-autosuggest';

import { timeFormat } from '../../util/util';

const renderChildTag = (tag, i, props) => {
  const {
    onChildTagUpClick,
    onChildTagDownClick,
    onChildTagRemoveClick,
  } = props;

  return (
    <div className="child" key={`childTag-${i}`}>
      <div className="left">{tag.name}</div>

      <div className="buttons right">
        <div className="button" data-key={i} onClick={onChildTagUpClick}>
          <i className="fa fa-arrow-up" data-key={i} />
        </div>
        <div className="button" data-key={i} onClick={onChildTagDownClick}>
          <i className="fa fa-arrow-down" data-key={i} />
        </div>
        <div className="button" data-key={i} onClick={onChildTagRemoveClick}>
          <i className="fa fa-trash-o" data-key={i} />
        </div>
      </div>

      <div className="clear" />
    </div>
  );
};

const TagEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    form,
    tag,
    childTags,
    suggestTags,
    onTagChange,
    onParentTagSuggestionSelected,
    onChildTagSuggestionSelected,
    parentTagInputProps,
    childTagInputProps,
    onChildTagAddClick,
    t,
  } = props;

  const suggestTagsOptimized = (suggestTags.tags || []).filter((suggestTag) => {
    if (suggestTag.tag_id !== tag.tag_id && suggestTag.tag_id !== 1) {
      return true;
    }
    return false;
  });
  const suggestTagsNameOptimized = suggestTagsOptimized.map(e => e.name);

  return (
    <div className="tagEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="id">ID</label>
            <div className="id">
              {tag.tag_id}
            </div>
          </div>

          <div className="item">
            <label htmlFor="name">{t('tag.name')}</label>
            <div className="name">
              <Field name="name" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="description">{t('tag.description')}</label>
            <div className="description">
              <Field name="description" component="textarea" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="parent">{t('tag.parentID')}</label>
            <div className="parent">
              <Autosuggest
                suggestions={suggestTagsNameOptimized}
                shouldRenderSuggestions={v => v != null}
                getSuggestionValue={suggestion => suggestion}
                renderSuggestion={suggestion => <span>{suggestion}</span>}
                inputProps={parentTagInputProps}
                onSuggestionSelected={onParentTagSuggestionSelected}
                onSuggestionsFetchRequested={onTagChange}
                onSuggestionsClearRequested={() => {}}
              />
            </div>
          </div>

          <div className="item">
            <label htmlFor="child">{t('tag.childIDs')}</label>
            <div className="children">
              {form.child_ids != null && childTags != null ?
                form.child_ids.map((id, i) => {
                  const childTag = childTags.find(e => e.tag_id === id);

                  if (childTag == null) {
                    return null;
                  }

                  return renderChildTag(childTag, i, props);
                }) : null}
              <div className="childrenAdd">
                <Autosuggest
                  suggestions={suggestTagsNameOptimized}
                  shouldRenderSuggestions={v => v != null}
                  getSuggestionValue={suggestion => suggestion}
                  renderSuggestion={suggestion => <span>{suggestion}</span>}
                  inputProps={childTagInputProps}
                  onSuggestionSelected={onChildTagSuggestionSelected}
                  onSuggestionsFetchRequested={onTagChange}
                  onSuggestionsClearRequested={() => {}}
                />

                <button type="button" onClick={onChildTagAddClick}>
                  Add
                </button>
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="createdAt">{t('common.createdAt')}</label>
            <div className="createdAt">
              {timeFormat(tag.created_at)}
            </div>
          </div>

          <div className="item">
            <label htmlFor="updateAt">{t('common.updatedAt')}</label>
            <div className="updatedAt">
              {timeFormat(tag.updated_at)}
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default TagEditForm;
