import React from 'react';
import classnames from 'classnames';

const renderRootTag = (props) => {
  const { onClick } = props;

  return (
    <li className="item" key="tag-o">
      <div className="list-content" onClick={onClick}>
        <div className="description">
          <div>
            <span className="name">(root)</span>
          </div>
        </div>
      </div>
    </li>
  );
};

const renderTag = (tag, i, props) => {
  if (tag == null) {
    return renderRootTag(props);
  }

  const {
    id,
    onClick,
  } = props;

  const indent = { item: true, selected: Number(id) === tag.tag_id };
  indent[`indent${i}`] = true;

  return (
    <li
      className={classnames(indent)}
      key={`tag-${tag.tag_id}`}
    >
      <div className="list-content" onClick={() => onClick(tag.tag_id)}>
        <div className="description">
          <div>
            <span className="name">
              {tag.name}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

const parentTags = (tags) => {
  let ret = [];

  if (tags.parent != null && tags.parent.id > 1) {
    ret = ret.concat(parentTags(tags.parent));
  }

  if (tags.tag_id > 1) {
    ret = ret.concat(tags);
  }

  return ret;
};

const TagList = (props) => {
  const { tags, onAddClick, id } = props;

  if (tags == null || (tags.tag_id != null && tags.tag_id !== Number(id || 1))) {
    return null;
  }

  const renderingTags = parentTags(tags);

  return (
    <div className="tagList">
      <ul>
        {renderRootTag(props)}
        {renderingTags.map((tag, i) => renderTag(tag, i, props))}
        {tags.children != null ?
          tags.children.map(tag => renderTag(tag, renderingTags.length, props)) : null}

        <li className={`item indent${renderingTags.length}`}>
          <div className="button" onClick={onAddClick}>
            <i className="fa fa-plus" aria-hidden="true" />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default TagList;
