import React from 'react';

const NotFound = props => (
  <div className="notFound">
    <h1>Not Found</h1>
    <p>
      {props.location.pathname}
    </p>
  </div>
);

export default NotFound;
