import {
  FETCH_FIXED_TAGS_START,
  FETCH_FIXED_TAGS_COMPLETE,
} from '../actions/tagAction';

const suggestTagsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FIXED_TAGS_START:
      return state;

    case FETCH_FIXED_TAGS_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default suggestTagsReducer;
