import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import articleReducer from './articleReducer';
import articlesReducer from './articlesReducer';
import articleHistoriesReducer from './articleHistoriesReducer';
import imageReducer from './imageReducer';
import imagesReducer from './imagesReducer';
import imageTagsReducer from './imageTagsReducer';
import imageSourcesReducer from './imageSourcesReducer';
import authorReducer from './authorReducer';
import authorsReducer from './authorsReducer';
import categoryReducer from './categoryReducer';
import categoriesReducer from './categoriesReducer';
import tagReducer from './tagReducer';
import tagsReducer from './tagsReducer';
import suggestTagsReducer from './suggestTagsReducer';
import issueReducer from './issueReducer';
import issuesReducer from './issuesReducer';
import productSetReducer from './productSetReducer';
import productSetsReducer from './productSetsReducer';
import productOptionReducer from './productOptionReducer';
import productOptionsReducer from './productOptionsReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import permissionsReducer from './permissionsReducer';
import rolesReducer from './rolesReducer';
import alertsReducer from './alertsReducer';
import loadingReducer from './loadingReducer';
import sessionReducer from './sessionReducer';
import editExclusionsReducer from './editExclusionsReducer';

export default combineReducers({
  article: articleReducer,
  articles: articlesReducer,
  articleHistories: articleHistoriesReducer,
  image: imageReducer,
  images: imagesReducer,
  imageTags: imageTagsReducer,
  imageSources: imageSourcesReducer,
  author: authorReducer,
  authors: authorsReducer,
  category: categoryReducer,
  categories: categoriesReducer,
  tag: tagReducer,
  tags: tagsReducer,
  suggestTags: suggestTagsReducer,
  issue: issueReducer,
  issues: issuesReducer,
  productSet: productSetReducer,
  productSets: productSetsReducer,
  productOption: productOptionReducer,
  productOptions: productOptionsReducer,
  user: userReducer,
  users: usersReducer,
  permissions: permissionsReducer,
  roles: rolesReducer,
  alerts: alertsReducer,
  loading: loadingReducer,
  session: sessionReducer,
  editExclusions: editExclusionsReducer,
  form: formReducer,
});

