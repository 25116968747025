import {
  createEditExclusion as createEditExclusionAPI,
  deleteEditExclusion as deleteEditExclusionAPI,
} from '../api/apiClient';

export const CREATE_EDIT_EXCLUSION_START = 'CREATE_EDIT_EXCLUSION_START';
export const CREATE_EDIT_EXCLUSION_COMPLETE = 'CREATE_EDIT_EXCLUSION_COMPLETE';
export const DELETE_EDIT_EXCLUSION_START = 'DELETE_EDIT_EXCLUSION_START';
export const DELETE_EDIT_EXCLUSION_COMPLETE = 'DELETE_EDIT_EXCLUSION_COMPLETE';

const createEditExclusionStart = () => ({ type: CREATE_EDIT_EXCLUSION_START });

const deleteEditExclusionStart = () => ({ type: DELETE_EDIT_EXCLUSION_START });

const createEditExclusionComplete = res => ({
  type: CREATE_EDIT_EXCLUSION_COMPLETE,
  payload: res,
});

const deleteEditExclusionComplete = res => ({
  type: DELETE_EDIT_EXCLUSION_COMPLETE,
  payload: res,
});

export const createEditExclusion = (tabID, articleID, revision) => (
  (dispatch) => {
    dispatch(createEditExclusionStart());

    const form = {
      tab_id: tabID,
      article_id: articleID,
      revision,
    };

    createEditExclusionAPI(form).then((res) => {
      const editExclusions = res.body;

      dispatch(createEditExclusionComplete(editExclusions));
    }).catch((res) => {
      console.error('response: %o', res);
    });
  }
);

export const deleteEditExclusion = tabID => (
  (dispatch) => {
    dispatch(deleteEditExclusionStart());

    const form = {
      tab_id: tabID,
    };

    deleteEditExclusionAPI(form).then((res) => {
      const editExclusions = res.body;

      dispatch(deleteEditExclusionComplete(editExclusions));
    }).catch((res) => {
      console.error('response: %o', res);
    });
  }
);
