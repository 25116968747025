import React from 'react';
import classnames from 'classnames';

const renderCategory = (category, props) => {
  const {
    id,
    onClick,
  } = props;

  return (
    <li
      className={classnames({ item: true, selected: Number(id) === category.category_id })}
      key={`category-${category.category_id}`}
    >
      <div className="list-content" onClick={() => onClick(category.category_id)}>
        <img
          src={category.mobile_header_image_url != null &&
            category.mobile_header_image_url.length > 0 ?
            category.mobile_header_image_url : '/assets/images/no_image_small.png'}
          alt="mobileHeaderImageURL"
        />

        <div className="description">
          <div>
            <span className="name">
              {`${category.name} (${category.key})`}
            </span>
            <span className="seoName">
              {category.seo_name}
            </span>
          </div>
          <div>
            <span className="description">
              {category.description}
            </span>
          </div>
        </div>

      </div>
    </li>
  );
};

const CategoryList = (props) => {
  const { categories } = props;

  if (categories == null) {
    return null;
  }

  return (
    <div className="categoryList">
      <ul>
        {categories.map(category => renderCategory(category, props))}
      </ul>
    </div>
  );
};

export default CategoryList;
