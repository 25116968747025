import React from 'react';
import classnames from 'classnames';
import Config from '../../config/Config';
import { getName } from '../../util/util';


const renderProductOption = (productOption, props) => {
  const {
    id,
    onClick,
  } = props;

  const classNames = { status: true };
  classNames[productOption.status] = true;

  return (
    <li
      className={classnames({
        item: true,
        selected: Number(id) === productOption.product_option_id,
      })}
      key={`productOption-${productOption.product_option_id}`}
    >
      <div className="list-content" onClick={() => onClick(productOption.product_option_id)}>
        <img
          src={productOption.catalog_image_url != null &&
            productOption.catalog_image_url.length > 0 ?
            productOption.catalog_image_url : '/assets/images/no_image_small.png'}
          alt="catalogImageURL"
        />

        <div className="description">
          <div>
            <span className={classnames(classNames)}>
              {getName(Config.PRODUCT_STATUSES, productOption.status)}
            </span>
            <span className="name">
              {`${productOption.name} (${productOption.key})`}
            </span>
          </div>
          <div className="description">
            {productOption.purposes != null && productOption.purposes.map((e, i) => (
              <span className="purposes" key={`productOptionPurpose${i}`}>
                {getName(Config.PRODUCT_OPTION_PURPOSE_TYPES, e)}
              </span>
            ))}
          </div>
          <div className="description">
            <span>
              {productOption.seo_description}
            </span>
          </div>
        </div>

      </div>
    </li>
  );
};

const ProductOptionList = (props) => {
  const { productOptions } = props;

  if (productOptions == null) {
    return null;
  }

  return (
    <div className="productOptionList">
      <ul>
        {productOptions.map(productOption => renderProductOption(productOption, props))}
      </ul>
    </div>
  );
};

export default ProductOptionList;
