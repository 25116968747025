import {
  FETCH_ISSUES_WITH_CONDITION_START,
  FETCH_ISSUES_WITH_CONDITION_COMPLETE,
} from '../actions/issueAction';

const issuesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ISSUES_WITH_CONDITION_START:
      return state;

    case FETCH_ISSUES_WITH_CONDITION_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default issuesReducer;
