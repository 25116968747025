import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { translate } from 'react-i18next';

import IssueList from '../../components/issue/IssueList';
import IssueEditContainer from './IssueEditContainer';
import { fetchIssuesWithCondition, createIssue } from '../../actions/issueAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';

class IssueContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleIssueClick = this.handleIssueClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchIssuesWithCondition();
  }

  handleNewClick() {
    this.props.router.push('/issue');
    this.props.addLoading();
    this.props.createIssue({ name: 'no name' }, (issue) => {
      this.props.fetchIssuesWithCondition();
      this.props.router.push(`/issue/${issue.issue_id}`);
      this.props.addSuccessAlert(this.props.t('messages.createIssueSuccess'));
    });
  }

  handleIssueClick(id) {
    this.props.router.push(`/issue/${id}`);
  }

  render() {
    const { issues, params, t } = this.props;

    return (
      <div className="issueContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="header">
            <div className="buttons right">
              <button
                type="button"
                onClick={this.handleNewClick}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Create Issue
              </button>
            </div>

            <div className="clear" />
          </div>

          <div
            className="content"
            ref={(ref) => { this.issueListContent = ref; }}
          >
            <IssueList
              id={params.id}
              issues={issues.issues}
              onClick={this.handleIssueClick}
              t={t}
            />
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <IssueEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    issues: state.issues,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchIssuesWithCondition:
      (form, issues, offset) => dispatch(fetchIssuesWithCondition(form, issues, offset)),
    createIssue: (form, callback) => dispatch(createIssue(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const issueContainer = withRouter(translate()(IssueContainer));
export default connect(mapStateToProps, mapDispatchToProps)(issueContainer);
