import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import { translate } from 'react-i18next';

import ArticleList from '../../components/article/ArticleList';
import ArticleDetail from '../../components/article/ArticleDetail';
import ArticleSearchForm from '../../components/article/ArticleSearchForm';
import { fetchArticle, fetchArticlesWithCondition, createArticle } from '../../actions/articleAction';
import { fetchAuthors } from '../../actions/authorAction';
import { fetchCategories } from '../../actions/categoryAction';
import { addLoading } from '../../actions/loadingAction';
import Config from '../../config/Config';

class ArticleContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleArticleClick = this.handleArticleClick.bind(this);

    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchArticlesWithCondition();
    this.props.fetchAuthors();
    this.props.fetchCategories();

    if (this.props.params.id != null) {
      this.props.fetchArticle(this.props.params.id);
    }
  }

  componentDidUpdate() {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }

    if (window.twttr) {
      window.twttr.widgets.load();
    }
  }

  handleScroll() {
    const { articles, articleSearchForm } = this.props;
    const articleListBody = this.articleListContent;
    const elementHeight = Math.max(
      articleListBody.offsetHeight,
      articleListBody.clientHeight,
      articleListBody.offsetHeight,
    );
    if (
      articles.articles != null &&
      articles.articles.length > 0 &&
      articleListBody.scrollTop + elementHeight >= articleListBody.scrollHeight
    ) {
      this.props.fetchArticlesWithCondition(
        articleSearchForm != null ? articleSearchForm.values : null,
        articles.articles,
        articles.offset,
      );
    }
  }

  handleSearchSubmit() {
    this.props.fetchArticlesWithCondition(this.props.articleSearchForm.values);

    this.articleListContent.scrollTop = 0;

    this.props.router.replace('/article');
  }

  handleNewClick() {
    const form = {
      titles: ['no title'],
      author_id: Config.NAMESPACE === '' ? 1 : 1,
      category_id: Config.NAMESPACE === '' ? 1 : 1,
      content_type: 'news',
      brand: 'corporate',
    };

    this.props.addLoading();
    this.props.createArticle(form);
  }

  handleArticleClick(id) {
    this.props.fetchArticle(id);
    this.props.router.push(`/article/${id}`);
  }

  handlePreviewClick(e) {
    const { article } = this.props;

    if (article.article_id != null) {
      if (article.status === 'posted') {
        if (article.brand === 'stories') {
          window.open(`${Config.WEB_ENDPOINT}/company/stories/${article.article_id}/${article.custom_url}`, '_blank');
        } else if (article.brand === 'shinkai') {
          window.open(`${Config.WEB_ENDPOINT}/shinkai/${article.article_id}/${article.custom_url}`, '_blank');
        } else {
          window.open(`${Config.WEB_ENDPOINT}/news/${article.article_id}/${article.custom_url}`, '_blank');
        }
      } else if (article.brand === 'stories') {
        window.open(`${Config.WEB_ENDPOINT}/company/stories/preview/${article.article_id}/${article.preview_key}`, '_blank');
      } else if (article.brand === 'shinkai') {
        window.open(`${Config.WEB_ENDPOINT}/shinkai/preview/${article.article_id}/${article.preview_key}`, '_blank');
      } else {
        window.open(`${Config.WEB_ENDPOINT}/news/preview/${article.article_id}/${article.preview_key}`, '_blank');
      }
    }
    e.stopPropagation();
  }

  handleDownloadClick(e) {
    if (this.props.article.article_id != null) {
      window.open(`${Config.CMS_ENDPOINT}/article/${this.props.article.article_id}/docx`, '_blank');
    }
    e.stopPropagation();
  }

  handleEditClick(e) {
    if (this.props.article.article_id != null) {
      window.open(`/article/${this.props.article.article_id}/edit`, '_blank');
    }
    e.stopPropagation();
  }

  render() {
    const { articles, article, authors, categories, permissions, params, t } = this.props;

    return (
      <div className="articleContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="header">
            <div className="buttons right">

              <button
                type="button"
                onClick={this.handleNewClick}
                data-for="new"
                data-tip={t('common.new')}
              >
                <i className="fa fa-pencil-square-o" aria-hidden="true" />
              </button>
              <ReactTooltip
                id="new"
                class="tooltip"
                place="right"
                effect="solid"
                multiline
              />
            </div>

            <ArticleSearchForm
              permissions={permissions}
              onSubmit={this.handleSearchSubmit}
              t={t}
            />
          </div>

          <div
            className="content"
            ref={(ref) => { this.articleListContent = ref; }}
            onScroll={this.handleScroll}
          >
            <ArticleList
              id={params.id}
              articles={articles.articles}
              authors={authors.authors}
              permissions={permissions}
              onClick={this.handleArticleClick}
              onPreviewClick={this.handlePreviewClick}
              onDownloadClick={this.handleDownloadClick}
              onEditClick={this.handleEditClick}
            />
          </div>

        </div>

        <div className={classnames({ detail: true, none: params.id == null })}>
          <ArticleDetail
            id={params.id}
            article={article}
            authors={authors.authors}
            categories={categories.categories}
            t={t}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    articles: state.articles,
    article: state.article,
    authors: state.authors,
    categories: state.categories,
    articleSearchForm: state.form.articleSearchForm,
    loading: state.loading,
    permissions: state.permissions,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchArticle: id => dispatch(fetchArticle(id)),
    fetchArticlesWithCondition:
      (form, articles, offset) => dispatch(fetchArticlesWithCondition(form, articles, offset)),
    fetchAuthors: () => dispatch(fetchAuthors()),
    fetchCategories: () => dispatch(fetchCategories()),
    createArticle: form => dispatch(createArticle(form)),
    addLoading: () => dispatch(addLoading()),
  }
);

const articleContainer = withRouter(translate()(ArticleContainer));
export default connect(mapStateToProps, mapDispatchToProps)(articleContainer);
