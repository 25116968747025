import React from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { translate } from 'react-i18next';

import ImageUploadPopup from '../../components/image/ImageUploadPopup';
import { fetchImageTags, fetchImageSources, createImage } from '../../actions/imageAction';
import { addLoading } from '../../actions/loadingAction';
import { addErrorAlert } from '../../actions/alertAction';

class ImageUploadPopupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleTagsRenderInput = this.handleTagsRenderInput.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleSourceSuggestionSelected = this.handleSourceSuggestionSelected.bind(this);
    this.refTagsInput = this.refTagsInput.bind(this);
    this.refImageType = this.refImageType.bind(this);
    this.refSource = this.refSource.bind(this);
    this.refCreditName = this.refCreditName.bind(this);
    this.refCreditURL = this.refCreditURL.bind(this);

    this.state = {
      prevTagsValue: '',
      uploadFiles: [],
      uploadFileBodies: [],
      tags: ['CMS'],
      source: 'FullDepth',
    };
  }

  handleSubmit() {
    if (this.state.uploadFileBodies.length === 0) {
      this.props.addErrorAlert(this.props.t('messages.createImageValidateError'));
      return;
    }

    this.props.addLoading();

    const images = [];
    this.state.uploadFileBodies.forEach((e, i) => {
      let tmp = e.split(';base64,');

      const image = document.getElementById(`uploadImage_${i}`);
      if (image != null && image.naturalWidth > 1200) {
        const data = this.convertImageToDataURL(image);
        tmp = data.split(';base64,');
      }

      images.push({
        body: tmp[1],
        format: tmp[0].substring(5),
        tags: this.tagsInput.props.value,
        image_type: this.imageType.value,
        source: this.source.props.inputProps.value,
        credit: JSON.stringify({ name: this.creditName.value, url: this.creditURL.value }),
      });
    });

    const callCreateImage = () => {
      const target = images.splice(0, 1);
      if (target.length === 0) return;

      this.props.addLoading();
      this.props.createImage(
        target[0],
        (images.length === 0 ? this.props.onSubmit : callCreateImage),
        this.props.inputType,
      );
    };

    callCreateImage();
  }

  convertImageToDataURL(image) {
    const width = image.naturalWidth;
    const height = image.naturalHeight;

    const canvas = document.getElementById('uploadImageCanvas');
    canvas.width = 1200;
    canvas.height = (height * 1200) / width;
    canvas.getContext('2d').drawImage(image, 0, 0, 1200, (height * 1200) / width);

    return canvas.toDataURL('image/jpeg');
  }

  handleFileDrop(e) {
    e.preventDefault();

    const uploadFiles = this.state.uploadFiles;

    Object.keys(e.dataTransfer.files).forEach((key) => {
      const f = e.dataTransfer.files[key];

      if (f.type.match('image.*')) {
        if (this.state.uploadFiles.find(file => file.name === f.name) == null) {
          uploadFiles.push(f);

          const reader = new FileReader();
          reader.onload = (ev) => {
            this.state.uploadFileBodies.push(ev.target.result);
            this.forceUpdate();
          };
          reader.readAsDataURL(f);
        }
      }
    });

    this.setState({ uploadFiles });
  }

  handleFileRemove() {
    this.setState({ uploadFiles: [] });
    this.setState({ uploadFileBodies: [] });
  }

  handleTagsChange(tags) {
    this.setState({ tags });
  }

  handleTagsRenderInput({ addTag, value, ref, onChange }) {
    const handleChange = (e, { newValue }) => {
      const tags = this.state.tags || [];

      if (newValue.indexOf(',') >= 0 || newValue.indexOf('  ') >= 0) {
        const tag = newValue.replace(/,/g, '').trim();

        if (tag.length > 0 && !tags.includes(tag)) {
          this.handleTagsChange(tags.concat([tag]));
        }

        this.tagsInput.setState({ tag: '' });
      } else {
        onChange(e);
      }
    };

    const handleKeyUp = (e) => {
      const tags = this.state.tags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Backspace') {
        if (this.state.prevTagsValue === '') {
          this.handleTagsChange(tags.slice(0, tags.length - 1));
        }
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleKeyPress = (e) => {
      const tags = this.state.tags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Enter') {
        if (newValue.length > 0 && !tags.includes(newValue)) {
          this.handleTagsChange(tags.concat([newValue]));
        }

        this.tagsInput.setState({ tag: '' });
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleBlur = () => {
      this.tagsInput.setState({ tag: '' });
    };

    return (
      <Autosuggest
        ref={ref}
        suggestions={this.props.suggestTags.tags || []}
        shouldRenderSuggestions={v => v != null && v.trim().length > 0}
        getSuggestionValue={suggestion => suggestion}
        renderSuggestion={suggestion => <span>{suggestion}</span>}
        inputProps={{
          value,
          placeholder: this.props.t('image.tagsPlaceholder'),
          onChange: handleChange,
          onKeyUp: handleKeyUp,
          onKeyPress: handleKeyPress,
          onBlur: handleBlur,
        }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        onSuggestionsFetchRequested={(e) => {
          this.props.fetchImageTags(e.value);
        }}
        onSuggestionsClearRequested={() => {}}
      />
    );
  }

  handleSourceChange(e) {
    this.props.fetchImageSources(e.value);
    this.setState({ source: e.value });
  }

  handleSourceSuggestionSelected(e, { suggestion }) {
    this.setState({ source: suggestion });
  }

  refTagsInput(ref) {
    this.tagsInput = ref;
  }

  refImageType(ref) {
    this.imageType = ref;
  }

  refSource(ref) {
    this.source = ref;
  }

  refCreditName(ref) {
    this.creditName = ref;
  }

  refCreditURL(ref) {
    this.creditURL = ref;
  }

  render() {
    const { onCancel, suggestSources, t } = this.props;

    return (
      <div className="imageUploadPopupContainer">
        <ImageUploadPopup
          onSubmit={this.handleSubmit}
          onCancel={onCancel}
          uploadFiles={this.state.uploadFiles}
          uploadFileBodies={this.state.uploadFileBodies}
          onFileDrop={this.handleFileDrop}
          onFileRemove={this.handleFileRemove}
          onTagsChange={this.handleTagsChange}
          onTagsRenderInput={this.handleTagsRenderInput}
          refTagsInput={this.refTagsInput}
          refImageType={this.refImageType}
          refSource={this.refSource}
          refCreditName={this.refCreditName}
          refCreditURL={this.refCreditURL}
          suggestSources={suggestSources}
          onSourceChange={this.handleSourceChange}
          onSourceSuggestionSelected={this.handleSourceSuggestionSelected}
          sourceInputProps={
            {
              name: 'source',
              value: this.state.source,
              onChange: e => this.setState({ source: e.target.value }),
            }
          }
          tags={this.state.tags}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    suggestTags: state.imageTags,
    suggestSources: state.imageSources,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchImageTags: value => dispatch(fetchImageTags(value)),
    fetchImageSources: value => dispatch(fetchImageSources(value)),
    createImage: (form, callback, inputType) => dispatch(createImage(form, callback, inputType)),
    addLoading: () => dispatch(addLoading()),
    addErrorAlert: value => dispatch(addErrorAlert(value)),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ImageUploadPopupContainer));
