import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import combineReducers from '../reducers/combineReducers';

const ConfigureStore = (initialState) => {
  const middlewares = [thunkMiddleware];
  const store = createStore(
    combineReducers,
    initialState,
    applyMiddleware(...middlewares),
  );

  return store;
};

export default ConfigureStore;
