import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchProductSetsWithCondition as fetchProductSetsWithConditionAPI,
  fetchProductSet as fetchProductSetAPI,
  createProductSet as createProductSetAPI,
  updateProductSet as updateProductSetAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_PRODUCT_SETS_WITH_CONDITION_START = 'FETCH_PRODUCT_SETS_WITH_CONDITION_START';
export const FETCH_PRODUCT_SETS_WITH_CONDITION_COMPLETE = 'FETCH_PRODUCT_SETS_WITH_CONDITION_COMPLETE';
export const FETCH_PRODUCT_SET_START = 'FETCH_PRODUCT_SET_START';
export const FETCH_PRODUCT_SET_COMPLETE = 'FETCH_PRODUCT_SET_COMPLETE';
export const CREATE_PRODUCT_SET_START = 'CREATE_PRODUCT_SET_START';
export const CREATE_PRODUCT_SET_COMPLETE = 'CREATE_PRODUCT_SET_COMPLETE';
export const UPDATE_PRODUCT_SET_START = 'UPDATE_PRODUCT_SET_START';
export const UPDATE_PRODUCT_SET_COMPLETE = 'UPDATE_PRODUCT_SET_COMPLETE';


const fetchProductSetsWithConditionStart = () => (
  { type: FETCH_PRODUCT_SETS_WITH_CONDITION_START }
);

const fetchProductSetsWithConditionComplete = (preProductSets, res) => {
  res.product_sets = preProductSets.concat(res.product_sets);

  return {
    type: FETCH_PRODUCT_SETS_WITH_CONDITION_COMPLETE,
    payload: res,
  };
};

const fetchProductSetStart = () => ({ type: FETCH_PRODUCT_SET_START });

const fetchProductSetComplete = res => ({
  type: FETCH_PRODUCT_SET_COMPLETE,
  payload: res,
});

const createProductSetStart = () => ({ type: CREATE_PRODUCT_SET_START });

const createProductSetComplete = res => ({
  type: CREATE_PRODUCT_SET_COMPLETE,
  payload: res,
});

const updateProductSetStart = () => ({ type: UPDATE_PRODUCT_SET_START });

const updateProductSetComplete = res => ({
  type: UPDATE_PRODUCT_SET_COMPLETE,
  payload: res,
});

export const fetchProductSetsWithCondition = (form = {}, preProductSets = [], offset = '', callback) => (
  (dispatch) => {
    dispatch(fetchProductSetsWithConditionStart());

    fetchProductSetsWithConditionAPI(form, offset).then((res) => {
      dispatch(fetchProductSetsWithConditionComplete(preProductSets, res.body));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchProductSetError', { message: res.text })));
    });
  }
);

export const fetchProductSet = id => (
  (dispatch) => {
    dispatch(fetchProductSetStart());

    fetchProductSetAPI(id).then((res) => {
      dispatch(fetchProductSetComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchProductSetError', { message: res.text })));
    });
  }
);

export const createProductSet = (form, callback) => (
  (dispatch) => {
    dispatch(createProductSetStart());

    createProductSetAPI(form).then((res) => {
      dispatch(createProductSetComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createProductSetSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createProductSetError', { message: res.text })));
    });
  }
);

export const updateProductSet = (form, callback) => (
  (dispatch) => {
    dispatch(updateProductSetStart());

    updateProductSetAPI(form).then((res) => {
      dispatch(updateProductSetComplete(res.body));
      dispatch(removeLoading());
      if (form.status === 'draft') {
        dispatch(addSuccessAlert(i18n.t('messages.draftProductSetSuccess')));
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.updateProductSetSuccess')));
      }
      if (callback != null) {
        callback();
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      if (form.status === 'draft') {
        dispatch(addErrorAlert(i18n.t('messages.draftProductSetError', { message: res.text })));
      } else {
        dispatch(addErrorAlert(i18n.t('messages.updateProductSetError', { message: res.text })));
      }
    });
  }
);
