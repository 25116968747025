import React from 'react';
import { Link } from 'react-router';
import TagsInput from 'react-tagsinput';

import { timeFormat, imageThumbnailURL } from '../../util/util';

const renderImage = (image, { onSubmit, inputType }) => (
  <div className="item" key={`searchPopupImage-${image.image_id}`}>
    <Link onClick={() => onSubmit(image, inputType)}>
      <img
        src={
          image.url != null && image.url.length > 0 ?
            imageThumbnailURL(image.url, 'small') : '/assets/images/no_image_small.png'
        }
        alt="imagethumbnail"
      />

      <div className="createUser">
        <i className="fa fa-user" />
        {image.user_name || '-'}
      </div>
      <div className="createdAt">
        <i className="fa fa-clock-o" />
        {timeFormat(image.created_at)}
      </div>
    </Link>
  </div>
);

const ImageSearchPopup = props => (
  <div className="imageSearchPopup">
    <div className="imageSearchPopup-inner">

      <div className="left">
        <div className="item">
          <label htmlFor="tags">{props.t('image.tags')}</label>
          <TagsInput
            ref={props.refTagsInput}
            renderInput={props.onTagsRenderInput}
            value={props.selectedTags || []}
            onChange={props.onTagsChange}
            onlyUnique
          />
        </div>
      </div>

      <div className="clear" />

      <div
        className="imageList"
        ref={props.refImageListContent}
        onScroll={props.onScroll}
      >
        {
          props.images.images != null ?
            props.images.images.map(image => renderImage(image, props)) : null
        }
      </div>

      <div className="footer">
        <div className="left">
          <button type="button" onClick={props.onCancel}>Cancel</button>
        </div>

        <div className="clear" />
      </div>
    </div>
  </div>
);

export default ImageSearchPopup;
