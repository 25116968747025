import React from 'react';
import { Field } from 'redux-form';

import { timeFormat } from '../../util/util';

const AuthorEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    form,
    author,
    t,
  } = props;


  return (
    <div className="authorEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="id">ID</label>
            <div className="id">
              {author.author_id}
            </div>
          </div>

          <div className="item">
            <label htmlFor="name">{t('author.name')}</label>
            <div className="name">
              <Field name="name" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="key">{t('author.key')}</label>
            <div className="key">
              <Field name="key" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="title">{t('author.title')}</label>
            <div className="title">
              <Field name="title" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="profile">{t('author.profile')}</label>
            <div className="profile">
              <Field name="profile" component="textarea" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="icon">{t('author.icon')}</label>
            <div className="icon">
              <Field name="icon" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="createdAt">{t('common.createdAt')}</label>
            <div className="createdAt">
              {timeFormat(author.created_at)}
            </div>
          </div>

          <div className="item">
            <label htmlFor="updateAt">{t('common.updatedAt')}</label>
            <div className="updatedAt">
              {timeFormat(author.updated_at)}
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default AuthorEditForm;
