import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { translate } from 'react-i18next';

import ProductOptionList from '../../components/productOption/ProductOptionList';
import ProductOptionEditContainer from './ProductOptionEditContainer';
import { fetchProductOptionsWithCondition, createProductOption } from '../../actions/productOptionAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';

class ProductOptionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleProductOptionClick = this.handleProductOptionClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchProductOptionsWithCondition();
  }

  handleNewClick() {
    this.props.router.push('/product_option');
    this.props.addLoading();
    const initialPurposes = ['performance'];
    const postForm = {
      name: 'no name',
      purposes: initialPurposes,
    };
    this.props.createProductOption(postForm, (productOption) => {
      this.props.fetchProductOptionsWithCondition();
      this.props.router.push(`/product_option/${productOption.product_option_id}`);
      this.props.addSuccessAlert(this.props.t('messages.createProductOptionSuccess'));
    });
  }

  handleProductOptionClick(id) {
    this.props.router.push(`/product_option/${id}`);
  }

  render() {
    const { productOptions, params, t } = this.props;

    return (
      <div className="productOptionContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="header">
            <div className="buttons right">
              <button
                type="button"
                onClick={this.handleNewClick}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Create ProductOption
              </button>
            </div>

            <div className="clear" />
          </div>

          <div
            className="content"
            ref={(ref) => { this.productOptionListContent = ref; }}
          >
            <ProductOptionList
              id={params.id}
              productOptions={productOptions.product_options}
              onClick={this.handleProductOptionClick}
              t={t}
            />
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <ProductOptionEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    productOptions: state.productOptions,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchProductOptionsWithCondition:
      (form, productOptions, offset) => (
        dispatch(fetchProductOptionsWithCondition(form, productOptions, offset))),
    createProductOption: (form, callback) => dispatch(createProductOption(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const productOptionContainer = withRouter(translate()(ProductOptionContainer));
export default connect(mapStateToProps, mapDispatchToProps)(productOptionContainer);
