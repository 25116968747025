import {
  FETCH_CATEGORIES_START,
  FETCH_CATEGORIES_COMPLETE,
} from '../actions/categoryAction';

const categoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_START:
      return state;

    case FETCH_CATEGORIES_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default categoriesReducer;
