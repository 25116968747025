import {
  FETCH_ISSUE_START,
  FETCH_ISSUE_COMPLETE,
  CREATE_ISSUE_START,
  CREATE_ISSUE_COMPLETE,
  UPDATE_ISSUE_START,
  UPDATE_ISSUE_COMPLETE,
} from '../actions/issueAction';

const issueReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ISSUE_START:
      return state;

    case FETCH_ISSUE_COMPLETE:
      return action.payload;

    case CREATE_ISSUE_START:
      return state;

    case CREATE_ISSUE_COMPLETE:
      return action.payload;

    case UPDATE_ISSUE_START:
      return state;

    case UPDATE_ISSUE_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default issueReducer;
