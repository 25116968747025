import dateformat from 'dateformat';

import i18n from '../config/i18n';

export const dateFormat = (datestr, format = i18n.t('common.dateFormat')) => {
  if (datestr == null || datestr.length === 0) {
    return null;
  }

  let tmp = datestr;
  if (datestr.length === 19) {
    tmp = datestr.replace(/-/g, '/');
  }

  const date = new Date(tmp);

  if (date.toString() === 'Invalid Date') {
    return null;
  }

  return dateformat(date, format);
};

export const timeFormat = (datestr) => {
  if (datestr == null || datestr.length === 0) {
    return null;
  }

  let tmp = datestr;
  if (datestr.length === 19) {
    tmp = datestr.replace(/-/g, '/');
  }

  const date = new Date(tmp);

  if (date.toString() === 'Invalid Date') {
    return null;
  }

  return dateformat(date, i18n.t('common.timeFormat'));
};

export const getName = (kind, id) => {
  const status = kind.find(e => e.id === id);

  if (status) {
    return status.name || i18n.t(status.i18nKey);
  }

  return null;
};

export const compareForm = (objA, objB) => {
  const objectSort = (obj) => {
    const keys = Object.keys(obj).sort();
    const map = {};

    keys.forEach((key) => {
      if (key !== 'updated_at'
        && key !== 'update_user_id'
        && key !== 'update_user_name'
        && key !== 'body'
        && key !== 'revision'
        && key !== 'images'
      ) {
        let val = obj[key];

        if (val != null && typeof val === 'object') {
          val = objectSort(val);
        }

        map[key] = val;
      }
    });

    return map;
  };

  return JSON.stringify(objectSort(objA)) === JSON.stringify(objectSort(objB));
};

export const imageThumbnailURL = (url, type) => {
  if (type == null || type.length === 0) {
    return url;
  }

  if (url.endsWith('.gif')) {
    return url;
  }

  const tmp = url.split('/');
  tmp.splice(3, 0, type);

  return tmp.join('/');
};

export const hasPermission = (permission, permissions) => {
  if (permissions == null || permissions.permissions == null) {
    return false;
  }

  return permissions.permissions.find(e => e === permission) != null;
};

export const getStrLength = (str) => {
  let result = 0;
  let i = 0;
  while (i < str.length) {
    const chr = str.charCodeAt(i);
    if ((chr >= 0x00 && chr < 0x81) ||
       (chr === 0xf8f0) ||
       (chr >= 0xff61 && chr < 0xffa0) ||
       (chr >= 0xf8f1 && chr < 0xf8f4)) {
      result += 1;
    } else {
      result += 2;
    }
    i += 1;
  }

  return result;
};

export const copyToClipboard = (str) => {
  const textarea = document.createElement('textarea');
  textarea.value = str;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};
