import {
  FETCH_ARTICLES_START,
  FETCH_ARTICLES_COMPLETE,
  FETCH_ARTICLES_WITH_CONDITION_START,
  FETCH_ARTICLES_WITH_CONDITION_COMPLETE,
} from '../actions/articleAction';

const articlesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ARTICLES_START:
      return state;

    case FETCH_ARTICLES_COMPLETE:
      return action.payload;

    case FETCH_ARTICLES_WITH_CONDITION_START:
      return state;

    case FETCH_ARTICLES_WITH_CONDITION_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default articlesReducer;
