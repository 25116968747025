import uuid from 'node-uuid';

import {
  CREATE_SESSION,
} from '../actions/sessionAction';

const sessionReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SESSION:
      return {
        tabID: uuid(),
      };

    default:
      return state;
  }
};

export default sessionReducer;
