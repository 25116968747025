import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ProductOptionEditFormContainer from './ProductOptionEditFormContainer';
import { fetchProductOption, fetchProductOptionsWithCondition, updateProductOption } from '../../actions/productOptionAction';
import { addLoading } from '../../actions/loadingAction';
import Config from '../../config/Config';

class ProductOptionEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchProductOption(this.props.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { productOption } = this.props;

    if (productOption != null && Number(prevProps.params.id) !== productOption.product_option_id) {
      this.props.fetchProductOption(prevProps.params.id);
    }
  }

  handleCloseClick() {
    this.props.router.push('/product_option');
  }

  handleSubmit(form) {
    const newForm = { ...form };

    newForm.sort_key = Number(newForm.sort_key);

    if (newForm.top_view_enable == null || newForm.top_view_enable.length === 0) {
      newForm.top_view_enable = false;
    }

    this.props.addLoading();
    this.props.updateProductOption(newForm, () => this.props.fetchProductOptionsWithCondition());
  }

  handlePreviewClick(e) {
    const { productOption } = this.props;
    window.open(`${Config.WEB_ENDPOINT}/product/set/preview/${productOption.key}/${productOption.preview_key}`, '_blank');
    e.stopPropagation();
  }

  render() {
    const { productOption, params } = this.props;

    if (productOption == null || params.id == null) {
      return null;
    }

    return (
      <div className="productOptionEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
            <button className="previewButton" onClick={this.handlePreviewClick}>
              <i className="fa fa-eye" aria-hidden="true" />
              Preview
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          <ProductOptionEditFormContainer
            id={params.id}
            productOption={productOption}
            saveButton={this.saveButton}
            closeButton={this.closeButton}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCloseClick}
            ref={(ref) => { this.productOptionEditFormContainer = ref; }}
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    productOption: state.productOption,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchProductOption: id => dispatch(fetchProductOption(id)),
    fetchProductOptionsWithCondition:
      (form, productOptions, offset) => (
        dispatch(fetchProductOptionsWithCondition(form, productOptions, offset))),
    updateProductOption: (form, callback) => dispatch(updateProductOption(form, callback)),
    addLoading: () => dispatch(addLoading()),
  }
);

const productOptionEditContainer = withRouter(ProductOptionEditContainer);
export default connect(mapStateToProps, mapDispatchToProps)(productOptionEditContainer);
