import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchProductOptionsWithCondition as fetchProductOptionsWithConditionAPI,
  fetchProductOption as fetchProductOptionAPI,
  createProductOption as createProductOptionAPI,
  updateProductOption as updateProductOptionAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_PRODUCT_OPTIONS_WITH_CONDITION_START = 'FETCH_PRODUCT_OPTIONS_WITH_CONDITION_START';
export const FETCH_PRODUCT_OPTIONS_WITH_CONDITION_COMPLETE = 'FETCH_PRODUCT_OPTIONS_WITH_CONDITION_COMPLETE';
export const FETCH_PRODUCT_OPTION_START = 'FETCH_PRODUCT_OPTION_START';
export const FETCH_PRODUCT_OPTION_COMPLETE = 'FETCH_PRODUCT_OPTION_COMPLETE';
export const CREATE_PRODUCT_OPTION_START = 'CREATE_PRODUCT_OPTION_START';
export const CREATE_PRODUCT_OPTION_COMPLETE = 'CREATE_PRODUCT_OPTION_COMPLETE';
export const UPDATE_PRODUCT_OPTION_START = 'UPDATE_PRODUCT_OPTION_START';
export const UPDATE_PRODUCT_OPTION_COMPLETE = 'UPDATE_PRODUCT_OPTION_COMPLETE';


const fetchProductOptionsWithConditionStart = () => (
  { type: FETCH_PRODUCT_OPTIONS_WITH_CONDITION_START }
);

const fetchProductOptionsWithConditionComplete = (preProductOptions, res) => {
  res.product_options = preProductOptions.concat(res.product_options);

  return {
    type: FETCH_PRODUCT_OPTIONS_WITH_CONDITION_COMPLETE,
    payload: res,
  };
};

const fetchProductOptionStart = () => ({ type: FETCH_PRODUCT_OPTION_START });

const fetchProductOptionComplete = res => ({
  type: FETCH_PRODUCT_OPTION_COMPLETE,
  payload: res,
});

const createProductOptionStart = () => ({ type: CREATE_PRODUCT_OPTION_START });

const createProductOptionComplete = res => ({
  type: CREATE_PRODUCT_OPTION_COMPLETE,
  payload: res,
});

const updateProductOptionStart = () => ({ type: UPDATE_PRODUCT_OPTION_START });

const updateProductOptionComplete = res => ({
  type: UPDATE_PRODUCT_OPTION_COMPLETE,
  payload: res,
});

export const fetchProductOptionsWithCondition = (form = {}, preProductOptions = [], offset = '', callback) => (
  (dispatch) => {
    dispatch(fetchProductOptionsWithConditionStart());

    fetchProductOptionsWithConditionAPI(form, offset).then((res) => {
      dispatch(fetchProductOptionsWithConditionComplete(preProductOptions, res.body));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchProductOptionError', { message: res.text })));
    });
  }
);

export const fetchProductOption = id => (
  (dispatch) => {
    dispatch(fetchProductOptionStart());

    fetchProductOptionAPI(id).then((res) => {
      dispatch(fetchProductOptionComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchProductOptionError', { message: res.text })));
    });
  }
);

export const createProductOption = (form, callback) => (
  (dispatch) => {
    dispatch(createProductOptionStart());

    createProductOptionAPI(form).then((res) => {
      dispatch(createProductOptionComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createProductOptionSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createProductOptionError', { message: res.text })));
    });
  }
);

export const updateProductOption = (form, callback) => (
  (dispatch) => {
    dispatch(updateProductOptionStart());

    updateProductOptionAPI(form).then((res) => {
      dispatch(updateProductOptionComplete(res.body));
      dispatch(removeLoading());
      if (form.status === 'draft') {
        dispatch(addSuccessAlert(i18n.t('messages.draftProductOptionSuccess')));
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.updateProductOptionSuccess')));
      }
      if (callback != null) {
        callback();
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      if (form.status === 'draft') {
        dispatch(addErrorAlert(i18n.t('messages.draftProductOptionError', { message: res.text })));
      } else {
        dispatch(addErrorAlert(i18n.t('messages.updateProductOptionError', { message: res.text })));
      }
    });
  }
);
