import {
  FETCH_PERMISSIONS_START,
  FETCH_PERMISSIONS_COMPLETE,
} from '../actions/permissionAction';

const permissionsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PERMISSIONS_START:
      return state;

    case FETCH_PERMISSIONS_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default permissionsReducer;
