export const ADD_LOADING = 'ADD_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';

export const addLoading = () => ({
  type: ADD_LOADING,
});

export const removeLoading = () => ({
  type: REMOVE_LOADING,
});
