import React from 'react';
import { Link } from 'react-router';

import { timeFormat } from '../../util/util';

const renderArticle = (article, { onSubmit }) => (
  <li key={`searchPopupArticle-${article.article_id}`}>
    <Link onClick={() => onSubmit(article)}>
      <img
        src={
          article.eye_catch_urls != null && article.eye_catch_urls.length > 0 ?
            article.eye_catch_urls[0] :
            '/assets/images/no_image_small.png'
        }
        alt="eyeCatch"
      />

      <div className="description">
        <div className="title">
          {article.titles != null && article.titles.length > 0 ? article.titles[0] : ''}
        </div>
        <div>
          <span className="postDate">
            <i className="fa fa-clock-o" />
            {timeFormat(article.post_date) || '-'}
          </span>
          <span>ID {article.article_id}</span>
        </div>
      </div>
    </Link>
  </li>
);

const ArticleSearchPopup = props => (
  <div className="articleSearchPopup">
    <div className="articleSearchPopup-inner">
      <div className="item">
        <div className="word">
          <input
            type="text"
            ref={props.refSearchWord}
            placeholder={props.t('article.searchPopup.searchWord')}
            onBlur={props.onSearch}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </div>
      </div>

      <div className="articleList">
        <ul>
          {
            props.articles.articles != null ?
              props.articles.articles.map(article => renderArticle(article, props)) : null
          }
        </ul>
      </div>

      <div className="footer">
        <div className="left">
          <button type="button" onClick={props.onCancel}>Cancel</button>
        </div>

        <div className="clear" />
      </div>
    </div>
  </div>
);

export default ArticleSearchPopup;
