import { browserHistory } from 'react-router';

import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchArticles as fetchArticlesAPI,
  fetchArticlesWithCondition as fetchArticlesWithConditionAPI,
  fetchArticle as fetchArticleAPI,
  fetchArticleHistories as fetchArticleHistoriesAPI,
  createArticle as createArticleAPI,
  updateArticle as updateArticleAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_ARTICLES_START = 'FETCH_ARTICLES_START';
export const FETCH_ARTICLES_COMPLETE = 'FETCH_ARTICLES_COMPLETE';
export const FETCH_ARTICLES_WITH_CONDITION_START = 'FETCH_ARTICLES_WITH_CONDITION_START';
export const FETCH_ARTICLES_WITH_CONDITION_COMPLETE = 'FETCH_ARTICLES_WITH_CONDITION_COMPLETE';
export const FETCH_ARTICLE_START = 'FETCH_ARTICLE_START';
export const FETCH_ARTICLE_COMPLETE = 'FETCH_ARTICLE_COMPLETE';
export const FETCH_ARTICLE_HISTORIES_START = 'FETCH_ARTICLE_HISTORIES_START';
export const FETCH_ARTICLE_HISTORIES_COMPLETE = 'FETCH_ARTICLE_HISTORIES_COMPLETE';
export const CREATE_ARTICLE_START = 'CREATE_ARTICLE_START';
export const CREATE_ARTICLE_COMPLETE = 'CREATE_ARTICLE_COMPLETE';
export const UPDATE_ARTICLE_START = 'UPDATE_ARTICLE_START';
export const UPDATE_ARTICLE_COMPLETE = 'UPDATE_ARTICLE_COMPLETE';

const fetchArticlesStart = () => ({ type: FETCH_ARTICLES_START });

const fetchArticlesComplete = (preArticles, res) => {
  res.articles = preArticles.concat(res.articles);

  return {
    type: FETCH_ARTICLES_COMPLETE,
    payload: res,
  };
};

const fetchArticlesWithConditionStart = () => ({ type: FETCH_ARTICLES_WITH_CONDITION_START });

const fetchArticlesWithConditionComplete = (preArticles, res) => {
  res.articles = preArticles.concat(res.articles);

  return {
    type: FETCH_ARTICLES_WITH_CONDITION_COMPLETE,
    payload: res,
  };
};

const fetchArticleStart = () => ({ type: FETCH_ARTICLE_START });

const fetchArticleComplete = res => ({
  type: FETCH_ARTICLE_COMPLETE,
  payload: res,
});

const fetchArticleHistoriesStart = () => ({ type: FETCH_ARTICLE_HISTORIES_START });

const fetchArticleHistoriesComplete = res => ({
  type: FETCH_ARTICLE_HISTORIES_COMPLETE,
  payload: res,
});

const createArticleStart = () => ({ type: CREATE_ARTICLE_START });

const createArticleComplete = (res) => {
  browserHistory.push(`/article/${res.article_id}/edit`);

  return {
    type: CREATE_ARTICLE_COMPLETE,
    payload: res,
  };
};

const updateArticleStart = () => ({ type: UPDATE_ARTICLE_START });

const updateArticleComplete = res => ({
  type: UPDATE_ARTICLE_COMPLETE,
  payload: res,
});

export const fetchArticles = (form = {}, preArticles = [], offset = '', callback) => (
  (dispatch) => {
    dispatch(fetchArticlesStart());

    fetchArticlesAPI(form, offset).then((res) => {
      dispatch(fetchArticlesComplete(preArticles, res.body));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchArticleError', { message: res.text })));
    });
  }
);

export const fetchArticlesWithCondition = (form = {}, preArticles = [], offset = '', callback) => (
  (dispatch) => {
    dispatch(fetchArticlesWithConditionStart());

    fetchArticlesWithConditionAPI(form, offset).then((res) => {
      dispatch(fetchArticlesWithConditionComplete(preArticles, res.body));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchArticleError', { message: res.text })));
    });
  }
);

export const fetchArticle = id => (
  (dispatch) => {
    dispatch(fetchArticleStart());

    fetchArticleAPI(id).then((res) => {
      dispatch(fetchArticleComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchArticleError', { message: res.text })));
    });
  }
);

export const fetchArticleHistories = (id, callback) => (
  (dispatch) => {
    dispatch(fetchArticleHistoriesStart());

    fetchArticleHistoriesAPI(id).then((res) => {
      dispatch(fetchArticleHistoriesComplete(res.body));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchArticleHistoryError', { message: res.text })));
    });
  }
);

export const createArticle = form => (
  (dispatch) => {
    dispatch(createArticleStart());

    createArticleAPI(form).then((res) => {
      dispatch(createArticleComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createArticleSuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createArticleError', { message: res.text })));
    });
  }
);

export const updateArticle = form => (
  (dispatch) => {
    dispatch(updateArticleStart());

    updateArticleAPI(form).then((res) => {
      dispatch(updateArticleComplete(res.body));
      dispatch(removeLoading());
      if (form.status === 'deleted') {
        dispatch(addSuccessAlert(i18n.t('messages.deleteArticleSuccess')));
      } else if (form.status === 'draft') {
        dispatch(addSuccessAlert(i18n.t('messages.draftArticleSuccess')));
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.updateArticleSuccess')));
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      if (form.status === 'deleted') {
        dispatch(addErrorAlert(i18n.t('messages.deleteArticleError', { message: res.text })));
      } else if (form.status === 'draft') {
        dispatch(addErrorAlert(i18n.t('messages.draftArticleError', { message: res.text })));
      } else {
        dispatch(addErrorAlert(i18n.t('messages.updateArticleError', { message: res.text })));
      }
    });
  }
);
