import {
  FETCH_PRODUCT_SET_START,
  FETCH_PRODUCT_SET_COMPLETE,
  CREATE_PRODUCT_SET_START,
  CREATE_PRODUCT_SET_COMPLETE,
  UPDATE_PRODUCT_SET_START,
  UPDATE_PRODUCT_SET_COMPLETE,
} from '../actions/productSetAction';

const productSetReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_SET_START:
      return state;

    case FETCH_PRODUCT_SET_COMPLETE:
      return action.payload;

    case CREATE_PRODUCT_SET_START:
      return state;

    case CREATE_PRODUCT_SET_COMPLETE:
      return action.payload;

    case UPDATE_PRODUCT_SET_START:
      return state;

    case UPDATE_PRODUCT_SET_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default productSetReducer;
