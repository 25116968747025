import {
  ADD_LOADING,
  REMOVE_LOADING,
} from '../actions/loadingAction';

const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case ADD_LOADING:
      return true;

    case REMOVE_LOADING:
      return false;

    default:
      return state;
  }
};

export default loadingReducer;
