import {
  FETCH_TAGS_START,
  FETCH_TAGS_COMPLETE,
} from '../actions/tagAction';

const tagsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TAGS_START:
      return state;

    case FETCH_TAGS_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default tagsReducer;
