import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import Autosuggest from 'react-autosuggest';
import { translate } from 'react-i18next';

import ImageSearchPopup from '../../components/image/ImageSearchPopup';
import { fetchImages, fetchImageTags } from '../../actions/imageAction';

class ImageSearchPopupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);

    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleTagsRenderInput = this.handleTagsRenderInput.bind(this);

    this.handleScroll = this.handleScroll.bind(this);
    this.refImageListContent = this.refImageListContent.bind(this);
    this.refTagsInput = this.refTagsInput.bind(this);

    this.state = {
      prevTagsValue: '',
      selectedTags: null,
    };
  }

  componentDidMount() {
    this.props.fetchImages();
  }

  handleSearch() {
    this.props.fetchImages({
      tags: this.state.selectedTags,
    });
    this.imageListContent.scrollTop = 0;
  }

  handleTagsChange(tags) {
    this.setState({ selectedTags: tags });
    setTimeout(() => this.handleSearch(), 0);
  }

  handleTagsRenderInput({ addTag, value, ref, onChange }) {
    const handleChange = (e, { newValue }) => {
      const tags = this.state.selectedTags || [];

      if (newValue.indexOf(',') >= 0 || newValue.indexOf('  ') >= 0) {
        const tag = newValue.replace(/,/g, '').trim();

        if (tag.length > 0 && !tags.includes(tag)) {
          this.handleTagsChange(tags.concat([tag]));
        }

        this.tagsInput.setState({ tag: '' });
      } else {
        onChange(e);
      }
    };

    const handleKeyUp = (e) => {
      const tags = this.state.selectedTags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Backspace') {
        if (this.state.prevTagsValue === '') {
          this.handleTagsChange(tags.slice(0, tags.length - 1));
        }
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleKeyPress = (e) => {
      const tags = this.state.selectedTags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Enter') {
        if (newValue.length > 0 && !tags.includes(newValue)) {
          this.handleTagsChange(tags.concat([newValue]));
        }

        this.tagsInput.setState({ tag: '' });
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleBlur = () => {
      this.tagsInput.setState({ tag: '' });
    };

    return (
      <Autosuggest
        ref={ref}
        suggestions={this.props.suggestTags.tags || []}
        shouldRenderSuggestions={v => v != null && v.trim().length > 0}
        getSuggestionValue={suggestion => suggestion}
        renderSuggestion={suggestion => <span>{suggestion}</span>}
        inputProps={{
          value,
          placeholder: this.props.t('image.tagsPlaceholder'),
          onChange: handleChange,
          onKeyUp: handleKeyUp,
          onKeyPress: handleKeyPress,
          onBlur: handleBlur,
        }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        onSuggestionsFetchRequested={(e) => {
          this.props.fetchImageTags(e.value);
        }}
        onSuggestionsClearRequested={() => {}}
      />
    );
  }

  handleScroll() {
    const { images } = this.props;
    const imageListBody = this.imageListContent;
    const elementHeight = Math.max(
      imageListBody.offsetHeight,
      imageListBody.clientHeight,
      imageListBody.offsetHeight,
    );
    if (
      images.offset != null &&
      imageListBody.scrollTop + elementHeight >= imageListBody.scrollHeight
    ) {
      this.props.fetchImages(
        {},
        images.images,
        images.offset,
      );
    }
  }

  refImageListContent(ref) {
    this.imageListContent = ref;
  }

  refTagsInput(ref) {
    this.tagsInput = ref;
  }

  render() {
    const { images, onSubmit, onCancel, inputType, suggestTags, t } = this.props;

    return (
      <div className="imageSearchPopupContainer">
        <ImageSearchPopup
          images={images}
          onSearch={this.handleSearch}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onScroll={this.handleScroll}
          inputType={inputType}
          onTagsRenderInput={this.handleTagsRenderInput}
          selectedTags={this.state.selectedTags}
          suggestTags={suggestTags}
          onTagsChange={this.handleTagsChange}
          refImageListContent={this.refImageListContent}
          refTagsInput={this.refTagsInput}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    images: state.images,
    suggestTags: state.imageTags,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchImages: (form, images, offset) => dispatch(fetchImages(form, images, offset)),
    fetchImageTags: value => dispatch(fetchImageTags(value)),
    updateForm: (key, value) => dispatch(change('imageEditForm', key, value)),
  }
);

const imageSearchPopupContainer = translate()(ImageSearchPopupContainer);
export default connect(mapStateToProps, mapDispatchToProps)(imageSearchPopupContainer);
