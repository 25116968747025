import React from 'react';
import Alerts from './Alerts';

const GlobalHeader = ({ alerts, onAlertClick }) => (
  <div className="globalHeader">
    <Alerts alerts={alerts} onAlertClick={onAlertClick} />
  </div>
);

export default GlobalHeader;
