import {
  FETCH_ROLES_START,
  FETCH_ROLES_COMPLETE,
} from '../actions/roleAction';

const rolesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ROLES_START:
      return state;

    case FETCH_ROLES_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default rolesReducer;
