import React from 'react';
import { Field } from 'redux-form';

const ImageEditPopupForm = (props) => {
  const {
    form,
    image,
    onSubmit,
    onCancel,
    t,
  } = props;

  return (
    <div className="imageEditPopup">
      <div className="imageEditPopup-inner">
        <img
          className="image"
          src={image.url}
          alt="content"
        />
        <div className="items">
          <div className="item">
            <div className="imageCredit">
              <div className="item">
                <label htmlFor="creditName">{t('image.creditName')}</label>

                <div className="creditName">
                  <Field name="credit_name" component="input" type="text" />
                </div>
              </div>

              <div className="item">
                <label htmlFor="creditURL">{t('image.creditURL')}</label>

                <div className="creditURL">
                  <Field name="credit_url" component="input" type="text" />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="footer">
          <div className="left">
            <button type="button" onClick={onCancel}>Cancel</button>
          </div>
          <div className="right">
            <button type="button" onClick={() => { onSubmit(form); }}>OK</button>
          </div>

          <div className="clear" />
        </div>
      </div>
    </div>
  );
};

export default ImageEditPopupForm;
