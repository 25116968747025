import React from 'react';
import { Link } from 'react-router';
import classnames from 'classnames';

import Config from '../../config/Config';

const SideMenu = ({ url, permissions, language, onLanguageChange, onLogout, t }) => {
  const MENUS = [
    { name: t('layout.sideMenu.menus.article'), url: '/article' },
    { name: t('layout.sideMenu.menus.image'), url: '/image' },
    { name: t('layout.sideMenu.menus.author'), url: '/author' },
    { name: t('layout.sideMenu.menus.category'), url: '/category' },
    { name: t('layout.sideMenu.menus.tag'), url: '/tag' },
    { name: t('layout.sideMenu.menus.issue'), url: '/issue' },
    { name: t('layout.sideMenu.menus.productSet'), url: '/product_set' },
    { name: t('layout.sideMenu.menus.productOption'), url: '/product_option' },
    { name: t('layout.sideMenu.menus.user'), url: '/user' },
  ];

  const renderMenu = menu => (
    <li className={classnames({ selected: `${url}/`.startsWith(`${menu.url}/`) })} key={menu.url}>
      <Link to={menu.url}>
        <span className="name">{menu.name}</span>
      </Link>
    </li>
  );

  const permittedMenus = MENUS.map((menu) => {
    if (permissions == null) {
      return null;
    }
    if (permissions.permissions != null) {
      for (let i = 0; i < permissions.permissions.length; i += 1) {
        if (menu.url.substring(1).startsWith(permissions.permissions[i])) {
          return renderMenu(menu);
        }
      }
    }

    return null;
  });

  return (
    <div className="sideMenu">
      <div className="content">
        <h1 className="logo">
          <Link to="/">
            <img src={Config.LOGO_IMAGE} alt="fulldepth" />
          </Link>
        </h1>

        <div className="nav">
          <div className="profile">
            <i className="fa fa-user" />
            {permissions != null && permissions.name != null ? permissions.name : ''}
          </div>
          <div className="language">
            <i className="fa fa-globe" />
            <div className="select-wrap">
              <select
                name="language"
                defaultValue={language}
                onChange={onLanguageChange}
              >
                {
                  Config.LANGUAGES
                    .map(e => <option value={e.id} key={e.id}>{e.name}</option>)
                }
              </select>
            </div>
          </div>
        </div>
      </div>
      <ul>
        {permittedMenus}
      </ul>
      <div className="logout">
        <Link className="item" onClick={onLogout}>
          <i className="fa fa-sign-out" aria-hidden="true" />
          Logout
        </Link>
      </div>
    </div>
  );
};

export default SideMenu;
