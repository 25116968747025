import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import TagEditFormContainer from './TagEditFormContainer';
import { fetchTag, fetchTags, updateTag } from '../../actions/tagAction';
import { addErrorAlert } from '../../actions/alertAction';
import { addLoading } from '../../actions/loadingAction';

class TagEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchTag(this.props.params.id);
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.params.id !== this.props.params.id) {
      this.props.fetchTag(nextProps.params.id);
    }
  }

  handleCloseClick() {
    this.props.router.push('/tag');
  }

  handleSubmit(form) {
    const newForm = { ...form };

    if (newForm.child_ids != null &&
      newForm.child_ids.find(id => Number(id) === newForm.parent_id) != null) {
      this.props.addErrorAlert(this.props.t('messages.warningTagTreeInvalid'));
      return;
    }

    this.props.addLoading();
    this.props.updateTag(newForm, e => this.props.fetchTags({ id: e.tag_id }));
  }

  render() {
    const { tag, params } = this.props;

    if (tag == null || params.id == null) {
      return null;
    }

    let editFormContainer = null;
    if (tag.tag_id === Number(params.id)) {
      editFormContainer = (
        <TagEditFormContainer
          id={params.id}
          tag={tag}
          saveButton={this.saveButton}
          closeButton={this.closeButton}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCloseClick}
          ref={(ref) => { this.tagEditFormContainer = ref; }}
        />
      );
    } else if (this.saveButton != null) {
      this.saveButton.onclick = null;
    }

    return (
      <div className="tagEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          {editFormContainer}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    tag: state.tag,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchTag: id => dispatch(fetchTag(id)),
    fetchTags: form => dispatch(fetchTags(form)),
    updateTag: (form, callback) => dispatch(updateTag(form, callback)),
    addErrorAlert: value => dispatch(addErrorAlert(value)),
    addLoading: () => dispatch(addLoading()),
  }
);

const tagEditContainer = withRouter(translate()(TagEditContainer));
export default connect(mapStateToProps, mapDispatchToProps)(tagEditContainer);
