import React from 'react';
import classnames from 'classnames';
import Config from '../../config/Config';
import { getName } from '../../util/util';

const renderProductSet = (productSet, props) => {
  const {
    id,
    onClick,
  } = props;

  const classNames = { status: true };
  classNames[productSet.status] = true;

  return (
    <li
      className={classnames({
        item: true,
        selected: Number(id) === productSet.product_set_id,
      })}
      key={`productSet-${productSet.product_set_id}`}
    >
      <div className="list-content" onClick={() => onClick(productSet.product_set_id)}>
        <img
          src={productSet.catalog_image_url != null &&
            productSet.catalog_image_url.length > 0 ?
            productSet.catalog_image_url : '/assets/images/no_image_small.png'}
          alt="catalogImageURL"
        />

        <div className="description">
          <div>
            <span className={classnames(classNames)}>
              {getName(Config.PRODUCT_STATUSES, productSet.status)}
            </span>
            <span className="name">
              {`${productSet.name} (${productSet.key})`}
            </span>
          </div>
          <div className="description">
            <span>
              {productSet.seo_description}
            </span>
          </div>
        </div>

      </div>
    </li>
  );
};

const ProductSetList = (props) => {
  const { productSets } = props;

  if (productSets == null) {
    return null;
  }

  return (
    <div className="productSetList">
      <ul>
        {productSets.map(productSet => renderProductSet(productSet, props))}
      </ul>
    </div>
  );
};

export default ProductSetList;
