import React from 'react';
import { Field } from 'redux-form';

import { timeFormat } from '../../util/util';

const UserEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    form,
    user,
    roles,
    t,
  } = props;

  return (
    <div className="userEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="id">ID</label>
            <div className="id">
              {user.user_id}
            </div>
          </div>

          <div className="item">
            <label htmlFor="name">{t('user.name')}</label>
            <div className="name">
              <Field name="name" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="email">{t('user.email')}</label>
            <div className="email">
              <Field name="email" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="role">{t('user.role')}</label>
            <div className="title">
              <div className="select-wrap">
                <Field name="role_id" component="select">
                  <option value="0" key="0" />
                  {
                    roles != null ?
                      roles.map(role => (
                        <option value={role.role_id} key={role.role_id}>
                          {role.name}
                        </option>
                      )) : null
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="lastAccessAt">{t('user.lastAccessAt')}</label>
            <div className="lastAccessAt">
              {timeFormat(user.last_access_at) || '-'}
            </div>
          </div>

          <div className="item">
            <label htmlFor="createdAt">{t('common.createdAt')}</label>
            <div className="createdAt">
              {timeFormat(user.created_at)}
            </div>
          </div>

          <div className="item">
            <label htmlFor="updateAt">{t('common.updatedAt')}</label>
            <div className="updatedAt">
              {timeFormat(user.updated_at)}
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default UserEditForm;
