import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchAuthors as fetchAuthorsAPI,
  fetchAuthor as fetchAuthorAPI,
  createAuthor as createAuthorAPI,
  updateAuthor as updateAuthorAPI,
  deleteAuthor as deleteAuthorAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_AUTHORS_START = 'FETCH_AUTHORS_START';
export const FETCH_AUTHORS_COMPLETE = 'FETCH_AUTHORS_COMPLETE';
export const FETCH_AUTHOR_START = 'FETCH_AUTHOR_START';
export const FETCH_AUTHOR_COMPLETE = 'FETCH_AUTHOR_COMPLETE';
export const CREATE_AUTHOR_START = 'CREATE_AUTHOR_START';
export const CREATE_AUTHOR_COMPLETE = 'CREATE_AUTHOR_COMPLETE';
export const UPDATE_AUTHOR_START = 'UPDATE_AUTHOR_START';
export const UPDATE_AUTHOR_COMPLETE = 'UPDATE_AUTHOR_COMPLETE';
export const DELETE_AUTHOR_START = 'DELETE_AUTHOR_START';
export const DELETE_AUTHOR_COMPLETE = 'DELETE_AUTHOR_COMPLETE';

const fetchAuthorsStart = () => ({ type: FETCH_AUTHORS_START });

const fetchAuthorsComplete = res => ({
  type: FETCH_AUTHORS_COMPLETE,
  payload: res,
});

const fetchAuthorStart = () => ({ type: FETCH_AUTHOR_START });

const fetchAuthorComplete = res => ({
  type: FETCH_AUTHOR_COMPLETE,
  payload: res,
});

const createAuthorStart = () => ({ type: CREATE_AUTHOR_START });

const createAuthorComplete = res => ({
  type: CREATE_AUTHOR_COMPLETE,
  payload: res,
});

const updateAuthorStart = () => ({ type: UPDATE_AUTHOR_START });

const updateAuthorComplete = res => ({
  type: UPDATE_AUTHOR_COMPLETE,
  payload: res,
});

const deleteAuthorStart = () => ({ type: DELETE_AUTHOR_START });

const deleteAuthorComplete = () => ({
  type: DELETE_AUTHOR_COMPLETE,
  payload: {},
});

export const fetchAuthors = () => (
  (dispatch) => {
    dispatch(fetchAuthorsStart());

    fetchAuthorsAPI().then((res) => {
      const authors = res.body;
      authors.authors.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      dispatch(fetchAuthorsComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchAuthorError', { message: res.text })));
    });
  }
);

export const fetchAuthor = id => (
  (dispatch) => {
    dispatch(fetchAuthorStart());

    fetchAuthorAPI(id).then((res) => {
      dispatch(fetchAuthorComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchAuthorError', { message: res.text })));
    });
  }
);

export const createAuthor = (form, callback) => (
  (dispatch) => {
    dispatch(createAuthorStart());

    createAuthorAPI(form).then((res) => {
      dispatch(createAuthorComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createAuthorSuccess')));

      if (callback != null) {
        callback(res.body);
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.createAuthorSuccess')));
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createAuthorError', { message: res.text })));
    });
  }
);

export const updateAuthor = (form, callback) => (
  (dispatch) => {
    dispatch(updateAuthorStart());

    updateAuthorAPI(form).then((res) => {
      dispatch(updateAuthorComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.updateAuthorSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateAuthorError', { message: res.text })));
    });
  }
);

export const deleteAuthor = id => (
  (dispatch) => {
    dispatch(deleteAuthorStart());

    deleteAuthorAPI(id).then(() => {
      dispatch(deleteAuthorComplete());
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.deleteAuthorSuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.deleteAuthorError', { message: res.text })));
    });
  }
);
