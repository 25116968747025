import { browserHistory } from 'react-router';
import Cookies from 'universal-cookie';
import request from 'superagent';

import Config from '../config/Config';

const createRequest = (req, callback) => (
  new Promise((resolve, reject) => {
    req.set('Authorization', `Bearer ${new Cookies().get('auth_token')}`)
      .set('Namespace', Config.NAMESPACE)
      .end((err, res) => {
        if (res == null) {
          callback('Connection timeout', { status: 500, text: 'Connection timeout.' }, resolve, reject);
        }

        console.log('API Response: %s %o', res.req != null ? res.req.url : null, {
          method: res.req != null ? res.req.method : null,
          url: res.req != null ? res.req.url : null,
          status: res.status,
          body: res.body,
        });
        callback(err, res, resolve, reject);
      });

    console.log('API Request: %s %o', req.url, {
      method: req.method,
      url: req.url,
    });
  })
);

const callbackProcess = (err, res, resolve, reject) => {
  if (err != null) {
    if (res.status === 401) {
      browserHistory.push('/login');
    } else {
      reject(res);
    }
  } else {
    resolve(res);
  }
};

export const fetchArticles = (form, offset) => {
  const query = Object.assign(form, { offset });
  const req = request.get(`${Config.API_ENDPOINT}/article`).query(query);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { articles: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchArticlesWithCondition = (form, offset) => {
  const sendForm = { type: 'cms', condition: form, offset, limit: 50 };
  const req = request.post(`${Config.API_ENDPOINT}/article_with_condition`).send(sendForm);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { articles: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchArticle = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/article/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchArticleHistories = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/article/${id}/history`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { article_histories: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const createArticle = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/article`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateArticle = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/article/${form.article_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const fetchImages = (form, offset) => {
  const query = Object.assign(form, { offset });
  const req = request.get(`${Config.API_ENDPOINT}/image`).query(query);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { images: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchImage = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/image/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchImageTags = (prefix) => {
  const req = request.get(`${Config.API_ENDPOINT}/image_tag`).query({ prefix });

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { image_tags: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchImageSources = (prefix) => {
  const req = request.get(`${Config.API_ENDPOINT}/image_source`).query({ prefix });

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { image_sources: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const createImage = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/image`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateImage = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/image/${form.image_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteImage = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/image/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchAuthors = () => {
  const req = request.get(`${Config.API_ENDPOINT}/author`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { authors: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchAuthor = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/author/${id}`);
  return createRequest(req, callbackProcess);
};

export const createAuthor = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/author`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateAuthor = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/author/${form.author_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteAuthor = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/author/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchCategories = () => {
  const req = request.get(`${Config.API_ENDPOINT}/category`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { categories: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchCategory = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/category/${id}`);
  return createRequest(req, callbackProcess);
};

export const createCategory = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/category`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateCategory = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/category/${form.category_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteCategory = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/category/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchTags = (form) => {
  const req = request.get(`${Config.API_ENDPOINT}/tag`).query(form);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { tags: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchTag = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/tag/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchFixedTags = (prefix) => {
  const req = request.get(`${Config.API_ENDPOINT}/tag`).query({ prefix });

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { tags: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const createTag = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/tag`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateTag = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/tag/${form.tag_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteTag = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/tag/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchIssuesWithCondition = (form) => {
  const sendForm = { type: 'cms', condition: form };
  const req = request.post(`${Config.API_ENDPOINT}/issue_with_condition`).send(sendForm);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { issues: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchIssue = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/issue/${id}`);
  return createRequest(req, callbackProcess);
};

export const createIssue = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/issue`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateIssue = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/issue/${form.issue_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteIssue = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/issue/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchProductSetsWithCondition = (form) => {
  const sendForm = { type: 'cms', condition: form };
  const req = request.post(`${Config.API_ENDPOINT}/product_set_with_condition`).send(sendForm);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { product_sets: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchProductSet = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/product_set/${id}`);
  return createRequest(req, callbackProcess);
};

export const createProductSet = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/product_set`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateProductSet = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/product_set/${form.product_set_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteProductSet = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/product_set/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchProductOptionsWithCondition = (form) => {
  const sendForm = { type: 'cms', condition: form };
  const req = request.post(`${Config.API_ENDPOINT}/product_option_with_condition`).send(sendForm);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { product_options: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchProductOption = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/product_option/${id}`);
  return createRequest(req, callbackProcess);
};

export const createProductOption = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/product_option`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateProductOption = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/product_option/${form.product_option_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteProductOption = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/product_option/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchUsers = (form, offset) => {
  const query = Object.assign(form, { offset });
  const req = request.get(`${Config.API_ENDPOINT}/user`).query(query);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { users: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchUser = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/user/${id}`);
  return createRequest(req, callbackProcess);
};

export const createUser = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/user`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateUser = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/user/${form.user_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteUser = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/user/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchPermissions = (token) => {
  const req = request.get(`${Config.API_ENDPOINT}/permission`).query({ token });
  return createRequest(req, callbackProcess);
};

export const fetchRoles = () => {
  const req = request.get(`${Config.API_ENDPOINT}/role`);
  return createRequest(req, callbackProcess);
};

export const createEditExclusion = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/edit_exclusion`).send(form);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { edit_exclusions: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const deleteEditExclusion = (form) => {
  const req = request.del(`${Config.API_ENDPOINT}/edit_exclusion`).send(form);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { edit_exclusions: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};
