import React from 'react';
import classnames from 'classnames';

const renderUser = (user, props) => {
  const {
    id,
    roles,
    onClick,
  } = props;

  return (
    <li
      className={classnames({ item: true, selected: Number(id) === user.user_id })}
      key={`user-${user.user_id}`}
    >
      <div className="list-content" onClick={() => onClick(user.user_id)}>

        <div className="description">
          <div>
            <span className="name">
              {`${user.name} (${user.email})`}
            </span>
          </div>
          <div>
            <span className="id">
              ID: {user.user_id}
            </span>
            <span className="role">
              <i className="fa fa-user" />
              {roles != null && roles.find(e => e.id === user.role_id) != null ?
                roles.find(e => e.id === user.role_id).name : '-'}
            </span>
            <span className="lastAccessAt">
              <i className="fa fa-clock-o" />
              {user.last_access_at || '-'}
            </span>
          </div>
        </div>

      </div>
    </li>
  );
};

const UserList = (props) => {
  const { users } = props;

  if (users == null) {
    return null;
  }

  return (
    <div className="userList">
      <ul>
        {users.map(user => renderUser(user, props))}
      </ul>
    </div>
  );
};

export default UserList;
