import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import CategoryEditFormContainer from './CategoryEditFormContainer';
import { fetchCategory, fetchCategories, updateCategory } from '../../actions/categoryAction';
import { addLoading } from '../../actions/loadingAction';

class CategoryEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchCategory(this.props.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { category } = this.props;

    if (category != null && Number(prevProps.params.id) !== category.category_id) {
      this.props.fetchCategory(prevProps.params.id);
    }
  }

  handleCloseClick() {
    this.props.router.push('/category');
  }

  handleSubmit(form) {
    const newForm = { ...form };

    newForm.sort_key = Number(newForm.sort_key);

    if (newForm.top_view_enable == null || newForm.top_view_enable.length === 0) {
      newForm.top_view_enable = false;
    }

    this.props.addLoading();
    this.props.updateCategory(newForm, () => this.props.fetchCategories());
  }

  render() {
    const { category, params } = this.props;

    if (category == null || params.id == null) {
      return null;
    }

    return (
      <div className="categoryEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          <CategoryEditFormContainer
            id={params.id}
            category={category}
            saveButton={this.saveButton}
            closeButton={this.closeButton}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCloseClick}
            ref={(ref) => { this.categoryEditFormContainer = ref; }}
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    category: state.category,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchCategory: id => dispatch(fetchCategory(id)),
    fetchCategories:
      (form, categories, offset) => dispatch(fetchCategories(form, categories, offset)),
    updateCategory: (form, callback) => dispatch(updateCategory(form, callback)),
    addLoading: () => dispatch(addLoading()),
  }
);

const categoryEditContainer = withRouter(CategoryEditContainer);
export default connect(mapStateToProps, mapDispatchToProps)(categoryEditContainer);
