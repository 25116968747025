import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { translate } from 'react-i18next';

import ProductSetList from '../../components/productSet/ProductSetList';
import ProductSetEditContainer from './ProductSetEditContainer';
import { fetchProductSetsWithCondition, createProductSet } from '../../actions/productSetAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';

class ProductSetContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleProductSetClick = this.handleProductSetClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchProductSetsWithCondition();
  }

  handleNewClick() {
    this.props.router.push('/product_set');
    this.props.addLoading();
    this.props.createProductSet({ name: 'no name' }, (productSet) => {
      this.props.fetchProductSetsWithCondition();
      this.props.router.push(`/product_set/${productSet.product_set_id}`);
      this.props.addSuccessAlert(this.props.t('messages.createProductSetSuccess'));
    });
  }

  handleProductSetClick(id) {
    this.props.router.push(`/product_set/${id}`);
  }

  render() {
    const { productSets, params, t } = this.props;

    return (
      <div className="productSetContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="header">
            <div className="buttons right">
              <button
                type="button"
                onClick={this.handleNewClick}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Create ProductSet
              </button>
            </div>

            <div className="clear" />
          </div>

          <div
            className="content"
            ref={(ref) => { this.productSetListContent = ref; }}
          >
            <ProductSetList
              id={params.id}
              productSets={productSets.product_sets}
              onClick={this.handleProductSetClick}
              t={t}
            />
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <ProductSetEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    productSets: state.productSets,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchProductSetsWithCondition:
      (form, productSets, offset) => (
        dispatch(fetchProductSetsWithCondition(form, productSets, offset))),
    createProductSet: (form, callback) => dispatch(createProductSet(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const productSetContainer = withRouter(translate()(ProductSetContainer));
export default connect(mapStateToProps, mapDispatchToProps)(productSetContainer);
