import {
  CREATE_EDIT_EXCLUSION_START,
  CREATE_EDIT_EXCLUSION_COMPLETE,
  DELETE_EDIT_EXCLUSION_START,
  DELETE_EDIT_EXCLUSION_COMPLETE,
} from '../actions/editExclusionAction';

const editExclusionsReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_EDIT_EXCLUSION_START:
      return state;

    case CREATE_EDIT_EXCLUSION_COMPLETE:
      return action.payload;

    case DELETE_EDIT_EXCLUSION_START:
      return state;

    case DELETE_EDIT_EXCLUSION_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default editExclusionsReducer;
