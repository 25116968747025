import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { translate } from 'react-i18next';

import UserList from '../../components/user/UserList';
import UserEditContainer from './UserEditContainer';
import { fetchUsers, createUser } from '../../actions/userAction';
import { fetchRoles } from '../../actions/roleAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';

class UserContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleUserClick = this.handleUserClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchUsers();
    this.props.fetchRoles();
  }

  handleNewClick() {
    this.props.router.push('/user');
    this.props.addLoading();
    this.props.createUser({ name: 'no name' }, (user) => {
      this.props.fetchUsers();
      this.props.router.push(`/user/${user.user_id}`);
      this.props.addSuccessAlert(this.props.t('messages.createUserSuccess'));
    });
  }

  handleUserClick(id) {
    this.props.router.push(`/user/${id}`);
  }

  render() {
    const { users, roles, params, t } = this.props;

    return (
      <div className="userContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="header">
            <div className="buttons right">
              <button
                type="button"
                onClick={this.handleNewClick}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Create User
              </button>
            </div>

            <div className="clear" />
          </div>

          <div
            className="content"
            ref={(ref) => { this.userListContent = ref; }}
          >
            <UserList
              id={params.id}
              users={users.users}
              roles={roles.roles}
              onClick={this.handleUserClick}
              t={t}
            />
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <UserEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    users: state.users,
    roles: state.roles,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchUsers: (form, users, offset) => dispatch(fetchUsers(form, users, offset)),
    createUser: (form, callback) => dispatch(createUser(form, callback)),
    fetchRoles: () => dispatch(fetchRoles()),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const userContainer = withRouter(translate()(UserContainer));
export default connect(mapStateToProps, mapDispatchToProps)(userContainer);
