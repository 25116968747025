const i18nJA = {
  layout: {
    sideMenu: {
      menus: {
        article: '記事管理',
        image: '画像管理',
        author: 'ライター管理',
        category: 'カテゴリ管理',
        tag: 'タグ管理',
        issue: '課題管理',
        productSet: '商品セット管理',
        productOption: '商品オプション管理',
        user: 'アカウント管理',
      },
    },
  },
  article: {
    eyeCatchURLs: 'アイキャッチ画像',
    hideEyeCatch: 'アイキャッチを記事内で非表示にする',
    displayOriginalRatioEyeCatch: 'アイキャッチを記事内でオリジナル比率で表示する',
    headerVideoURLs: 'アイキャッチYoutube動画',
    mobileYoutubeVideoEyeCatchIDPlaceholder: 'モバイル用Youtube動画IDを入力してください',
    pcYoutubeVideoEyeCatchIDPlaceholder: 'PC用Youtube動画IDを入力してください',
    titles: '本文タイトル',
    subTitle: 'サブタイトル',
    author: 'ライター',
    category: 'カテゴリー',
    url: 'URL',
    copyUrl: 'プレビューURLをコピー',
    brand: 'ブランド',
    contentType: 'コンテンツタイプ',
    tags: 'タグ',
    seoTitle: 'SEOタイトル',
    socialTitle: 'Socialタイトル',
    description: 'Description',
    embedCode: '埋込タグ',
    status: 'ステータス',
    postDate: '公開日時',
    memo: 'メモ',
    eyeCatchURLsTooltip: 'ABテストを実装した場合にサブ画像も活用します。',
    displayOriginalRatioEyeCatchTooltip: 'チェックすると元の画像比率を維持したアイキャッチ画像が記事内で表示されます。',
    eyeCatchURLsToMainTooltip: '表示中の画像をメイン画像にする',
    titlesTooltip: 'ABテストを実装した場合に二番目以降のタイトルも活用します。',
    titlesToMainTooltip: 'メインタイトルにする',
    subTitleTooltip: 'ストーリー、深海プロジェクトの記事で活用します',
    tagsTooltip: 'タグページや検索に利用されるタグを入力してください。',
    seoTitleTooltip: 'Googleなどの検索結果ページに掲載するタイトルを入力してください。<br>30文字を超えると、途中で切られる場合があります。<br>未入力の場合、本文タイトルが使用されます。<br>検索ヒットさせたいキーワードは本文または本文タイトルに入れたほうが効果があります。',
    socialTitleTooltip: 'Facebook, TwitterなどSNSに掲載するタイトルを入力してください。<br>30文字を超えると、途中で切られる場合があります。<br>未入力の場合、本文タイトルが使用されます。',
    descriptionTooltip: 'Googleなどの検索結果ページに掲載する記事の説明文を入力してください。<br>250文字以内で入力してください。スマホの場合200文字前後で切られるため、重要な説明は先頭から200文字以内に含めてください。<br>検索ヒットさせたいキーワードは本文または本文タイトルに入れたほうが効果があります。',
    descriptionCount: '文字数: {{count}}',
    descriptionCountAlert: '設定可能な文字数を超えています。256文字以下にしてください。',
    tagsPlaceholder: 'タグを入力',
    sentence: '文章',
    heading: '見出し',
    image: '画像',
    embed: '埋込',
    etc: 'その他',
    imageURLPlaceholder: 'リンク先URLを入力',
    imageAltPlaceholder: 'altテキストを入力',
    embedPlaceholder: '埋込タグを入力',
    creditTypePlaceholder: 'クレジットタイプを入力',
    creditNamePlaceholder: 'クレジット名を入力',
    creditURLPlaceholder: 'クレジットURLを入力',
    creditAddPlaceholder: 'クレジットを追加',
    authorNamePlaceholder: '名前を入力',
    referenceNamePlaceholder: '引用元を入力してください。',
    referenceURLPlaceholder: '引用元のリンクを入力してください。',
    insertSection: 'ここにセクションを挿入',
    editExclusion: '{{userName}} が編集中です',
    articleHistory: '記事履歴',
    searchForm: {
      word: '記事ID/タイトル先頭文字',
      wordPlaceholder: '記事ID・タイトル先頭文字を入力してEnter',
      status: 'ステータス',
      brand: 'ブランド',
      contentType: 'コンテンツタイプ',
      isMyArticle: '自分で作成した記事のみ表示',
    },
    searchPopup: {
      searchWord: '記事ID・タイトル先頭文字を入力してEnter',
    },
    publishSettingPopup: {
      popTitle: '公開設定',
      postDate: '公開日時',
      editDate: '更新日',
      cancel: 'キャンセル',
      save: '保存',
      saveAsReserved: '予約保存',
    },
    statusBar: {
      posted: '公開済み',
      reserved: '公開予定',
      deleted: '削除済み',
    },
    header: {
      delete: '削除',
      history: '履歴',
      preview: 'プレビュー',
      overwrite: '上書き保存',
      saveAsDraft: '下書き保存',
      returnToDraft: '下書きに戻す',
      publishSettings: '公開設定',
    },
  },
  image: {
    url: 'URL',
    tags: 'タグ',
    userName: 'ユーザ名',
    imageType: '画像種類',
    source: '画像サイト名',
    tagsPlaceholder: 'タグを入力',
    credit: 'クレジット',
    creditName: 'クレジット名',
    creditURL: 'クレジットURL',
    hideCredit: 'クレジットを非表示にする',
    isFullWidth: 'デバイス幅いっぱいまで画像を表示する',
    searchForm: {
      tags: 'タグ',
      imageType: '画像種類',
      isMyImage: '自分でアップロードした画像のみ表示',
    },
    searchPopup: {
      searchWord: 'タグを入力してEnter',
    },
    uploadFile: 'アップロードファイル',
    draggableImage: '画像ファイルをドラッグアンドドロップしてください',
  },
  author: {
    name: '名前',
    key: 'キー',
    title: '肩書',
    profile: 'プロフィール',
    icon: 'アイコン画像',
  },
  category: {
    name: '名前',
    key: 'キー',
    description: '説明',
    seoName: '名前(SEO)',
    mobileHeaderImageURL: 'ヘッダーイメージURL(mobile)',
    pcHeaderImageURL: 'ヘッダーイメージURL(pc)',
    sortKey: '表示順',
  },
  tag: {
    name: '名前',
    description: '説明',
    parentID: '親タグ',
    childIDs: '子タグ',
    searchTag: 'タグを入力してEnter',
  },
  issue: {
    name: '名前',
    key: 'キー',
    topDescription: 'TOPページ説明',
    catalogDescription: '一覧ページ説明',
    seoDescription: 'SEO Description',
    issues: '課題',
    summaries: 'サマリー',
    featureFunctions: '特徴・機能',
    detailYoutubeVideoID: '詳細ページYoutube動画ID',
    showDetailYoutubeVideo: '詳細ページにYoutube動画を表示する',
    catalogImageURL: '一覧イメージURL',
    mobileHeaderImageURL: 'ヘッダーイメージURL(mobile)',
    pcHeaderImageURL: 'ヘッダーイメージURL(desktop)',
    sortKey: '表示順',
    status: 'ステータス',
  },
  productSet: {
    name: '名前',
    key: 'キー',
    catalogDescription: '一覧ページ説明',
    detailDescription: '詳細ページ説明',
    seoDescription: 'SEO description',
    featureFunctions: '特徴・機能',
    detailYoutubeVideoID: '詳細ページYoutube動画ID',
    showDetailYoutubeVideo: '詳細ページにYoutube動画を表示する',
    catalogImageURL: '一覧イメージURL',
    mobileHeaderImageURL: 'ヘッダーイメージURL(mobile)',
    pcHeaderImageURL: 'ヘッダーイメージURL(desktop)',
    sortKey: '表示順',
    status: 'ステータス',
  },
  productOption: {
    name: 'Name',
    key: 'Key',
    purposes: '目的',
    catalogDescription: '一覧ページ説明',
    detailDescription: '詳細ページ説明',
    catalogSceneDescription: '一覧ページシーン説明',
    seoDescription: 'SEO description',
    summaries: 'サマリー',
    featureFunctions: '特徴・機能',
    detailYoutubeVideoID: '詳細ページYoutube動画ID',
    showDetailYoutubeVideo: '詳細ページにYoutube動画を表示する',
    catalogImageURL: '一覧イメージURL',
    mobileHeaderImageURL: 'ヘッダーイメージURL(mobile)',
    pcHeaderImageURL: 'ヘッダーイメージURL(desktop)',
    sortKey: '表示順',
    status: 'ステータス',
  },
  user: {
    name: '名前',
    email: 'メールアドレス',
    role: 'ロール',
    lastAccessAt: '最新アクセス日時',
  },
  common: {
    createdAt: '作成日時',
    updatedAt: '更新日時',
    createUserName: '作成者',
    updateUserName: '更新者',
    selectAll: '全て',
    match: '一致',
    notMatch: '除外',
    main: 'メイン',
    sub: 'サブ{{index}}',
    download: 'ダウンロード',
    new: '新規作成',
    dateFormat: 'yyyy/mm/dd',
    timeFormat: 'yyyy/mm/dd HH:MM:ss',
    articleContentTypes: {
      news: 'ニュース',
      product: 'プロダクト',
      event: 'イベント',
      original: 'オリジナル',
    },
    articleStatuses: {
      draft: '下書き',
      reserved: '予約',
      posted: '公開',
      deleted: '削除',
    },
    articleInputTypes: {
      credit: 'クレジット',
      relationArticle: '関連記事',
      reference: '引用',
    },
    imageImageTypes: {
      eyeCatch: 'アイキャッチ',
      article: '記事内',
      material: '素材',
    },
    featureFunctionsItems: {
      name: '名前',
      eyecatchType: 'アイキャッチタイプ',
      imageURL: 'イメージURL',
      imagePreview: 'イメージプレビュー',
      youtubeVideoID: 'Youtube Video ID',
      youtubePreview: 'Youtubeプレビュー',
      example: '具体例',
      equipment: '使用機材',
      text: 'テキスト',
    },
    productOptionPurposeTypes: {
      performance: '基本性能アップ',
      watching: 'みたい',
      measuring: '測りたい',
      collection: '設置・回収',
      cleaning: '掃除したい',
    },
  },
  messages: {
    authFailure: 'ユーザ認証に失敗しました。',
    fetchArticleError: '記事の取得に失敗しました。 {{message}}',
    fetchArticleHistoryError: '記事履歴の取得に失敗しました。 {{message}}',
    fetchArticleDocxError: 'Docxファイルの取得に失敗しました。 {{message}}',
    createArticleSuccess: '記事を作成しました。',
    createArticleError: '記事の作成に失敗しました。 {{message}}',
    updateArticleSuccess: '記事を更新しました。',
    updateArticleError: '記事の更新に失敗しました。 {{message}}',
    deleteArticleSuccess: '記事を削除しました。',
    deleteArticleError: '記事の削除に失敗しました。 {{message}}',
    deleteArticleConfirm: '記事を本当に削除しますか？',
    draftArticleSuccess: '記事を下書き保存しました。',
    draftArticleError: '記事の下書き保存に失敗しました。 {{message}}',
    fetchImageError: '画像の取得に失敗しました。 {{message}}',
    fetchImageSourceError: '画像サイトの取得に失敗しました。 {{message}}',
    createImageSuccess: '画像をアップロードしました。',
    createImageError: '画像のアップロードに失敗しました。 {{message}}',
    createImageValidateError: 'アップロードファイルが指定されていません。',
    updateImageSuccess: '画像を更新しました。',
    updateImageError: '画像の更新に失敗しました。 {{message}}',
    deleteImageSuccess: '画像を削除しました。',
    deleteImageError: '画像の削除に失敗しました。 {{message}}',
    fetchAuthorError: 'ライターの取得に失敗しました。 {{message}}',
    createAuthorSuccess: 'ライターを作成しました。',
    createAuthorError: 'ライターの作成に失敗しました。 {{message}}',
    updateAuthorSuccess: 'ライターを更新しました。',
    updateAuthorError: 'ライターの更新に失敗しました。 {{message}}',
    deleteAuthorSuccess: 'ライターを削除しました。',
    deleteAuthorError: 'ライターの削除に失敗しました。 {{message}}',
    fetchCategoryError: 'カテゴリーの取得に失敗しました。 {{message}}',
    createCategorySuccess: 'カテゴリーを作成しました。',
    createCategoryError: 'カテゴリーの作成に失敗しました。 {{message}}',
    updateCategorySuccess: 'カテゴリーを更新しました。',
    updateCategoryError: 'カテゴリーの更新に失敗しました。 {{message}}',
    deleteCategorySuccess: 'カテゴリーを削除しました。',
    deleteCategoryError: 'カテゴリーの削除に失敗しました。 {{message}}',
    fetchTagError: 'タグの取得に失敗しました。 {{message}}',
    createTagSuccess: 'タグを作成しました。',
    createTagError: 'タグの作成に失敗しました。 {{message}}',
    updateTagSuccess: 'タグを更新しました。',
    updateTagError: 'タグの更新に失敗しました。 {{message}}',
    deleteTagSuccess: 'タグを削除しました。',
    deleteTagError: 'タグの削除に失敗しました。 {{message}}',
    fetchIssueError: '課題の取得に失敗しました。 {{message}}',
    createIssueSuccess: '課題を作成しました。',
    createIssueError: '課題の作成に失敗しました。 {{message}}',
    draftIssueSuccess: '課題を下書き保存しました。',
    draftIssueError: '課題の下書き保存に失敗しました。 {{message}}',
    updateIssueSuccess: '課題を更新しました。',
    updateIssueError: '課題の更新に失敗しました。 {{message}}',
    deleteIssueSuccess: '課題を削除しました。',
    deleteIssueError: '課題の削除に失敗しました。 {{message}}',
    fetchProductSetError: '商品セットの取得に失敗しました。 {{message}}',
    createProductSetSuccess: '商品セットを作成しました。',
    createProductSetError: '商品セットの作成に失敗しました。 {{message}}',
    draftProductSetSuccess: '商品セットを下書き保存しました。',
    draftProductSetError: '商品セットの下書き保存に失敗しました。 {{message}}',
    updateProductSetSuccess: '商品セットを更新しました。',
    updateProductSetError: '商品セットの更新に失敗しました。 {{message}}',
    deleteProductSetSuccess: '商品セットを削除しました。',
    deleteProductSetError: '商品セットの削除に失敗しました。 {{message}}',
    fetchProductOptionError: '商品オプションの取得に失敗しました。 {{message}}',
    createProductOptionSuccess: '商品オプションを作成しました。',
    createProductOptionError: '商品オプションの作成に失敗しました。 {{message}}',
    draftProductOptionSuccess: '商品オプションを下書き保存しました。',
    draftProductOptionError: '商品オプションの下書き保存に失敗しました。 {{message}}',
    updateProductOptionSuccess: '商品オプションを更新しました。',
    updateProductOptionError: '商品オプションの更新に失敗しました。 {{message}}',
    deleteProductOptionSuccess: '商品オプションを削除しました。',
    deleteProductOptionError: '商品オプションの削除に失敗しました。 {{message}}',
    fetchUserError: 'ユーザーの取得に失敗しました。 {{message}}',
    createUserSuccess: 'ユーザーを作成しました。',
    createUserError: 'ユーザーの作成に失敗しました。 {{message}}',
    updateUserSuccess: 'ユーザーを更新しました。',
    updateUserError: 'ユーザーの更新に失敗しました。 {{message}}',
    deleteUserSuccess: 'ユーザーを削除しました。',
    deleteUserError: 'ユーザーの削除に失敗しました。 {{message}}',
    fetchRoleError: 'ロールの取得に失敗しました。 {{message}}',
    warningEditingSection: 'セクションが開いた状態でSaveはおこなえません。OKまたはCancelをクリックしてセクションの編集を確定させてください。',
    warningPastDateReservation: '公開日時に過去の日時が設定されています。',
    warningRequirePostDate: '公開日時を設定してください。',
    warningPastEditDate: '公開日時よりも前に更新日を設定することはできません。',
    warningRequireImageCredit: '画像にクレジットを設定してください。',
    discardEditingArticle: '編集内容が保存されていません。破棄してよろしいですか？',
    restoreFromLocalStorage: '自動保存されたデータが存在します。復元しますか？<br>Cancelをクリックすると自動保存されたデータは破棄されます。',
    googleMapsURLError: 'URLに誤りがあります。GoogleマップのURLを入力してください。',
    warningTagTreeInvalid: '親タグと子タグに同じタグは設定できません。',
    previewUrlCopied: 'プレビューURLがコピーされました',
  },
};

export default i18nJA;
