import React from 'react';

const Alerts = ({ alerts, onAlertClick }) => {
  if (alerts == null || Object.keys(alerts).length === 0) {
    return null;
  }

  return (
    <div className="alerts">
      {alerts.map(alert => (
        alert.style !== 'confirm' ? (
          <div className={`alert ${alert.style}`} key={alert.id}>
            <div className="message left">
              {alert.text}
            </div>

            <div className="right" onClick={() => onAlertClick(alert.id)}>
              <i className="fa fa-times" />
            </div>

            <div className="clear" />
          </div>
        ) : (
          <div className={`alert ${alert.style}`} key={alert.id}>
            <div className="confirm-inner">
              <div className="message" dangerouslySetInnerHTML={{ __html: alert.text }} />

              <div>
                <button
                  type="button"
                  onClick={() => {
                    if (alert.onCancel != null) {
                      alert.onCancel();
                    }
                    onAlertClick(alert.id);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bangButton"
                  type="button"
                  onClick={() => {
                    if (alert.onSubmit != null) {
                      alert.onSubmit();
                    }
                    onAlertClick(alert.id);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )
      ))}
    </div>
  );
};

export default Alerts;
