import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchImages as fetchImagesAPI,
  fetchImage as fetchImageAPI,
  fetchImageTags as fetchImageTagsAPI,
  fetchImageSources as fetchImageSourcesAPI,
  createImage as createImageAPI,
  updateImage as updateImageAPI,
  deleteImage as deleteImageAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_IMAGES_START = 'FETCH_IMAGES_START';
export const FETCH_IMAGES_COMPLETE = 'FETCH_IMAGES_COMPLETE';
export const FETCH_IMAGE_START = 'FETCH_IMAGE_START';
export const FETCH_IMAGE_COMPLETE = 'FETCH_IMAGE_COMPLETE';
export const FETCH_IMAGE_TAGS_START = 'FETCH_IMAGE_TAGS_START';
export const FETCH_IMAGE_TAGS_COMPLETE = 'FETCH_IMAGE_TAGS_COMPLETE';
export const FETCH_IMAGE_SOURCES_START = 'FETCH_IMAGE_SOURCES_START';
export const FETCH_IMAGE_SOURCES_COMPLETE = 'FETCH_IMAGE_SOURCES_COMPLETE';
export const CREATE_IMAGE_START = 'CREATE_IMAGE_START';
export const CREATE_IMAGE_COMPLETE = 'CREATE_IMAGE_COMPLETE';
export const UPDATE_IMAGE_START = 'UPDATE_IMAGE_START';
export const UPDATE_IMAGE_COMPLETE = 'UPDATE_IMAGE_COMPLETE';
export const DELETE_IMAGE_START = 'DELETE_IMAGE_START';
export const DELETE_IMAGE_COMPLETE = 'DELETE_IMAGE_COMPLETE';

const fetchImagesStart = () => ({ type: FETCH_IMAGES_START });

const fetchImagesComplete = (preImages, res) => {
  res.images = preImages.concat(res.images);

  return {
    type: FETCH_IMAGES_COMPLETE,
    payload: res,
  };
};

const fetchImageStart = () => ({ type: FETCH_IMAGE_START });

const fetchImageComplete = (res) => {
  if (res.credit) {
    const credit = JSON.parse(res.credit);
    res.credit_name = credit.name ? credit.name : '©';
    res.credit_url = credit.url;
  } else {
    res.credit_name = '©';
    res.credit_url = '';
  }
  return {
    type: FETCH_IMAGE_COMPLETE,
    payload: res,
  };
};

const fetchImageTagsStart = () => ({ type: FETCH_IMAGE_TAGS_START });

const fetchImageTagsComplete = res => ({
  type: FETCH_IMAGE_TAGS_COMPLETE,
  payload: res,
});

const fetchImageSourcesStart = () => ({ type: FETCH_IMAGE_SOURCES_START });

const fetchImageSourcesComplete = res => ({
  type: FETCH_IMAGE_SOURCES_COMPLETE,
  payload: res,
});

const createImageStart = () => ({ type: CREATE_IMAGE_START });

const createImageComplete = res => ({
  type: CREATE_IMAGE_COMPLETE,
  payload: res,
});

const updateImageStart = () => ({ type: UPDATE_IMAGE_START });

const updateImageComplete = res => ({
  type: UPDATE_IMAGE_COMPLETE,
  payload: res,
});

const deleteImageStart = () => ({ type: DELETE_IMAGE_START });

const deleteImageComplete = () => ({
  type: DELETE_IMAGE_COMPLETE,
  payload: {},
});

export const fetchImages = (form = {}, preImages = [], offset = '') => (
  (dispatch) => {
    dispatch(fetchImagesStart());

    fetchImagesAPI(form, offset).then((res) => {
      dispatch(fetchImagesComplete(preImages, res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchImageError', { message: res.text })));
    });
  }
);

export const fetchImage = id => (
  (dispatch) => {
    dispatch(fetchImageStart());

    fetchImageAPI(id).then((res) => {
      dispatch(fetchImageComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchImageError', { message: res.text })));
    });
  }
);

export const fetchImageTags = prefix => (
  (dispatch) => {
    dispatch(fetchImageTagsStart());

    fetchImageTagsAPI(prefix).then((res) => {
      dispatch(fetchImageTagsComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
    });
  }
);

export const fetchImageSources = prefix => (
  (dispatch) => {
    dispatch(fetchImageSourcesStart());

    if (prefix.length === 0) {
      dispatch(fetchImageSourcesComplete({}));
      return;
    }

    fetchImageSourcesAPI(prefix).then((res) => {
      dispatch(fetchImageSourcesComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
    });
  }
);

export const createImage = (form, callback, inputType) => (
  (dispatch) => {
    dispatch(createImageStart());

    createImageAPI(form).then((res) => {
      dispatch(createImageComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createImageSuccess')));

      if (callback != null) {
        callback(res.body, inputType);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createImageError', { message: res.text })));
    });
  }
);

export const updateImage = (form, callback) => (
  (dispatch) => {
    dispatch(updateImageStart());

    updateImageAPI(form).then((res) => {
      dispatch(updateImageComplete(res.body));
      dispatch(removeLoading());

      if (callback != null) {
        callback(res.body);
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.updateImageSuccess')));
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateImageError', { message: res.text })));
    });
  }
);

export const deleteImage = id => (
  (dispatch) => {
    dispatch(deleteImageStart());

    deleteImageAPI(id).then(() => {
      dispatch(deleteImageComplete());
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.deleteImageSuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.deleteImageError', { message: res.text })));
    });
  }
);
