import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import Autosuggest from 'react-autosuggest';
import { translate } from 'react-i18next';

import ImageEditForm from '../../components/image/ImageEditForm';
import { fetchImageTags, fetchImageSources } from '../../actions/imageAction';

class ImageEditFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleTagsRenderInput = this.handleTagsRenderInput.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleSourceSuggestionSelected = this.handleSourceSuggestionSelected.bind(this);
    this.refTagsInput = this.refTagsInput.bind(this);

    this.state = {
      prevTagsValue: '',
      source: null,
    };
  }

  handleTagsChange(tags) {
    this.props.updateForm('tags', tags);
  }

  handleTagsRenderInput({ addTag, value, ref, onChange }) {
    const handleChange = (e, { newValue }) => {
      const tags = this.props.imageEditForm.tags || [];

      if (newValue.indexOf(',') >= 0 || newValue.indexOf('  ') >= 0) {
        const tag = newValue.replace(/,/g, '').trim();

        if (tag.length > 0 && !tags.includes(tag)) {
          this.handleTagsChange(tags.concat([tag]));
        }

        this.tagsInput.setState({ tag: '' });
      } else {
        onChange(e);
      }
    };

    const handleKeyUp = (e) => {
      const tags = this.props.imageEditForm.tags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Backspace') {
        if (this.state.prevTagsValue === '') {
          this.handleTagsChange(tags.slice(0, tags.length - 1));
        }
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleKeyPress = (e) => {
      const tags = this.props.imageEditForm.tags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Enter') {
        if (newValue.length > 0 && !tags.includes(newValue)) {
          this.handleTagsChange(tags.concat([newValue]));
        }

        this.tagsInput.setState({ tag: '' });
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleBlur = () => {
      this.tagsInput.setState({ tag: '' });
    };

    return (
      <Autosuggest
        ref={ref}
        suggestions={this.props.suggestTags.tags || []}
        shouldRenderSuggestions={v => v != null && v.trim().length > 0}
        getSuggestionValue={suggestion => suggestion}
        renderSuggestion={suggestion => <span>{suggestion}</span>}
        inputProps={{
          value,
          placeholder: this.props.t('image.tagsPlaceholder'),
          onChange: handleChange,
          onKeyUp: handleKeyUp,
          onKeyPress: handleKeyPress,
          onBlur: handleBlur,
        }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        onSuggestionsFetchRequested={(e) => {
          this.props.fetchImageTags(e.value);
        }}
        onSuggestionsClearRequested={() => {}}
      />
    );
  }

  handleSourceChange(e) {
    this.props.fetchImageSources(e.value);
    this.props.updateForm('source', e.value);
  }

  handleSourceSuggestionSelected(e, { suggestion }) {
    this.props.updateForm('source', suggestion);
  }

  refTagsInput(ref) {
    this.tagsInput = ref;
  }

  render() {
    if (Number(this.props.id) !== this.props.image.image_id) {
      return null;
    }

    const {
      imageEditForm,
      initialValues,
      image,
      saveButton,
      closeButton,
      onSubmit,
      onCancel,
      suggestSources,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = () => onSubmit(this.props.imageEditForm);
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    return (
      <div className="imageEditFormContainer">
        <ImageEditForm
          form={imageEditForm}
          initialValues={initialValues}
          image={image}
          onTagsChange={this.handleTagsChange}
          onTagsRenderInput={this.handleTagsRenderInput}
          refTagsInput={this.refTagsInput}
          suggestSources={suggestSources}
          onSourceChange={this.handleSourceChange}
          onSourceSuggestionSelected={this.handleSourceSuggestionSelected}
          sourceInputProps={
            {
              name: 'source',
              value: this.state.source || imageEditForm != null ? imageEditForm.source : '',
              onChange: e => this.setState({ source: e.target.value }),
            }
          }
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    imageEditForm: state.form.imageEditForm != null ? state.form.imageEditForm.values : null,
    suggestTags: state.imageTags,
    suggestSources: state.imageSources,
    initialValues: state.image,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('imageEditForm', key, value)),
    fetchImageTags: value => dispatch(fetchImageTags(value)),
    fetchImageSources: value => dispatch(fetchImageSources(value)),
  }
);

const imageEditFormContainer = reduxForm({ form: 'imageEditForm', enableReinitialize: true })(translate()(ImageEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(imageEditFormContainer);
