import React from 'react';
import TagsInput from 'react-tagsinput';
import Autosuggest from 'react-autosuggest';

import Config from '../../config/Config';

const ImageUploadPopup = props => (
  <div
    className="imageUploadPopup"
    onDragStart={e => e.preventDefault()}
    onDragOver={e => e.preventDefault()}
    onDrop={e => e.preventDefault()}
  >
    <div className="imageUploadPopup-inner">
      <div className="items">

        <div className="item">
          <label htmlFor="uploadFile">{props.t('image.uploadFile')}</label>
          <div>
            {props.uploadFiles.map(f => f.name).join(', ')}
            {props.uploadFiles.length > 0 ?
              <span onClick={props.onFileRemove}>
                <i className="fa fa-trash-o" aria-hidden="true" />
              </span> : null}
          </div>
          <div
            className="image"
            onDragStart={e => e.preventDefault()}
            onDragOver={(e) => {
              e.preventDefault();
              e.dataTransfer.dropEffect = 'copy';
            }}
            onDrop={props.onFileDrop}
          >
            {props.t('image.draggableImage')}
          </div>
          <div className="uploadImageTmp">
            <div id="uploadImages">
              {props.uploadFileBodies.map((e, i) => <img id={`uploadImage_${i}`} src={e} key={i} alt="uploadImage" />)}
            </div>
            <canvas id="uploadImageCanvas" />
          </div>
        </div>

        <div className="item">
          <label htmlFor="tags">{props.t('image.tags')}</label>

          <div className="tags">
            <TagsInput
              ref={props.refTagsInput}
              renderInput={props.onTagsRenderInput}
              value={props.tags || []}
              onChange={props.onTagsChange}
              onlyUnique
            />
          </div>
        </div>

        <div className="item">
          <div>
            <label htmlFor="imageType">{props.t('image.imageType')}</label>

            <div className="imageType">
              <div className="select-wrap">
                <select defaultValue="article" ref={props.refImageType}>
                  {
                    Config.IMAGE_IMAGE_TYPES
                      .map(e => <option value={e.id} key={e.id}>{props.t(e.i18nKey)}</option>)
                  }
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="item">
          <label htmlFor="source">{props.t('image.source')}</label>

          <div className="source">
            <Autosuggest
              ref={props.refSource}
              suggestions={props.suggestSources.sources || []}
              shouldRenderSuggestions={v => v != null}
              getSuggestionValue={suggestion => suggestion}
              renderSuggestion={suggestion => <span>{suggestion}</span>}
              inputProps={props.sourceInputProps}
              onSuggestionSelected={props.onSourceSuggestionSelected}
              onSuggestionsFetchRequested={props.onSourceChange}
              onSuggestionsClearRequested={() => {}}
            />
          </div>
        </div>

        <div className="item">
          <label htmlFor="imageCredit">{props.t('image.credit')}</label>

          <div className="imageCredit">
            <div className="creditName">
              <input
                ref={props.refCreditName}
                type="text"
                defaultValue="©FullDepth"
              />
            </div>

            <div className="creditURL">
              <input
                ref={props.refCreditURL}
                type="text"
                placeholder={props.t('image.creditURL')}
              />
            </div>
          </div>
        </div>

      </div>

      <div className="footer">
        <div className="left">
          <button type="button" onClick={props.onCancel}>Cancel</button>
        </div>
        <div className="right">
          <button type="button" onClick={props.onSubmit}>OK</button>
        </div>

        <div className="clear" />
      </div>
    </div>
  </div>
);

export default ImageUploadPopup;
