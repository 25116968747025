import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Cookies from 'universal-cookie';
import { translate } from 'react-i18next';
import classnames from 'classnames';

import GlobalHeader from '../../components/layout/GlobalHeader';
import SideMenu from '../../components/layout/SideMenu';
import Loading from '../../components/layout/Loading';
import { fetchPermissions } from '../../actions/permissionAction';
import { removeAlert } from '../../actions/alertAction';
import { createSession } from '../../actions/sessionAction';
import Config from '../../config/Config';
import i18n from '../../config/i18n';

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleAlertClick = this.handleAlertClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  componentWillMount() {
    const cookies = new Cookies();

    const token = this.props.location.query.token;
    if (token != null) {
      cookies.set('auth_token', token, { path: '/', secure: Config.ENV !== 'dev', maxAge: 60 * 60 * 24 * 90 });
      if (Config.ENV === 'test') cookies.set('fd_cms_web_test', '2acd644c-8b34-78f2-2370-eabc76795df4', { domain: '.fulldepth.co.jp', path: '/', secure: true, maxAge: 60 * 60 * 24 * 90 });
      this.props.router.replace('/');
    }

    const authToken = cookies.get('auth_token');
    if (authToken == null) {
      this.props.router.push('/login');
    }

    if (this.props.session == null || Object.keys(this.props.session).length === 0) {
      this.props.createSession();
    }
  }

  componentDidMount() {
    const token = new Cookies().get('auth_token');

    if (token != null) {
      this.props.fetchPermissions();

      if (this.props.location.pathname === '/') {
        this.props.router.replace('/article');
      }
    }
  }

  componentWillUpdate(nextProps) {
    const token = new Cookies().get('auth_token');

    if (token == null) {
      this.props.router.push('/login');
    } else {
      if (nextProps.location.pathname === '/') {
        this.props.router.replace('/article');
      }

      const cookies = new Cookies();
      cookies.set('auth_token', token, { path: '/', secure: Config.ENV !== 'dev', maxAge: 60 * 60 * 24 * 90 });
      if (Config.ENV === 'test') cookies.set('fd_cms_web_test', '2acd644c-8b34-78f2-2370-eabc76795df4', { domain: '.fulldepth.co.jp', path: '/', secure: true, maxAge: 60 * 60 * 24 * 90 });
    }

    const prevPath = this.props.location.pathname;
    const nextPath = nextProps.location.pathname;

    if (prevPath !== nextPath) {
      for (let i = this.props.alerts.length - 1; i >= 0; i -= 1) {
        this.props.removeAlert(this.props.alerts[i].id);
      }
    }
  }

  componentDidUpdate() {
    const { alerts } = this.props;

    Object.keys(alerts).forEach((key) => {
      if (alerts[key].style === 'success') {
        setTimeout(() => this.props.removeAlert(alerts[key].id), 2000);
      }
    });
  }

  handleAlertClick(id) {
    this.props.removeAlert(id);
  }

  handleLogout() {
    const cookies = new Cookies();
    cookies.set('auth_token', null, { path: '/', secure: Config.ENV !== 'dev', maxAge: 60 * 60 * 24 * 90 });

    this.props.router.push('/');
  }

  handleLanguageChange(e) {
    i18n.changeLanguage(e.target.options[e.target.selectedIndex].value);
  }

  render() {
    const { permissions, alerts, location, children, t } = this.props;

    const loading = this.props.loading ? <Loading /> : null;

    return (
      <div className="homeContainer">

        <div className="header">
          <GlobalHeader
            alerts={alerts}
            onAlertClick={this.handleAlertClick}
          />
        </div>

        <div className="main">
          <div className={classnames('sidemenu', { none: location.pathname.match('/article/(\\d+)/edit') })}>
            <SideMenu
              url={location.pathname}
              permissions={permissions}
              language={i18n.language}
              onLanguageChange={this.handleLanguageChange}
              onLogout={this.handleLogout}
              t={t}
            />
          </div>

          <div className={classnames('content', { fullWidth: location.pathname.match('/article/(\\d+)/edit') })}>
            {children ? React.cloneElement(children) : null}
          </div>
        </div>

        {loading}

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    permissions: state.permissions,
    alerts: state.alerts,
    article: state.article,
    session: state.session,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchPermissions: () => dispatch(fetchPermissions()),
    removeAlert: id => dispatch(removeAlert(id)),
    createSession: () => dispatch(createSession()),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(translate()(withRouter(HomeContainer)));
