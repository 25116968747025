import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchTags as fetchTagsAPI,
  fetchTag as fetchTagAPI,
  fetchFixedTags as fetchFixedTagsAPI,
  createTag as createTagAPI,
  updateTag as updateTagAPI,
  deleteTag as deleteTagAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_TAGS_START = 'FETCH_TAGS_START';
export const FETCH_TAGS_COMPLETE = 'FETCH_TAGS_COMPLETE';
export const FETCH_TAG_START = 'FETCH_TAG_START';
export const FETCH_TAG_COMPLETE = 'FETCH_TAG_COMPLETE';
export const FETCH_FIXED_TAGS_START = 'FETCH_FIXED_TAGS_START';
export const FETCH_FIXED_TAGS_COMPLETE = 'FETCH_FIXED_TAGS_COMPLETE';
export const CREATE_TAG_START = 'CREATE_TAG_START';
export const CREATE_TAG_COMPLETE = 'CREATE_TAG_COMPLETE';
export const UPDATE_TAG_START = 'UPDATE_TAG_START';
export const UPDATE_TAG_COMPLETE = 'UPDATE_TAG_COMPLETE';
export const DELETE_TAG_START = 'DELETE_TAG_START';
export const DELETE_TAG_COMPLETE = 'DELETE_TAG_COMPLETE';

const fetchTagsStart = () => ({ type: FETCH_TAGS_START });

const fetchTagsComplete = res => ({
  type: FETCH_TAGS_COMPLETE,
  payload: res,
});

const fetchTagStart = () => ({ type: FETCH_TAG_START });

const fetchTagComplete = res => ({
  type: FETCH_TAG_COMPLETE,
  payload: res,
});

const fetchFixedTagsStart = () => ({ type: FETCH_FIXED_TAGS_START });

const fetchFixedTagsComplete = res => ({
  type: FETCH_FIXED_TAGS_COMPLETE,
  payload: res,
});

const createTagStart = () => ({ type: CREATE_TAG_START });

const createTagComplete = res => ({
  type: CREATE_TAG_COMPLETE,
  payload: res,
});

const updateTagStart = () => ({ type: UPDATE_TAG_START });

const updateTagComplete = res => ({
  type: UPDATE_TAG_COMPLETE,
  payload: res,
});

const deleteTagStart = () => ({ type: DELETE_TAG_START });

const deleteTagComplete = () => ({
  type: DELETE_TAG_COMPLETE,
  payload: {},
});

export const fetchTags = (form, callback) => (
  (dispatch) => {
    dispatch(fetchTagsStart());

    fetchTagsAPI(form).then((res) => {
      dispatch(fetchTagsComplete(res.body));

      if (callback != null) {
        callback(res);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchTagError', { message: res.text })));
    });
  }
);

export const fetchTag = id => (
  (dispatch) => {
    dispatch(fetchTagStart());

    fetchTagAPI(id).then((res) => {
      dispatch(fetchTagComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchTagError', { message: res.text })));
    });
  }
);

export const fetchFixedTags = prefix => (
  (dispatch) => {
    dispatch(fetchFixedTagsStart());

    if (prefix.length === 0) {
      dispatch(fetchFixedTagsComplete({ tags: [] }));
      return;
    }

    fetchFixedTagsAPI(prefix).then((res) => {
      dispatch(fetchFixedTagsComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchTagError', { message: res.text })));
    });
  }
);

export const createTag = (form, callback) => (
  (dispatch) => {
    dispatch(createTagStart());

    createTagAPI(form).then((res) => {
      dispatch(createTagComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createTagSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createTagError', { message: res.text })));
    });
  }
);

export const updateTag = (form, callback) => (
  (dispatch) => {
    dispatch(updateTagStart());

    updateTagAPI(form).then((res) => {
      dispatch(updateTagComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.updateTagSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateTagError', { message: res.text })));
    });
  }
);

export const deleteTag = id => (
  (dispatch) => {
    dispatch(deleteTagStart());

    deleteTagAPI(id).then(() => {
      dispatch(deleteTagComplete());
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.deleteTagSuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.deleteTagError', { message: res.text })));
    });
  }
);
