import {
  FETCH_USER_START,
  FETCH_USER_COMPLETE,
  CREATE_USER_START,
  CREATE_USER_COMPLETE,
  UPDATE_USER_START,
  UPDATE_USER_COMPLETE,
  DELETE_USER_START,
  DELETE_USER_COMPLETE,
} from '../actions/userAction';

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_START:
      return state;

    case FETCH_USER_COMPLETE:
      return action.payload;

    case CREATE_USER_START:
      return state;

    case CREATE_USER_COMPLETE:
      return action.payload;

    case UPDATE_USER_START:
      return state;

    case UPDATE_USER_COMPLETE:
      return action.payload;

    case DELETE_USER_START:
      return state;

    case DELETE_USER_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default userReducer;
