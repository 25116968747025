import React from 'react';
import ReactTooltip from 'react-tooltip';

import ArticleEditSectionContainer from '../../containers/article/ArticleEditSectionContainer';

const renderItemsAdd = (i, onClick, t) => (
  <div className="itemsAdd">
    <button type="button" data-for={`itemsAdd_${i}`} data-tip={t('article.insertSection')} data-key={i} onClick={onClick}>
      <i className="fa fa-plus" data-key={i} />
    </button>
    <ReactTooltip
      id={`itemsAdd_${i}`}
      class="tooltip"
      place="right"
      effect="solid"
      multiline
    />
  </div>
);

const renderItemContent = (item) => {
  const inputType = item.input_type;

  if (inputType === 'sentence') {
    return <div className="sentence" dangerouslySetInnerHTML={{ __html: item.content }} />;
  } else if (inputType === 'heading') {
    return <div className="heading" dangerouslySetInnerHTML={{ __html: item.content }} />;
  } else if (inputType === 'image') {
    let creditHTML;
    if (item.meta.credit && !item.meta.credit.hide_credit) {
      if (item.meta.credit.url) {
        creditHTML = (
          <div className="credit">
            <a href={item.meta.credit.url} target="_blank" rel="noopener noreferrer">{item.meta.credit.name}</a>
          </div>
        );
      } else {
        creditHTML = (
          <div className="credit">{item.meta.credit.name}</div>
        );
      }
    } else {
      creditHTML = null;
    }

    return (
      <div>
        <img src={item.content || '/assets/images/no_image.png'} alt="content" />
        <div className="imageCredit">
          {creditHTML}
        </div>
      </div>
    );
  } else if (inputType === 'embed') {
    return (
      <div>
        <div className="embed" dangerouslySetInnerHTML={{ __html: item.content }} />
        <button type="button">Edit</button>
      </div>
    );
  } else if (inputType === 'credit') {
    let innerHTML = '';

    for (let i = 0; i < item.content.length; i += 1) {
      const c = item.content[i];

      let tmp = '';
      for (let j = 0; j < c.items.length; j += 1) {
        if (c.items[j].url) {
          tmp += `, <a href="${c.items[j].url}" target="_blank" rel="noopener noreferrer">${c.items[j].name}</a>`;
        } else {
          tmp += `, ${c.items[j].name}`;
        }
      }
      tmp = tmp.substring(2);
      innerHTML += `<div>${c.type_value || ''} ${tmp}</div>`;
    }

    return <div className="credit" dangerouslySetInnerHTML={{ __html: innerHTML }} />;
  } else if (inputType === 'relation_article') {
    return (
      <div className="relationArticle">
        <div className="image">
          <img src={item.image_url || '/assets/images/no_image.png'} alt={item.content} />
        </div>
        <div className="body" dangerouslySetInnerHTML={{ __html: item.content }} />
      </div>
    );
  } else if (inputType === 'reference') {
    return (
      <div className="reference">
        <div className="referenceContent" dangerouslySetInnerHTML={{ __html: item.content }} />
        <div className="referenceNameWrapper">
          <a className="referenceName" href={item.meta.reference_url} target="_blank" rel="noopener noreferrer">{`出典：${item.meta.reference_name}`}</a>
        </div>
      </div>
    );
  }

  return null;
};

const renderEditSection = (id, item, selectedIndex, onSubmit, onCancel, onBlur) => (
  <div className="editSection" key="editSection">
    <ArticleEditSectionContainer
      id={id}
      articleItem={item}
      selectedIndex={selectedIndex}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onBlur={onBlur}
    />
  </div>
);

const ArticleItemsEditForm = (props) => {
  const {
    id,
    form,
    selectedIndex,
    onAddClick,
    onEditClick,
    onCopyClick,
    onUpClick,
    onDownClick,
    onRemoveClick,
    onSubmit,
    onCancel,
    onBlur,
    t,
  } = props;
  const items = form.article_items;

  return (
    <div className="articleItemsEditForm" key="article_item">
      {renderItemsAdd(0, onAddClick, t)}

      {items != null ? items.map((item, i) => (
        <div className="articleItems" key={`items-${i}`}>
          {selectedIndex === i ?
            renderEditSection(id, items[selectedIndex], selectedIndex, onSubmit, onCancel, onBlur) :
            (
              <div>
                <div className="buttons">
                  <div className="right">
                    <div className="button" data-key={i} onClick={onCopyClick}>
                      <i className="fa fa-copy" data-key={i} />
                    </div>
                    <div className="button" data-key={i} onClick={onUpClick}>
                      <i className="fa fa-arrow-up" data-key={i} />
                    </div>
                    <div className="button" data-key={i} onClick={onDownClick}>
                      <i className="fa fa-arrow-down" data-key={i} />
                    </div>
                    <div className="button" data-key={i} onClick={onRemoveClick}>
                      <i className="fa fa-trash-o" data-key={i} />
                    </div>
                  </div>

                  <div className="clear" />
                </div>

                <div className="content" onClick={() => onEditClick(i)}>
                  {renderItemContent(item, t)}
                </div>
              </div>
            )
          }
          <div className="addContents">
            {renderItemsAdd(i + 1, onAddClick, t)}
          </div>
        </div>
      )) : null}
    </div>
  );
};

export default ArticleItemsEditForm;
