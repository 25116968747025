import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchUsers as fetchUsersAPI,
  fetchUser as fetchUserAPI,
  createUser as createUserAPI,
  updateUser as updateUserAPI,
  deleteUser as deleteUserAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_COMPLETE = 'FETCH_USERS_COMPLETE';
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_COMPLETE = 'FETCH_USER_COMPLETE';
export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_COMPLETE = 'CREATE_USER_COMPLETE';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_COMPLETE = 'UPDATE_USER_COMPLETE';
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_COMPLETE = 'DELETE_USER_COMPLETE';

const fetchUsersStart = () => ({ type: FETCH_USERS_START });

const fetchUsersComplete = (preUsers, res) => {
  res.users = preUsers.concat(res.users);

  return {
    type: FETCH_USERS_COMPLETE,
    payload: res,
  };
};

const fetchUserStart = () => ({ type: FETCH_USER_START });

const fetchUserComplete = res => ({
  type: FETCH_USER_COMPLETE,
  payload: res,
});

const createUserStart = () => ({ type: CREATE_USER_START });

const createUserComplete = res => ({
  type: CREATE_USER_COMPLETE,
  payload: res,
});

const updateUserStart = () => ({ type: UPDATE_USER_START });

const updateUserComplete = res => ({
  type: UPDATE_USER_COMPLETE,
  payload: res,
});

const deleteUserStart = () => ({ type: DELETE_USER_START });

const deleteUserComplete = () => ({
  type: DELETE_USER_COMPLETE,
  payload: {},
});

export const fetchUsers = (form = {}, preUsers = [], offset = '') => (
  (dispatch) => {
    dispatch(fetchUsersStart());

    fetchUsersAPI(form, offset).then((res) => {
      dispatch(fetchUsersComplete(preUsers, res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchUserError', { message: res.text })));
    });
  }
);

export const fetchUser = id => (
  (dispatch) => {
    dispatch(fetchUserStart());

    fetchUserAPI(id).then((res) => {
      dispatch(fetchUserComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchUserError', { message: res.text })));
    });
  }
);

export const createUser = (form, callback) => (
  (dispatch) => {
    dispatch(createUserStart());

    createUserAPI(form).then((res) => {
      dispatch(createUserComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createUserSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createUserError', { message: res.text })));
    });
  }
);

export const updateUser = (form, callback) => (
  (dispatch) => {
    dispatch(updateUserStart());

    updateUserAPI(form).then((res) => {
      dispatch(updateUserComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.updateUserSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateUserError', { message: res.text })));
    });
  }
);

export const deleteUser = id => (
  (dispatch) => {
    dispatch(deleteUserStart());

    deleteUserAPI(id).then(() => {
      dispatch(deleteUserComplete());
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.deleteUserSuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.deleteUserError', { message: res.text })));
    });
  }
);
