import {
  FETCH_IMAGE_START,
  FETCH_IMAGE_COMPLETE,
  CREATE_IMAGE_START,
  CREATE_IMAGE_COMPLETE,
  UPDATE_IMAGE_START,
  UPDATE_IMAGE_COMPLETE,
  DELETE_IMAGE_START,
  DELETE_IMAGE_COMPLETE,
} from '../actions/imageAction';

const imageReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_IMAGE_START:
      return state;

    case FETCH_IMAGE_COMPLETE:
      return action.payload;

    case CREATE_IMAGE_START:
      return state;

    case CREATE_IMAGE_COMPLETE:
      return action.payload;

    case UPDATE_IMAGE_START:
      return state;

    case UPDATE_IMAGE_COMPLETE:
      return action.payload;

    case DELETE_IMAGE_START:
      return state;

    case DELETE_IMAGE_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default imageReducer;
