import React from 'react';
import { Field } from 'redux-form';
import TagsInput from 'react-tagsinput';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import ImageUploadPopupContainer from '../../containers/image/ImageUploadPopupContainer';
import ArticlePublishSettingPopupContainer from '../../containers/article/ArticlePublishSettingPopupContainer';
import ArticleItemsEditForm from './ArticleItemsEditForm';
import Config from '../../config/Config';
import { getStrLength, copyToClipboard } from '../../util/util';

const ArticleEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    id,
    form,
    article,
    authors,
    categories,
    onEyeCatchClick,
    onEyeCatchAddClick,
    onEyeCatchToMainClick,
    onEyeCatchRemoveClick,
    onHeaderVideoURLsOpenClick,
    showHeaderVideoURLs,
    onTitlesAddClick,
    onTitlesToMainClick,
    onTitlesRemoveClick,
    onItemsAddClick,
    onItemsEditClick,
    onItemsCopyClick,
    onItemsUpClick,
    onItemsDownClick,
    onItemsRemoveClick,
    onCopyToClipboardClick,
    onContentTypeChange,
    onTagsChange,
    onTagsRenderInput,
    onTagsRenderTag,
    onDescriptionKeyUp,
    onSectionSubmit,
    onSectionCancel,
    onSectionBlur,
    refDescription,
    refDescriptionLabel,
    refTagsInput,
    selectedEyeCatch,
    selectedItemIndex,
    isDisplayImageUploadPopup,
    isDisplayPublishSettingPopup,
    onImageUploadSubmit,
    onImageUploadCancel,
    onPublishSettingSubmit,
    onPublishSettingReserved,
    onPublishSettingCancel,
    t,
  } = props;

  const imageUploadPopup = isDisplayImageUploadPopup ?
    (<ImageUploadPopupContainer
      onSubmit={onImageUploadSubmit}
      onCancel={onImageUploadCancel}
    />)
    : null;

  const publishSettingPopup = isDisplayPublishSettingPopup ?
    (<ArticlePublishSettingPopupContainer
      onSubmit={onPublishSettingSubmit}
      onReserved={onPublishSettingReserved}
      onCancel={onPublishSettingCancel}
      t={t}
    />)
    : null;

  const onClickCopyUrl = (e) => {
    e.preventDefault();

    let articlePath = `${Config.WEB_ENDPOINT}/${article.article_id}/${article.custom_url}`;
    if (article.status === 'posted') {
      if (article.brand === 'stories') {
        articlePath = `${Config.WEB_ENDPOINT}/company/stories/${article.article_id}/${article.custom_url}`;
      } else if (article.brand === 'shinkai') {
        articlePath = `${Config.WEB_ENDPOINT}/shinkai/${article.article_id}/${article.custom_url}`;
      } else {
        articlePath = `${Config.WEB_ENDPOINT}/news/${article.article_id}/${article.custom_url}`;
      }
    } else if (article.brand === 'stories') {
      articlePath = `${Config.WEB_ENDPOINT}/company/stories/preview/${article.article_id}/${article.preview_key}`;
    } else if (article.brand === 'shinkai') {
      articlePath = `${Config.WEB_ENDPOINT}/shinkai/preview/${article.article_id}/${article.preview_key}`;
    } else {
      articlePath = `${Config.WEB_ENDPOINT}/news/preview/${article.article_id}/${article.preview_key}`;
    }

    const previewUrls = [];
    (article.titles || ['']).forEach((_, titleIndex) => {
      (article.eye_catch_urls || ['']).forEach((__, eyeCatchIndex) => {
        previewUrls.push(`${articlePath}?title_index=${titleIndex + 1}&eye_catch_index=${eyeCatchIndex + 1}`);
      });
    });
    copyToClipboard(previewUrls.join('\n'));
    onCopyToClipboardClick();
  };

  const onHeaderYoutubeVideoChange = (e) => {
    const changeType = e.target.dataset.name;
    const changeYoutubeVideoID = e.target.value;

    if (changeType === 'mobileHeaderYoutubeVideo') {
      document.getElementById('mobileHeaderYoutubePreview').src =
        `https://www.youtube.com/embed/${changeYoutubeVideoID}`;
    } else if (changeType === 'pcHeaderYoutubeVideo') {
      document.getElementById('pcHeaderYoutubePreview').src =
        `https://www.youtube.com/embed/${changeYoutubeVideoID}`;
    }
  };

  return (
    <div className="articleEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="eyeCatchURLs">*{t('article.eyeCatchURLs')}</label>
            <i
              className="fa fa-info-circle"
              data-for="eyeCatchURLs"
              data-tip={t('article.eyeCatchURLsTooltip')}
            />
            <ReactTooltip
              id="eyeCatchURLs"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="eyeCatch">
              {imageUploadPopup}
              {publishSettingPopup}

              <img
                src={
                  form.eye_catch_urls != null &&
                  form.eye_catch_urls[selectedEyeCatch] != null &&
                  form.eye_catch_urls[selectedEyeCatch] !== '' ?
                    form.eye_catch_urls[selectedEyeCatch] :
                    '/assets/images/no_image.png'
                }
                alt="eyeCatch"
              />

              <div className="buttons right">
                <div onClick={onEyeCatchToMainClick}>
                  <i
                    className="fa fa-arrow-circle-o-up"
                    data-for="eye_catch_urls_main"
                    data-tip={t('article.eyeCatchURLsToMainTooltip')}
                  />
                  <ReactTooltip
                    id="eye_catch_urls_main"
                    class="tooltip"
                    place="right"
                    effect="solid"
                  />
                </div>

                <div onClick={onEyeCatchRemoveClick}>
                  <i className="fa fa-trash-o" />
                </div>
              </div>

              <div className="pagenation">
                {form.eye_catch_urls != null ? form.eye_catch_urls.map((e, i) => (
                  <div
                    name="eyeCatch"
                    className={classNames({
                      button: true,
                      selected: i === selectedEyeCatch,
                    })}
                    key={`eyeCatch-${i}`}
                    data-key={i}
                    onClick={onEyeCatchClick}
                  >
                    {i === 0 ? t('common.main') : t('common.sub', { index: i })}
                  </div>
                )) : null}

                <div className="button" onClick={onEyeCatchAddClick}>
                  <i className="fa fa-plus" />
                </div>
              </div>

              <div className="hideEyeCatch">
                <Field name="hide_eye_catch" id="hideEyeCatch" component="input" type="checkbox" />
                <label className="checkbox" htmlFor="hideEyeCatch">{t('article.hideEyeCatch')}</label>
              </div>
              <div className="displayOriginalRatioEyeCatch">
                <Field name="display_original_ratio_eye_catch" id="displayOriginalRatioEyeCatchID" component="input" type="checkbox" />
                <label className="checkbox" htmlFor="displayOriginalRatioEyeCatch">*{t('article.displayOriginalRatioEyeCatch')}</label>
                <i
                  className="fa fa-info-circle"
                  data-for="displayOriginalRatioEyeCatch"
                  data-tip={t('article.displayOriginalRatioEyeCatchTooltip')}
                />
                <ReactTooltip
                  id="displayOriginalRatioEyeCatch"
                  class="tooltip"
                  place="right"
                  effect="solid"
                  multiline
                />
              </div>
            </div>
          </div>

          <div className="item headerVideoURLsContainer">
            <label htmlFor="headerVideoURLs">{t('article.headerVideoURLs')}</label>

            <div className="buttons right">
              <div onClick={onHeaderVideoURLsOpenClick}>
                <i className={classNames({
                  fa: true,
                  'fa-caret-up': showHeaderVideoURLs,
                  'fa-caret-down': !showHeaderVideoURLs,
                })}
                />
              </div>
            </div>

            <div className={classNames({
              headerVideoURLs: true,
              opened: showHeaderVideoURLs,
            })}
            >
              <div className="headerVideo">
                <div className="videoLable">Mobile</div>
                <Field
                  name="mobile_header_youtube_video_id"
                  component="input"
                  data-name="mobileHeaderYoutubeVideo"
                  onChange={onHeaderYoutubeVideoChange}
                  type="text"
                  placeholder={t('article.mobileYoutubeVideoEyeCatchIDPlaceholder')}
                />
                <iframe
                  id="mobileHeaderYoutubePreview"
                  title="mobileHeaderYoutubePreview"
                  src={`https://www.youtube.com/embed/${form.mobile_header_youtube_video_id}`}
                  frameBorder="0"
                />
              </div>
              <div className="headerVideo">
                <div className="videoLable">PC</div>
                <Field
                  name="pc_header_youtube_video_id"
                  component="input"
                  data-name="pcHeaderYoutubeVideo"
                  onChange={onHeaderYoutubeVideoChange}
                  type="text"
                  placeholder={t('article.pcYoutubeVideoEyeCatchIDPlaceholder')}
                />
                <iframe
                  id="pcHeaderYoutubePreview"
                  title="pcHeaderYoutubePreview"
                  src={`https://www.youtube.com/embed/${form.pc_header_youtube_video_id}`}
                  frameBorder="0"
                />
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="titles">*{t('article.titles')}</label>
            <i
              className="fa fa-info-circle"
              data-for="titles"
              data-tip={t('article.titlesTooltip')}
            />
            <ReactTooltip
              id="titles"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="titles">
              {form.titles != null ? form.titles.map((e, i) => (
                <div key={`titles-${i}`}>
                  <div className="buttons right">
                    <div onClick={onTitlesToMainClick}>
                      <i
                        className="fa fa-arrow-circle-o-up"
                        data-for="titles_main"
                        data-tip={t('article.titlesToMainTooltip')}
                        data-key={i}
                      />
                      <ReactTooltip
                        id="titles_main"
                        class="tooltip"
                        place="right"
                        effect="solid"
                      />
                    </div>

                    <div onClick={onTitlesRemoveClick}>
                      <i className="fa fa-trash-o" data-key={i} />
                    </div>
                  </div>

                  <div className="content">
                    <div className="label">{ i === 0 ? t('common.main') : t('common.sub', { index: i }) }</div>
                    <Field name={`titles[${i}]`} component="input" type="text" />
                  </div>
                </div>
              )) : null}

              <button type="button" className="add" onClick={onTitlesAddClick}>
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>

          <div className="item">
            <label htmlFor="sub_title">{t('article.subTitle')}</label>
            <i
              className="fa fa-info-circle"
              data-for="sub_title"
              data-tip={t('article.subTitleTooltip')}
            />
            <ReactTooltip
              id="sub_title"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />
            <div className="sub_title">
              <div className="content">
                <Field name="sub_title" component="input" type="text" />
              </div>
            </div>
          </div>

          <div className="item">
            <div className="left">
              <label htmlFor="author">*{t('article.author')}</label>

              <div className="author">
                <div className="select-wrap">
                  <Field name="author_id" component="select">
                    {
                      authors != null ?
                        authors.map(author => (
                          <option value={author.author_id} key={author.author_id}>
                            {author.name}
                          </option>
                        )) : null
                    }
                  </Field>
                </div>
              </div>
            </div>

            <div className="right">
              <label htmlFor="brand">*{t('article.brand')}</label>

              <div className="brand">
                <div className="select-wrap">
                  <Field name="brand" component="select">
                    {
                      Config.ARTICLE_BRANDS
                        .map(e => <option value={e.id} key={e.id}>{e.name}</option>)
                    }
                  </Field>
                </div>
              </div>
            </div>

            <div className="clear" />

            <div className="left">
              <label htmlFor="contentType">*{t('article.contentType')}</label>

              <div className="contentType">
                <div className="select-wrap">
                  <Field name="content_type" component="select" onChange={onContentTypeChange}>
                    {
                      Config.ARTICLE_CONTENT_TYPES
                        .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
                    }
                  </Field>
                </div>
              </div>
            </div>

            <div className="right">
              <label htmlFor="category">*{t('article.category')}</label>

              <div className="category">
                <div className="select-wrap">
                  <Field name="category_id" component="select">
                    {
                      categories != null ?
                        categories.map(category => (
                          <option value={category.category_id} key={category.category_id}>
                            {category.name}
                          </option>
                        )) : null
                    }
                  </Field>
                </div>
              </div>
            </div>

            <div className="clear" />
          </div>

          <div className="item">
            <ArticleItemsEditForm
              id={id}
              form={form}
              selectedIndex={selectedItemIndex}
              onAddClick={onItemsAddClick}
              onEditClick={onItemsEditClick}
              onCopyClick={onItemsCopyClick}
              onUpClick={onItemsUpClick}
              onDownClick={onItemsDownClick}
              onRemoveClick={onItemsRemoveClick}
              onSubmit={onSectionSubmit}
              onCancel={onSectionCancel}
              onBlur={onSectionBlur}
              t={t}
            />
          </div>
        </div>

        <div className={`metaItems ${Config.NAMESPACE}`}>
          <div className="metaItem">
            <label htmlFor="url">*{t('article.url')}</label>

            <div className="url">
              <span>{`${Config.WEB_ENDPOINT}/${id}/`}</span>
              <Field name="custom_url" component="input" type="text" />
              <button type="button" onClick={onClickCopyUrl}>{t('article.copyUrl')}</button>
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="tags">{t('article.tags')}</label>

            <i
              className="fa fa-info-circle"
              data-for="tags"
              data-tip={t('article.tagsTooltip')}
            />
            <ReactTooltip
              id="tags"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="tags">
              <TagsInput
                ref={refTagsInput}
                renderInput={onTagsRenderInput}
                renderTag={onTagsRenderTag}
                value={form.tags || []}
                onChange={onTagsChange}
                tagProps={
                  {
                    className: 'react-tagsinput-tag',
                    classNameRemove: 'react-tagsinput-remove',
                  }
                }
                onlyUnique
              />
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="seoTitle">*{t('article.seoTitle')}</label>

            <i
              className="fa fa-info-circle"
              data-for="seo_title"
              data-tip={t('article.seoTitleTooltip')}
            />
            <ReactTooltip
              id="seo_title"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="seoTitle">
              <Field name="seo_title" component="input" type="text" />
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="socialTitle">*{t('article.socialTitle')}</label>
            <i
              className="fa fa-info-circle"
              data-for="social_title"
              data-tip={t('article.socialTitleTooltip')}
            />
            <ReactTooltip
              id="social_title"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="socialTitle">
              <Field name="social_title" component="input" type="text" />
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="description">*{t('article.description')}</label>
            <i
              className="fa fa-info-circle"
              data-for="description"
              data-tip={t('article.descriptionTooltip')}
            />
            <ReactTooltip
              id="description"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="description">
              <Field
                name="description"
                ref={refDescription}
                component="textarea"
                onKeyUp={onDescriptionKeyUp}
              />
              <div>
                <span ref={refDescriptionLabel}>
                  {t('article.descriptionCount', { count: form.description != null ? getStrLength(form.description) : 0 })}
                </span>
              </div>
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="embedCode">{t('article.embedCode')}</label>

            <div className="embedCode">
              <Field name="embed_code" component="textarea" />
            </div>
          </div>

          <hr />

          <div className="metaItem">
            <label htmlFor="memo">{t('article.memo')}</label>

            <div className="memo">
              <Field name="memo" component="textarea" />
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default ArticleEditForm;
