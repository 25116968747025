import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';

import ProductSetEditForm from '../../components/productSet/ProductSetEditForm';

class ProductSetEditFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleFeatureFunctionsAddClick = this.handleFeatureFunctionsAddClick.bind(this);
    this.handleFeatureFunctionsRemoveClick = this.handleFeatureFunctionsRemoveClick.bind(this);
    this.handleFeatureFunctionsChange = this.handleFeatureFunctionsChange.bind(this);

    this.state = {
      featureFunctions: null,
    };
  }

  componentWillMount() {
    const featureFunctions = this.props.productSet.feature_functions;

    this.setState({ featureFunctions });
  }

  componentWillReceiveProps() {
    const featureFunctions = Object.assign({}, this.state.featureFunctions);
    this.setState({ featureFunctions });
  }

  handleFeatureFunctionsAddClick() {
    let featureFunctions = [].concat(this.props.productSetEditForm.feature_functions);
    featureFunctions.push({
      name: 'no name',
      eyecatch_type: 'image',
      image_url: '',
      youtube_video_id: '',
      example: '',
      equipment: '',
      text: '',
    });
    featureFunctions = featureFunctions.filter(v => v != null);

    this.props.updateForm('feature_functions', featureFunctions);
  }

  handleFeatureFunctionsRemoveClick(e) {
    const featureFunctions = this.props.productSetEditForm.feature_functions;

    if (featureFunctions.length <= 1) {
      return;
    }

    const i = Number(e.target.dataset.key);

    const newFeatureFunctions = featureFunctions != null ? [].concat(featureFunctions) : [];
    newFeatureFunctions.splice(i, 1);

    this.props.updateForm('feature_functions', newFeatureFunctions);
  }

  handleFeatureFunctionsChange(e) {
    const newFeatureFunctions = [].concat(this.props.productSetEditForm.feature_functions);
    const i = Number(e.target.dataset.key);
    const changeType = e.target.dataset.name;
    newFeatureFunctions[i][changeType] = e.target.value;

    this.props.updateForm('feature_functions', newFeatureFunctions);
    if (changeType === 'image_url') {
      document.getElementById(`featureFunctions${i}ImagePreview`).src =
      newFeatureFunctions[i].image_url || '/assets/images/no_image.png';
    } else if (changeType === 'youtube_video_id') {
      document.getElementById(`featureFunctions${i}YoutubePreview`).src =
        `https://www.youtube.com/embed/${newFeatureFunctions[i].youtube_video_id}`;
    }
  }

  handleDetailYoutubeVideoIDChange(e) {
    const id = e.target.value;
    document.getElementById('detailYoutubeVideoID').src = `https://www.youtube.com/embed/${id}`;
  }

  render() {
    if (Number(this.props.id) !== this.props.productSet.product_set_id) {
      return null;
    }

    const {
      productSetEditForm,
      permissions,
      initialValues,
      productSet,
      saveButton,
      closeButton,
      onSubmit,
      onCancel,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = () => onSubmit(this.props.productSetEditForm);
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    return (
      <div className="productSetEditFormContainer">
        <ProductSetEditForm
          form={productSetEditForm}
          permissions={permissions}
          initialValues={initialValues}
          productSet={productSet}
          onFeatureFunctionsAddClick={this.handleFeatureFunctionsAddClick}
          onFeatureFunctionsRemoveClick={this.handleFeatureFunctionsRemoveClick}
          onFeatureFunctionsChange={this.handleFeatureFunctionsChange}
          onDetailYoutubeVideoIDChange={this.handleDetailYoutubeVideoIDChange}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    productSetEditForm:
      state.form.productSetEditForm != null ? state.form.productSetEditForm.values : null,
    initialValues: state.productSet,
    permissions: state.permissions,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('productSetEditForm', key, value)),
  }
);

const productSetEditFormContainer = reduxForm({ form: 'productSetEditForm', enableReinitialize: true })(translate()(ProductSetEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(productSetEditFormContainer);
