import {
  FETCH_CATEGORY_START,
  FETCH_CATEGORY_COMPLETE,
  CREATE_CATEGORY_START,
  CREATE_CATEGORY_COMPLETE,
  UPDATE_CATEGORY_START,
  UPDATE_CATEGORY_COMPLETE,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_COMPLETE,
} from '../actions/categoryAction';

const categoryReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_START:
      return state;

    case FETCH_CATEGORY_COMPLETE:
      return action.payload;

    case CREATE_CATEGORY_START:
      return state;

    case CREATE_CATEGORY_COMPLETE:
      return action.payload;

    case UPDATE_CATEGORY_START:
      return state;

    case UPDATE_CATEGORY_COMPLETE:
      return action.payload;

    case DELETE_CATEGORY_START:
      return state;

    case DELETE_CATEGORY_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default categoryReducer;
