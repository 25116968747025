import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import ArticleDetail from '../../components/article/ArticleDetail';
import ArticleHistoryList from '../../components/article/ArticleHistoryList';
import { updateArticle, fetchArticleHistories } from '../../actions/articleAction';
import { fetchAuthors } from '../../actions/authorAction';
import { fetchCategories } from '../../actions/categoryAction';
import { createEditExclusion, deleteEditExclusion } from '../../actions/editExclusionAction';
import { addLoading } from '../../actions/loadingAction';

class ArticleHistoryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCreateEditExclusion = this.handleCreateEditExclusion.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleRestoreClick = this.handleRestoreClick.bind(this);

    this.state = {
      editExclusionTimer: null,
    };
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchArticleHistories(this.props.params.id);
    }

    this.props.fetchAuthors();
    this.props.fetchCategories();

    if (this.state.editExclusionTimer == null) {
      setTimeout(this.handleCreateEditExclusion, 3 * 1000);
      this.state.editExclusionTimer = setInterval(this.handleCreateEditExclusion, 30 * 1000);
    }
  }

  componentDidUpdate(prevProps) {
    const prevPath = prevProps.location.pathname;
    const nextPath = this.props.location.pathname;

    if (this.articleHistoryDetail != null && prevPath !== nextPath) {
      this.articleHistoryDetail.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.editExclusionTimer);
    this.setState({ editExclusionTimer: null });

    this.props.deleteEditExclusion(this.props.session.tabID);
  }

  handleCreateEditExclusion() {
    const { articleHistories, session } = this.props;

    if (articleHistories.articles != null && articleHistories.articles.length > 0) {
      const article = articleHistories.articles[0];
      this.props.createEditExclusion(session.tabID, article.article_id, article.revision);
    }
  }

  handleCloseClick() {
    this.props.router.push(`/article/${this.props.params.id}/edit`);
  }

  handleRestoreClick(article) {
    this.props.addLoading();
    this.props.updateArticle(article);
    this.props.router.push(`/article/${this.props.articleHistories.articles[0].article_id}/edit`);
  }

  render() {
    const { articleHistories, authors, categories, permissions, params, t } = this.props;
    const editExclusions = this.props.editExclusions.edit_exclusions;

    if (articleHistories == null || Object.keys(articleHistories).length === 0) {
      return null;
    }

    const selectedHistory =
      articleHistories.articles.find(e => e.revision === Number(params.revision));
    const canRestore = selectedHistory.revision !== articleHistories.articles[0].revision;

    let editingUserNames = [];
    if (editExclusions != null) {
      editExclusions.forEach((e) => {
        if (permissions != null && e.user_id !== permissions.id) {
          editingUserNames.push(e.user_name);
        }
      });

      editingUserNames = editingUserNames.filter((x, i, self) => self.indexOf(x) === i);
    }

    return (
      <div className="articleHistoryContainer">
        {(() => {
          if (editingUserNames.length > 0) {
            return (
              <div className="editExclusion">
                {t('article.editExclusion', { userName: editingUserNames.join(', ') })}
              </div>
            );
          }

          return null;
        })()}

        <div className="header">
          <div className="buttons">
            <div className="left">
              <button onClick={this.handleCloseClick}>
                <i className="fa fa-times" aria-hidden="true" />
                Close
              </button>
            </div>

            <div className="right">
              <button
                className="bangButton"
                onClick={() => this.handleRestoreClick(selectedHistory)}
                disabled={!canRestore}
              >
                Restore
              </button>
            </div>

            <div className="clear" />
          </div>

          <div className="list">
            <label htmlFor="articleHistory">{t('article.articleHistory')}</label>
            <ArticleHistoryList params={params} articleHistories={articleHistories} />
          </div>
        </div>

        <div ref={(ref) => { this.articleHistoryDetail = ref; }} className="detail">
          <ArticleDetail
            id={params.id}
            article={selectedHistory}
            authors={authors.authors}
            categories={categories.categories}
            t={t}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    articleHistories: state.articleHistories,
    authors: state.authors,
    categories: state.categories,
    session: state.session,
    permissions: state.permissions,
    editExclusions: state.editExclusions,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchArticleHistories: id => dispatch(fetchArticleHistories(id)),
    fetchAuthors: () => dispatch(fetchAuthors()),
    fetchCategories: () => dispatch(fetchCategories()),
    updateArticle: form => dispatch(updateArticle(form)),
    createEditExclusion:
      (tabID, articleID, revision) => dispatch(createEditExclusion(tabID, articleID, revision)),
    deleteEditExclusion: tabID => dispatch(deleteEditExclusion(tabID)),
    addLoading: () => dispatch(addLoading()),
  }
);

const articleHistoryContainer = withRouter(translate()(ArticleHistoryContainer));
export default connect(mapStateToProps, mapDispatchToProps)(articleHistoryContainer);
