import { addErrorAlert } from './alertAction';
import {
  fetchRoles as fetchRolesAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_ROLES_START = 'FETCH_ROLES_START';
export const FETCH_ROLES_COMPLETE = 'FETCH_ROLES_COMPLETE';

const fetchRolesStart = () => ({ type: FETCH_ROLES_START });

const fetchRolesComplete = res => ({
  type: FETCH_ROLES_COMPLETE,
  payload: res,
});

export const fetchRoles = () => (
  (dispatch) => {
    dispatch(fetchRolesStart());

    fetchRolesAPI().then((res) => {
      dispatch(fetchRolesComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchRoleError', { message: res.text })));
    });
  }
);
