const appEnv = {
  ENV: 'test',
  NAMESPACE: '',
  LOGO_IMAGE: '/assets/images/logo.png',
  WEB_ENDPOINT: 'https://test.fulldepth.co.jp',
  CMS_ENDPOINT: 'https://cms-test.fulldepth.co.jp',
  API_ENDPOINT: 'https://cms-api-test.fulldepth.co.jp/api/v1',
  AUTH_ENDPOINT: 'https://cms-api-test.fulldepth.co.jp/auth',
};

export default appEnv;
