import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';
import { I18nextProvider } from 'react-i18next';

import AppContainer from './containers/AppContainer';
import LoginContainer from './containers/login/LoginContainer';
import HomeContainer from './containers/home/HomeContainer';
import ArticleContainer from './containers/article/ArticleContainer';
import ArticleDetail from './components/article/ArticleDetail';
import ArticleEditContainer from './containers/article/ArticleEditContainer';
import ArticleHistoryContainer from './containers/article/ArticleHistoryContainer';
import ImageContainer from './containers/image/ImageContainer';
import ImageEditContainer from './containers/image/ImageEditContainer';
import AuthorContainer from './containers/author/AuthorContainer';
import AuthorEditContainer from './containers/author/AuthorEditContainer';
import CategoryContainer from './containers/category/CategoryContainer';
import CategoryEditContainer from './containers/category/CategoryEditContainer';
import TagContainer from './containers/tag/TagContainer';
import TagEditContainer from './containers/tag/TagEditContainer';
import IssueContainer from './containers/issue/IssueContainer';
import IssueEditContainer from './containers/issue/IssueEditContainer';
import ProductOptionContainer from './containers/productOption/ProductOptionContainer';
import ProductOptionEditContainer from './containers/productOption/ProductOptionEditContainer';
import ProductSetContainer from './containers/productSet/ProductSetContainer';
import ProductSetEditContainer from './containers/productSet/ProductSetEditContainer';
import UserContainer from './containers/user/UserContainer';
import UserEditContainer from './containers/user/UserEditContainer';
import NotFound from './components/error/NotFound';
import ConfigureStore from './store/ConfigureStore';
import i18n from './config/i18n';

const store = ConfigureStore();

const dom = (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router history={browserHistory}>
        <Route component={AppContainer}>

          <Route path="/login" component={LoginContainer} />

          <Route path="/" component={HomeContainer}>

            <Route path="/article" component={ArticleContainer}>
              <Route path="/article/:id" component={ArticleDetail} />
            </Route>
            <Route path="/article/:id/edit" component={ArticleEditContainer} />
            <Route path="/article/:id/history/:revision" component={ArticleHistoryContainer} />

            <Route path="/image" component={ImageContainer}>
              <Route path="/image/:imageID" component={ImageEditContainer} />
            </Route>

            <Route path="/author" component={AuthorContainer}>
              <Route path="/author/:id" component={AuthorEditContainer} />
            </Route>

            <Route path="/category" component={CategoryContainer}>
              <Route path="/category/:id" component={CategoryEditContainer} />
            </Route>

            <Route path="/tag" component={TagContainer}>
              <Route path="/tag/:id" component={TagEditContainer} />
            </Route>

            <Route path="/issue" component={IssueContainer}>
              <Route path="/issue/:id" component={IssueEditContainer} />
            </Route>

            <Route path="/product_set" component={ProductSetContainer}>
              <Route path="/product_set/:id" component={ProductSetEditContainer} />
            </Route>

            <Route path="/product_option" component={ProductOptionContainer}>
              <Route path="/product_option/:id" component={ProductOptionEditContainer} />
            </Route>

            <Route path="/user" component={UserContainer}>
              <Route path="/user/:id" component={UserEditContainer} />
            </Route>

            <Route path="*" component={NotFound} />
          </Route>

        </Route>
      </Router>
    </I18nextProvider>
  </Provider>
);

ReactDOM.render(dom, document.getElementById('app'));
