import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import AuthorEditFormContainer from './AuthorEditFormContainer';
import { fetchAuthor, fetchAuthors, updateAuthor } from '../../actions/authorAction';
import { addLoading } from '../../actions/loadingAction';

class AuthorEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchAuthor(this.props.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { author } = this.props;

    if (author != null && Number(prevProps.params.id) !== author.author_id) {
      this.props.fetchAuthor(prevProps.params.id);
    }
  }

  handleCloseClick() {
    this.props.router.push('/author');
  }

  handleSubmit(form) {
    const newForm = { ...form };

    this.props.addLoading();
    this.props.updateAuthor(newForm, () => this.props.fetchAuthors());
  }

  render() {
    const { author, params } = this.props;

    if (author == null || params.id == null) {
      return null;
    }

    return (
      <div className="authorEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          <AuthorEditFormContainer
            id={params.id}
            author={author}
            saveButton={this.saveButton}
            closeButton={this.closeButton}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCloseClick}
            ref={(ref) => { this.authorEditFormContainer = ref; }}
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    author: state.author,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchAuthor: id => dispatch(fetchAuthor(id)),
    fetchAuthors: (form, authors, offset) => dispatch(fetchAuthors(form, authors, offset)),
    updateAuthor: (form, callback) => dispatch(updateAuthor(form, callback)),
    addLoading: () => dispatch(addLoading()),
  }
);

const authorEditContainer = withRouter(AuthorEditContainer);
export default connect(mapStateToProps, mapDispatchToProps)(authorEditContainer);
