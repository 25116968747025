import {
  FETCH_TAG_START,
  FETCH_TAG_COMPLETE,
  CREATE_TAG_START,
  CREATE_TAG_COMPLETE,
  UPDATE_TAG_START,
  UPDATE_TAG_COMPLETE,
  DELETE_TAG_START,
  DELETE_TAG_COMPLETE,
} from '../actions/tagAction';

const tagReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TAG_START:
      return state;

    case FETCH_TAG_COMPLETE:
      return action.payload;

    case CREATE_TAG_START:
      return state;

    case CREATE_TAG_COMPLETE:
      return action.payload;

    case UPDATE_TAG_START:
      return state;

    case UPDATE_TAG_COMPLETE:
      return action.payload;

    case DELETE_TAG_START:
      return state;

    case DELETE_TAG_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default tagReducer;
